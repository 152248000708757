import React, { useEffect, useState } from "react";
import { Input, Button ,Select} from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";

const MaterialModal = ({ handleClose, selectedMaterial }) => {
    const inputDataStructure = {
        machine: {
            key: "machine",
            label: "Machine",
            data: "",
            required: true,
            type: "text",
            error: null, 
        },
        unit: { 
            key: "unit",
            label: "SELECT RATE TYPE",
            type: "select", 
            optList: [],
            error: null,
            required: true,

        },
        perUnit: {
            key: "perUnit",
            label: "Per Unit Rate",
            data: "",
            required: true,
            type: "number",
            error: null, 
        },
        
    };
    
    const [inputs, setInputs] = useState(inputDataStructure);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (input) => {
        let input_list = { ...inputs };
        input_list[input.key] = input;
        
        setInputs(input_list);
    };

    const buildForm = async () => {
        let input_list = { ...inputs };

        try {

                        const unitOPt = [
                { id: "hour", title: "Hour" },
                { id: "minutes", title: "Minutes" },
                { id: "m", title: "m" },
                { id: "cm", title: "cm" },
                { id: "l", title: "l" },
                { id: "ml", title: "ml" }
    
            ];
            input_list.unit.optList = unitOPt;
        } catch (error) {
            console.error("Error fetching supplier data:", error);
        }
        setInputs(input_list);
    };
    

    useEffect(() => {
        buildForm();
    }, []);

    const handleSubmit = async () => {
        const event = window.event;
        event.preventDefault();

        const formData = {
            machine: inputs.machine.data,
            unit: inputs.unit.data,
            perUnit: inputs.perUnit.data,
        };

        try {
            setIsLoading(true);

            if (selectedMaterial) {
                formData.id = selectedMaterial.id;
                await apiService.patch("material", formData);
                toast.success("Material updated successfully!");
            } else {
                await apiService.post("material/machine", formData);
                toast.success("Machine added successfully!");
            }

            handleClose();
            setIsLoading(false);

        } catch (e) {
            toast.error(e);
            setIsLoading(false);

        }
    };

    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75 ">
                <div className=" shadow-xl transition-all w-[25%] mx-auto mt-[100px] rounded-lg bg-white">
                    <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
                        <h3 className="py-1 mx-auto">
                            {!selectedMaterial && "ADD NEW "}
                            {selectedMaterial && "EDIT "}
                            MACHINE
                        </h3>
                        <span
                            className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
                            onClick={handleClose}
                        >
                            X
                        </span>
                    </div>

                    <div className="px-4 mt-2">
                    <Input
    input={inputs.machine} 
    handleChange={handleChange}
/>
<Select input={inputs.unit} handleChange={handleChange} />
<Input
    input={inputs.perUnit} 
    handleChange={handleChange}
/>

                        
                                                {isLoading ? (
                        "Wait..."
                    ) : (
                        <Button
                            handleClick={handleSubmit}
                            className="mt-6"
                            text={selectedMaterial ? "EDIT" : "CREATE"}
                        />)}
                    </div>
                </div>
            </div>
        </div>
    );
};


export default MaterialModal;
