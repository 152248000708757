import React, { useEffect, useState } from "react";
import { Input, Button } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";

const MaterialModal = ({ handleClose, selectedMaterial }) => {
    const inputDataStructure = {

        unitPriceExt: {
            key: "unitPriceExt",
            label: "Selling price",
            data: 0,
            min: 1,
            required: true,
            type: "number",
            error: null,
        },
        maxDiscount: {
            key: "maxDiscount",
            label: "Maximum allowed discount (%)",
            data: 0,
            min: 1,
            required: true,
            type: "number",
            error: null,
        },
    };

    const [inputs, setInputs] = useState(inputDataStructure);

    const handleChange = (input) => {
        let input_list = { ...inputs };
        input_list[input.key] = input;

        setInputs(input_list);
    };

    const buildForm = async () => {
        let input_list = { ...inputs };

        input_list.unitPriceExt.data = selectedMaterial.unitPriceExt;
        input_list.maxDiscount.data = selectedMaterial.maxDiscount;

        switch (selectedMaterial.priceCalcMethod) {
            case "nos":

                input_list.unitPriceExt.label =
                    "External unit price (per item)";
                break;
            case "weight-based":
                input_list.unitPriceExt.label = "External unit price (per 1kg)";
                break;

            case "length-based":

                input_list.unitPriceExt.label =
                    "External unit price (per 1 ft)";

                break;

            case "area-based":

                input_list.unitPriceExt.label =
                    "External unit price (per 1 sq. ft)";

                break;

            default:
                break;
        }

        setInputs(input_list);
    };

    useEffect(() => {
        buildForm();
    }, []);

    const handleSubmit = async () => {
        const event = window.event;
        event.preventDefault();

        const formData = {
            id: selectedMaterial.id,
            unitPriceExt: inputs.unitPriceExt.data,
            maxDiscount: inputs.maxDiscount.data,
        };

        try {
            await apiService.patch("material/price", formData);
            toast.success("Prices updated successfully!");

            handleClose();
        } catch (e) {
            toast.error(e);
        }
    };

    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75 ">
                <div className=" shadow-xl transition-all w-[25%] mx-auto mt-[100px] rounded-lg bg-white">
                    <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
                        <h3 className="py-1 mx-auto">SET PRICE</h3>
                        <span
                            className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
                            onClick={handleClose}
                        >
                            X
                        </span>
                    </div>

                    <div className="px-4 mt-2">

                        <Input
                            input={inputs.unitPriceExt}
                            handleChange={handleChange}
                        />
                        <Input
                            input={inputs.maxDiscount}
                            handleChange={handleChange}
                        />

                        <Button
                            handleClick={handleSubmit}
                            className="w-full mt-6"
                            text={"SUBMIT"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MaterialModal;
