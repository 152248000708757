import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import OrderModal from "./OrderModal";
import ViewModal from "./ViewModal";
import ViewModalJob from "./ViewModalJob";
import ReactDOMServer from "react-dom/server";
import ViewVoucher from "./ViewVoucher";

import { Button } from "../../../components/ui";
import { Table, Input } from "antd";
import apiService from "../../../http";
import Confirm from "../../../components/ui/ConfirmFinish";
import CustomerModal from "./CustomerModal";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [orderModalState, setOrderModalState] = useState(false);
  const [viewModalState, setViewModalState] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [confirmModalState, setConfirmModalState] = useState(false);
  const [jobView, setViewModalStateJob] = useState(false);
  const [customerModal, setCustomerModal] = useState(false);
  const [voucherView, setvoucherView] = useState(false);

  const handlePrint = (order) => {
    const tableContent = (
      <div>
        <p>
          <strong>Customer Name:</strong> {order.customerName}
        </p>
        <p>
          <strong>Date:</strong>{" "}
          {new Date(order.createdAt).toLocaleDateString()}
        </p>
        <p>
          <strong>Order Number:</strong> {order.orderId}
        </p>
        <p>
          <strong>Address:</strong> {order.customerAddress}
        </p>
        <p>
          <strong>Advance Amount:</strong>{" "}
          {order.advancePayment.toLocaleString("en-US", {
            style: "currency",
            currency: "LKR",
          })}
        </p>
        <br />
        <br />
        <br />
        <br />
        <p class="signature">............................</p>
        <p class="signature">Authorized Signature</p>
      </div>
    );

    const printContent = ReactDOMServer.renderToString(tableContent);
    const printWindow = window.open("", "Print", "width=800,height=600");
    printWindow.document.write(
      `<html><head><title>Costing Report</title>
            <style>
            table.no-border, table.no-border th, table.no-border td {
              border: none !important;
              padding: 8px;
          }
              table, th, td {
                  border: 1px solid black;
                  border-collapse: collapse;
                  padding: 8px;
              }
            th {
                background-color: #f2f2f2;
            }
            img {
                width: 150px; /* Adjust the width as needed */
            }
            .print-content {
                text-align: center;
                margin-bottom: 20px;
            }
            .print-content p {
                font-family: Sinhala, Arial;
                font-size: 12px;
                margin: 20px 0 10px 0;
            }
            h1 {
                text-align: center;
            }
            p{
                fontFamily: Sinhala, Arial;
                fontSize: 12px;
                margin: 10px 0 5px 0;
            }
            .classdiv{
                flex:2;
                text-align: right;
            }
            .fullCls{
                display:flex;
                justify-content:space-between;
                align-itmes:center;
                margin-bottom:20px;
            }.imgcls{
                flex:1;
                text-align:left;
                width:150px;
            }
        </style>
        </head><body>
        <div class="fullCls">
      <div class="imgcls">
      <img src="/logo.png" alt="Logo"/>
      </div>
      <div class="classdiv">
      <p>
        තහඩු නැවීම, කැපිම, රොල් කිරීම, රූෆින් ශීට් සහ උපාංග, ස්ටීල්
        ෆැබ්‍රිකේශන් වැඩ
        <br />
        විලකොළදෙනිය, මලංගම, රත්නපුර
        <br />
        045 2121750, 074 0327850
      </p>
      </div>
      </div>
            <h1>PAYMENT RECEIPT</h1>
            ${printContent}
            </body></html>`
    );
    printWindow.document.write(
      `<script>window.onload = function() { window.print(); window.onafterprint = function() { window.close(); }; };</script>`
    );
    printWindow.document.close();
  };

  const handleCloseModal = () => {
    setOrderModalState(false);
    setViewModalState(false);
    setConfirmModalState(false);
    setViewModalStateJob(false);
    refreshOrders();
    setvoucherView(false);
  };

  const handleCloseOrder = () => {
    setOrderModalState(false);
    setCustomerModal(true);
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const filteredServices = orders.filter((order) => {
    const columnsToSearch = ["orderId", "customerName", "customerPhone"];
    return columnsToSearch.some((column) =>
      String(order[column]).toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const getOrders = async () => {
    const orders = await apiService.get("order");
    setOrders(orders);
  };

  useEffect(() => {
    getOrders();
  }, []);

  const refreshOrders = async () => {
    try {
      const orders = await apiService.get("order");
      setOrders(orders);
    } catch (e) {
      toast.error(e);
    }
  };

  const handleDelete = async (e) => {
    try {
      if (selectedOrder) {
        const formData = {
          orderId: selectedOrder.orderId,
          id: selectedOrder.id,
        };
        // Patch request to change the status of the selected order
        await apiService.post("order/status", formData);
        setConfirmModalState(false);
        setSelectedOrder((prevOrder) => ({ ...prevOrder, status: "finished" }));
        toast.success("Order finished successfully");
      }
    } catch (e) {
      toast.error(e);
    }
    setConfirmModalState(false);
    refreshOrders();
  };

  const getProductById = async (productId) => {
    try {
      const response = await apiService.get(`product/getproduct/${productId}`);
      return response;
    } catch (error) {
      console.error("Error fetching product:", error);
      throw error;
    }
  };

  const actionBtns = (order) => {
    const { status, products } = order;
    let bool = false;

    const checkProductData = (product) => {
      try {
        const productId = product.id;
        const productData = getProductById(productId);
        if (product.quantity > productData.stock) {
          bool = true;
        } else {
          bool = false;
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    const checkProducts = () => {
      if (status === "sold") {
        return (
          <div className="flex flex-wrap items-center">
            <Button
              text="VIEW"
              className="ml-3 hover:bg-primary hover:text-white px-[14px]"
              handleClick={() => {
                setSelectedOrder(order);
                setViewModalState(true);
              }}
            />
            <Button
              text="JOBS"
              className="hover:bg-secondary hover:text-white px-[14px]"
              handleClick={() => {
                setSelectedOrder(order);
                setViewModalStateJob(true);
              }}
            />
            <Button
              text="Print A"
              className="hover:bg-secondary hover:text-white px-[14px]"
              handleClick={() => {
                handlePrint(order);
              }}
            />
            <Button
              text="Print B"
              className="hover:bg-secondary hover:text-white px-[14px]"
              handleClick={() => {
                setSelectedOrder(order);
                setvoucherView(true);
              }}
            />
          </div>
        );
      } else {
        for (const product of products) {
          checkProductData(product);
          if (bool) {
            return (
              <div className="flex flex-wrap items-center">
                <Button
                  text="VIEW"
                  className="ml-3 hover:bg-primary hover:text-white px-[14px]"
                  handleClick={() => {
                    setSelectedOrder(order);
                    setViewModalState(true);
                  }}
                />
                <Button
                  text="JOBS"
                  className="hover:bg-secondary hover:text-white px-[14px]"
                  handleClick={() => {
                    setSelectedOrder(order);
                    setViewModalStateJob(true);
                  }}
                />
                <Button
                  text="Print A"
                  className="hover:bg-secondary hover:text-white px-[14px]"
                  handleClick={() => {
                    handlePrint(order);
                  }}
                />
                <Button
                  text="Print B"
                  className="hover:bg-secondary hover:text-white px-[14px]"
                  handleClick={() => {
                    setSelectedOrder(order);
                    setvoucherView(true);
                  }}
                />
              </div>
            );
          } else {
            return (
              <div className="flex flex-wrap items-center">
                <Button
                  text="VIEW"
                  className="ml-3 hover:bg-primary hover:text-white px-[14px]"
                  handleClick={() => {
                    setSelectedOrder(order);
                    setViewModalState(true);
                  }}
                />
                <Button
                  text="FINISH"
                  className="hover:bg-secondary hover:text-white px-[14px]"
                  handleClick={() => {
                    setSelectedOrder(order);
                    setConfirmModalState(true);
                  }}
                />
                <Button
                  text="JOBS"
                  className="hover:bg-secondary hover:text-white px-[14px]"
                  handleClick={() => {
                    setSelectedOrder(order);
                    setViewModalStateJob(true);
                  }}
                />
                <Button
                  text="Print A"
                  className="hover:bg-secondary hover:text-white px-[14px]"
                  handleClick={() => {
                    handlePrint(order);
                  }}
                />
                <Button
                  text="Print B"
                  className="hover:bg-secondary hover:text-white px-[14px]"
                  handleClick={() => {
                    setSelectedOrder(order);
                    setvoucherView(true);
                  }}
                />
                {confirmModalState && (
                  <Confirm
                    cancelHandler={handleCloseModal}
                    confirmHandler={() => handleDelete(selectedOrder)}
                  />
                )}
              </div>
            );
          }
        }
      }
    };

    return checkProducts();
  };

  const columns = [
    {
      title: "#",
      width: "5%",
      render: (arg, record, index) => {
        const rowNumber = index + 1;
        return <p className="text-center">{rowNumber}</p>;
      },
    },
    {
      title: "ORDER ID",
      dataIndex: "orderId",
      key: "orderId",
      align: "center",
      width: "5%",
      sorter: (a, b) => a.orderId.localeCompare(b.orderId),
    },
    {
      title: "CUSTOMER NAME",
      dataIndex: "customerName",
      key: "customerName",
      align: "center",
      width: "5%",
    },
    {
      title: "CUSTOMER PHONE",
      dataIndex: "customerPhone",
      key: "customerPhone",
      align: "center",
      width: "5%",
    },
    {
      title: "STATUS",
      align: "center",
      width: "5%",
      dataIndex: "status",
      render: (status) => {
        let backgroundColor = "";
        let textColor = "";

        // Determine background color and text color based on status
        if (status === "complete") {
          backgroundColor = "bg-green-500";
          textColor = "text-white";
        } else if (status === "sold") {
          backgroundColor = "bg-red-500";
          textColor = "text-white";
        } else if (status === "pending") {
          backgroundColor = "bg-blue-500";
          textColor = "text-white";
        }

        return (
          <span className={`rounded px-2 py-1 ${backgroundColor} ${textColor}`}>
            {status}
          </span>
        );
      },
    },
    {
      title: "TOTAL",
      align: "center",
      width: "5%",
      render: (arg) => {
        const formattedPrice = arg.total.toLocaleString("en-US", {
          style: "currency",
          currency: "LKR",
        });
        return formattedPrice;
      },
    },
    {
      title: "ACTIONS",
      align: "center",
      width: "35%",
      render: (arg) => actionBtns(arg),
    },
  ];

  return (
    <section className="w-full mt-6">
      {orderModalState && (
        <OrderModal
          handleClose={handleCloseModal}
          handleCloseModal={handleCloseOrder}
        />
      )}
      {jobView && (
        <ViewModalJob
          selectedOrder={selectedOrder}
          handleClose={handleCloseModal}
        />
      )}
      {viewModalState && (
        <ViewModal
          selectedOrder={selectedOrder}
          handleClose={handleCloseModal}
        />
      )}
      {voucherView && (
        <ViewVoucher
          selectedOrder={selectedOrder}
          handleClose={handleCloseModal}
        />
      )}
      {customerModal && (
        <CustomerModal
          handleClose={handleCloseModal}
          sellAlert={() => setCustomerModal(false)}
        />
      )}
      <div className="flex w-full">
        <div
          className="flex gap-3 px-2 py-2 mb-3 bg-white rounded-md"
          style={{ width: "50%" }}
        >
          <Button
            text={"CREATE ORDER"}
            handleClick={() => setOrderModalState(true)}
          />
        </div>
        <div
          className="flex items-center justify-end mx-5"
          style={{ width: "50%" }}
        >
          <Input
            placeholder="   Type To Search"
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
            style={{
              width: "43%",
              border: "1px solid #878686",
              borderRadius: "4px",
              height: "40px",
            }}
          />
        </div>
      </div>
      <Table columns={columns} dataSource={filteredServices} />
    </section>
  );
};

export default Orders;
