import React, { useState, useEffect } from "react";
import ChartSection from "./ChartSection";
import CounterSection from "./CounterSection";

import apiService from "../../../http";

const Home = () => {
    const [countData, setCounts] = useState([]);
    const [materials, setMaterials] = useState([]);
    const [products, setProducts] = useState([]);
    const [sales, setSale] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await apiService.get("stat");
                setCounts(result);
                const materials = await apiService.get("material");
                setMaterials(materials);
                const products = await apiService.get("product");
                setProducts(products);
                const sales = await apiService.get("sale");
                setSale(sales);
            } catch (e) {
                console.log(e);
            }
        };

        fetchData();
    }, []);


        //Get Materials data for the Graph

    const result = [];

    const generateMonthlyMatCount = (Materials) => {
        const monthlyCounts = {};
    
        Materials.forEach(product => {
            const createdAt = new Date(product.createdAt);
            const month = createdAt.getMonth(); 
            const year = createdAt.getFullYear();
    
            const key = `${year}-${month + 1}`; 
    
            if (monthlyCounts[key]) {
                monthlyCounts[key]++;
            } else {
                monthlyCounts[key] = 1;
            }
        });
    
        for (const key in monthlyCounts) {
            const [year, month] = key.split('-');
            const monthName = new Date(year, month - 1).toLocaleString('en-US', { month: 'long' }); 
            result.push({ name: monthName, Materials: monthlyCounts[key] });
        }
    
        return result;
    };
    
    const monthlymatCount = generateMonthlyMatCount(materials);


        //Get Products data for the Graph

    const productsArray = [];

    const generateMonthlyProductCount = (Products) => {
        const monthlyCounts = {};
    
        Products.forEach(product => {
            const createdAt = new Date(product.createdAt);
            const month = createdAt.getMonth(); 
            const year = createdAt.getFullYear();
    
            const key = `${year}-${month + 1}`; 
    
            if (monthlyCounts[key]) {
                monthlyCounts[key]++;
            } else {
                monthlyCounts[key] = 1;
            }
        });
    
        for (const key in monthlyCounts) {
            const [year, month] = key.split('-');
            const monthName = new Date(year, month - 1).toLocaleString('en-US', { month: 'long' }); 
            productsArray.push({ name: monthName, Products: monthlyCounts[key] });
        }
    
        return productsArray;
    };
    
    const monthlyProductCount = generateMonthlyProductCount(products);


    //Get Sales data for the Graph
    const saleArray = [];

    const generateMonthlySaleCount = (Sales) => {
        const monthlyCounts = {};
    
        Sales.forEach(product => {
            const createdAt = new Date(product.createdAt);
            const month = createdAt.getMonth(); 
            const year = createdAt.getFullYear();
    
            const key = `${year}-${month + 1}`; 
    
            if (monthlyCounts[key]) {
                monthlyCounts[key]++;
            } else {
                monthlyCounts[key] = 1;
            }
        });
    
        for (const key in monthlyCounts) {
            const [year, month] = key.split('-');
            const monthName = new Date(year, month - 1).toLocaleString('en-US', { month: 'long' }); 
            saleArray.push({ name: monthName, Sales: monthlyCounts[key] });
        }
    
        return saleArray;
    };
    
    const monthlySaleCount = generateMonthlySaleCount(sales);


    return (
        <div>
            <CounterSection countData={countData} />
            <ChartSection data={productsArray} keyX={"name"} keyY={"Products"} />
            <ChartSection data={result} keyX={"name"} keyY={"Materials"} />
            <ChartSection data={saleArray} keyX={"name"} keyY={"Sales"} />
        </div>
    );
};

export default Home;
