import React, { useEffect, useState } from "react";
import { Input, Button, Select } from "../../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../../http";
import { Table } from "antd";
import Moment from "moment";

const ProductModal = ({ handleClose, selectedJob }) => {
  const [isLoading, setIsLoading] = useState(false);

  const inputDataStructure = {
    remarks: {
      key: "remarks",
      label: "Remarks",
      data: "",
      type: "text",
      error: null,
    },
    service: {
      key: "service",
      label: "Service",
      required: true,
      optTitle: "a service",
      data: null,
      optList: [],
      error: null,
    },
    thickness: {
      key: "thickness",
      label: "Thickness",
      required: true,
      optTitle: "a thickness",
      data: null,
      optList: [],
      error: null,
    },
    price: {
      key: "price",
      label: "Unit Price (LKR)",
      required: true,
      min: 0,
      data: 0,
      type: "number",
      error: null,
    },
    quantity: {
      key: "quantity",
      label: "Quantity",
      required: true,
      min: 1,
      data: 0,
      type: "number",
      error: null,
    },
  };

  const [inputs, setInputs] = useState(inputDataStructure);
  const [services, setServices] = useState([]);
  const [labourList, setLabourList] = useState([]);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    if (input.key === "service") {
      const selectedService = services.find(
        (service) => service.id === input.data
      );
      if (selectedService) {
        const thicknessOptions = selectedService.unitPrice.map((item) => ({
          id: item.thickness,
          title: item.thickness,
        }));
        input_list.thickness.optList = thicknessOptions;
        input_list.price.data = 0; // Reset price
      } else {
        input_list.thickness.optList = [];
      }
    } else if (input.key === "thickness") {
      const selectedService = services.find(
        (service) => service.id === inputs.service.data
      );
      if (selectedService) {
        const selectedUnit = selectedService.unitPrice.find(
          (price) => price.thickness === input.data
        );
        if (selectedUnit) {
          input_list.price.data = selectedUnit.price;
        } else {
          input_list.price.data = 0;
        }
      }
    }

    setInputs(input_list);
  };

  const buildForm = async () => {
    try {
      const response = await apiService.get("service");
      const filteredServices = response.filter(
        (service) => service.status === true
      );
      const optList = filteredServices.map((service) => ({
        id: service.id,
        title: service.title,
      }));
      setInputs((prevInputs) => ({
        ...prevInputs,
        service: { ...prevInputs.service, optList },
      }));
      setServices(filteredServices);
    } catch (error) {
      toast.error("Failed to load data.");
    }
  };

  const getData = async () => {
    const labours = await apiService.get(`job/service/${selectedJob.id}`);
    setLabourList(labours);
  };

  useEffect(() => {
    buildForm();
    getData();
  }, []);

  const handleDelete = async (jobId, id) => {
    try {
      const formData = {
        jobId: jobId,
        serviceId: id,
      };
      setIsLoading(true);
      await apiService.patch(`job/deleteService`, formData);
      toast.success("Machine deleted successfully!");
      getData();
      setIsLoading(false);
    } catch (e) {
      toast.error(e.message);
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    const formData = {
      job: selectedJob.id,
      service: inputs.service.data,
      thickness: inputs.thickness.data,
      price: inputs.price.data,
      quantity: inputs.quantity.data,
    };

    try {
      setIsLoading(true);
      await apiService.patch("job/service", formData);
      toast.success("Product Services updated successfully!");
      getData();
      buildForm();

      setIsLoading(false);

      setInputs(inputDataStructure);
    } catch (e) {
      toast.error(e.message);
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: "#",
      width: "2%",
      render: (arg, record, index) => {
        const rowNumber = index + 1;
        return <p className="text-center">{rowNumber}</p>;
      },
    },
    {
      title: "DATE",
      align: "center",
      width: "5%",
      render: (arg) => {
        const date = Moment(arg.date);
        const formattedDatetime = date.format("YYYY-MM-DD");
        return formattedDatetime;
      },
    },
    {
      title: "Title",
      align: "center",
      width: "5%",
      dataIndex: "service",
    },
    {
      title: "Thickness",
      key: "hours",
      align: "center",
      width: "5%",
      dataIndex: "thickness",
    },
    {
      title: "Price",
      align: "center",
      width: "5%",
      dataIndex: "price",
    },
    {
      title: "Quantity",
      align: "center",
      width: "5%",
      dataIndex: "quantity",
    },
    {
      title: "TOTAL",
      align: "center",
      width: "10%",
      render: (arg) => {
        const total = arg.quantity * arg.price;
        const formattedPrice = total
          ? total.toLocaleString("en-US", {
              style: "currency",
              currency: "LKR",
            })
          : "0";
        return formattedPrice;
      },
    },
    {
      title: "ACTIONS",
      align: "center",
      width: "5%",
      render: (arg) => {
        return (
          <Button
            handleClick={() => handleDelete(selectedJob.id, arg.service_id)}
            text="Delete"
          />
        );
      },
    },
  ];

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      onClick={handleClose}
    >
      <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75">
        <div
          className="transform shadow-xl transition-all w-[80%] mx-auto mt-[100px] rounded-lg bg-white"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
            <h3 className="py-1 mx-auto text-xl text-center text-white bg-primary">
              SERVICE CENTER
            </h3>
            <span
              className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
              onClick={handleClose}
            >
              X
            </span>
          </div>
          <div className="flex gap-4 px-4">
            <div className="w-[80%]">
              <div className="w-full">
                <h3 className="w-full text-lg text-center bg-slate-200 text-slate-500">
                  SERVICE COSTING
                </h3>
                <Table
                  columns={columns}
                  dataSource={labourList}
                  scroll={{ y: 400 }}
                />
              </div>
            </div>
            <div className="w-[25%] mt-2">
              <Select input={inputs.service} handleChange={handleChange} />
              <Select input={inputs.thickness} handleChange={handleChange} />
              <Input
                input={inputs.price}
                handleChange={handleChange}
                readOnly
              />
              <Input input={inputs.quantity} handleChange={handleChange} />
              <Input input={inputs.remarks} handleChange={handleChange} />
              {isLoading ? (
                "Wait..."
              ) : (
                <Button
                  handleClick={handleSubmit}
                  className="w-full mt-6"
                  text={"ADD"}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
