import React, { useEffect, useState } from "react";
import Moment from "moment";
import { Input, Button, Select } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";
import { Table } from "antd";

const ProductMaterialModal = ({ handleClose, selectedSupplier }) => {
  const inputDataStructure = {
    material: {
      key: "material",
      label: "Material",
      required: true,
      optTitle: "a material",
      optList: [],
      data: null,
      error: null,
    },
    quantity: {
      key: "quantity",
      label: "Quantity",
      required: true,
      min: 0,
      data: 0,
      type: "number",
      error: null,
    },
    price: {
      key: "price",
      label: "Import Price",
      required: true,
      min: 0,
      data: 0,
      type: "number",
      error: null,
    },
    invNumber: {
      key: "invNumber",
      label: "Invoice Number",
      data: "",
      type: "text",
      error: null,
    },
  };

  const [inputs, setInputs] = useState(inputDataStructure);
  const [materials, setMaterials] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [materialList, setMaterialList] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    if (input.key === "material") {
      const material = materials.find((item) => item.id === input.data);
      if (material) {
        setSelectedMaterial(material);
      } else {
        console.error(`Material with id ${input.data} not found.`);
        setSelectedMaterial(null);
      }
    }
    setInputs(input_list);
  };

  const buildForm = async () => {
    let materials = await apiService.get(`material`);
    materials = materials.filter((material) => material.status === true);

    let input_list = { ...inputs };
    const optList = materials.map((material) => ({
      id: material.id,
      title: `${material.title} ( ${material.materialId})`,
    }));
    input_list.material.optList = optList;

    setInputs(input_list);
    setMaterials(materials);
  };

  useEffect(() => {
    buildForm();
  }, []);

  const handleAddToTable = () => {
    if (!inputs.material.data) {
      toast.error("Please select a material.");
      return;
    }
    if (inputs.quantity.data <= 0) {
      toast.error("Quantity must be greater than 0.");
      return;
    }
    if (inputs.price.data <= 0) {
      toast.error("Price must be greater than 0.");
      return;
    }
    if (!inputs.invNumber.data.trim()) {
      toast.error("Invoice number is required.");
      return;
    }

    const newData = {
      date: new Date(),
      idMaterial: selectedMaterial.id,
      title: selectedMaterial.title,
      price: inputs.price.data,
      quantity: inputs.quantity.data,
      invNumber: inputs.invNumber.data,
    };

    setMaterialList((prevList) => {
      const updatedList = [...prevList, newData];
      calculateTotalAmount(updatedList);
      return updatedList;
    });

    // Reset inputs
    let input_list = { ...inputs };
    input_list.material.data = null;
    input_list.quantity.data = 0;
    input_list.price.data = 0;
    input_list.invNumber.data = "";
    setInputs(input_list);
    setSelectedMaterial(null);
  };

  const calculateTotalAmount = (list) => {
    const total = list.reduce(
      (sum, item) => sum + item.price * item.quantity,
      0
    );
    setTotalAmount(total);
  };

  const handleSave = async () => {
    try {
      const data = {
        materialList: materialList,
        Supplier: selectedSupplier.id,
        totalAmount: totalAmount,
      };
      console.log(data);
      setIsLoading(true);
      await apiService.patch("supplier/grn", data);
      toast.success("Transaction completed successfully!");
      buildForm();
      handleClose();
      setMaterialList([]);
      setTotalAmount(0);
      setIsLoading(false);
    } catch (e) {
      toast.error(e);
      setIsLoading(false);
    }
  };

  const handleDelete = (index) => {
    setMaterialList((prevList) => {
      const newList = [...prevList];
      newList.splice(index, 1);
      calculateTotalAmount(newList);
      return newList;
    });
  };

  const columns = [
    {
      title: "Date",
      align: "center",
      render: (arg) => Moment(arg.date).format("YYYY-MM-DD"),
      width: "15%",
    },
    {
      title: "Material",
      align: "center",
      dataIndex: "title",
      width: "15%",
    },
    {
      title: "Import Price",
      align: "center",
      dataIndex: "price",
      width: "15%",
    },
    {
      title: "Quantity",
      align: "center",
      dataIndex: "quantity",
      width: "15%",
    },
    {
      title: "Invoice Number",
      align: "center",
      dataIndex: "invNumber",
      width: "15%",
    },
    {
      title: "Actions",
      align: "center",
      width: "25%",
      render: (text, record, index) => (
        <Button handleClick={() => handleDelete(index)} text="Delete" />
      ),
    },
  ];

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75 ">
        <div className="shadow-xl transition-all w-[90%] mx-auto mt-[100px] rounded-lg bg-white">
          <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
            <h3 className="py-1 mx-auto">GRN CENTER</h3>
            <span
              className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
              onClick={handleClose}
            >
              X
            </span>
          </div>
          <div className="mx-4 mt-2">
            <div className="flex gap-4">
              <div className="w-full">
                <Table
                  columns={columns}
                  dataSource={materialList}
                  rowKey={(record) => record.date.toString() + record.title}
                  scroll={{ y: 400 }}
                />
              </div>
              <div className="w-[25%]">
                <h3 className="w-full mb-4 text-lg text-center bg-slate-200 text-slate-500">
                  ADD MATERIALS
                </h3>
                <Select input={inputs.material} handleChange={handleChange} />
                <Input input={inputs.quantity} handleChange={handleChange} />
                <Input input={inputs.price} handleChange={handleChange} />
                <Input input={inputs.invNumber} handleChange={handleChange} />
                <div className="mt-4">
                  <h4>Total Amount: {totalAmount}</h4>
                </div>
                {isLoading ? (
                  "Wait..."
                ) : (
                  <>
                    <Button
                      handleClick={handleAddToTable}
                      className="w-full mt-2"
                      text="ADD TO TABLE"
                    />
                    <Button
                      handleClick={handleSave}
                      className="w-full mt-2"
                      text="SAVE"
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductMaterialModal;
