import React from "react";

const InvoicePrint = ({ item, number, handleRemove }) => {
    let amount = 0;


    if (item.types === "material") {
                amount = ((item.price - (item.price * item.discount) / 100) * item.fullquantity) + (item.offCutUsage * item.offcutprice);
    } else {
        amount = (item.price - (item.price * item.discount) / 100) * item.quantity;
    }

    let quantity = item.quantity;
    let rate = item.price;

    return (
        <tr className="py-6 mt-6 border-b-2 h-9 border-slate-300">
            <td className="text-left"># {number}</td>
            <td className="text-left">{item.title}</td>
            <td className="text-right">{quantity}</td>
            <td className="text-right">
                {rate.toLocaleString("en-US", {
                    style: "currency",
                    currency: "LKR",
                })}
            </td>
            <td className="text-right">{`${item.discount}%`}</td>
            <td className="text-right">
                {amount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "LKR",
                })}
            </td>
        </tr>
    );
};

export default InvoicePrint;
