import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { toast } from "react-toastify";
import apiService from "../../../../http";
import ReactDOMServer from "react-dom/server";
import PrintReport from "./PrintReport"; // Adjust the import path as needed
import { Button } from "../../../../components/ui"; // Adjust the import path as needed

const CostingReport = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const refreshOrders = async () => {
    setIsLoading(true);
    try {
      const response = await apiService.get("order/profit");
      if (response) {
        setOrders(response);
        setFilteredOrders(response);
      } else {
        setOrders([]);
        setFilteredOrders([]);
      }
    } catch (e) {
      toast.error(e.message);
      setOrders([]);
      setFilteredOrders([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    refreshOrders();
  }, []);

  useEffect(() => {
    filterOrders();
  }, [fromDate, toDate]);

  const filterOrders = () => {
    let filtered = orders;

    if (fromDate) {
      const from = new Date(fromDate);
      from.setHours(0, 0, 0, 0);
      filtered = filtered.filter((order) => {
        const orderDate = new Date(order.createdAt);
        orderDate.setHours(0, 0, 0, 0);
        return orderDate >= from;
      });
    }
    if (toDate) {
      const to = new Date(toDate);
      to.setHours(23, 59, 59, 999);
      filtered = filtered.filter((order) => {
        const orderDate = new Date(order.createdAt);
        orderDate.setHours(0, 0, 0, 0);
        return orderDate <= to;
      });
    }

    setFilteredOrders(filtered);
  };

  const columns = [
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => (
        <p className="text-center">{new Date(date).toLocaleDateString()}</p>
      ),
    },
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Total Order Amount",
      dataIndex: "totalOrderAmount",
      key: "totalOrderAmount",
    },
    {
      title: "Total Cost",
      dataIndex: "totalCost",
      key: "totalCost",
    },
    {
      title: "Profit",
      key: "profit",
      render: (text, record) => record.totalOrderAmount - record.totalCost,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => (
        <p className="text-center">{new Date(date).toLocaleDateString()}</p>
      ),
    },
  ];

  const handlePrint = () => {
    const printContent = ReactDOMServer.renderToString(
      <PrintReport orders={filteredOrders} />
    );
    const printWindow = window.open("", "Print", "width=800,height=600");
    printWindow.document.write(
      `<html><head><title>Costing Report</title></head><body>${printContent}</body></html>`
    );
    printWindow.document.write(
      `<script>window.onload = function() { window.print(); window.onafterprint = function() { window.close(); }; };</script>`
    );
    printWindow.document.close();
  };

  const totalAmount = filteredOrders.reduce(
    (acc, order) => acc + (order.totalOrderAmount - order.totalCost),
    0
  );

  return (
    <section className="w-full">
      <div className="p-6 mx-3 my-1 border-2 rounded-md">
        <p className="text-lg font-bold">COSTING REPORT</p>
        <div className="flex mb-4 space-x-3">
          <div className="w-[200px]">
            <label className="block mb-2 text-sm font-medium text-gray-700">
              From Date
            </label>
            <input
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </div>

          <div className="w-[200px]">
            <label className="block mb-2 text-sm font-medium text-gray-700">
              To Date
            </label>
            <input
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </div>
        </div>
        <Button
          text="CLEAR FILTERS"
          className="ml-3 hover:bg-primary hover:text-white px-[14px]"
          handleClick={() => {
            setFromDate("");
            setToDate("");
            setFilteredOrders(orders);
          }}
        />
        <Button
          text="PRINT"
          className="ml-3 hover:bg-primary hover:text-white px-[14px]"
          handleClick={handlePrint}
          disabled={isLoading} // Disable the button while loading
        />
      </div>
      <div id="printableArea" className="p-6 mx-3 my-1 border-2 rounded-md">
        <Table
          columns={columns}
          dataSource={filteredOrders}
          rowKey="orderId"
          style={{ width: "100%" }}
        />
        <p className="mt-4 font-bold text-right">
          Total Profit:{" "}
          {totalAmount
            .toLocaleString("en-US", {
              style: "currency",
              currency: "LKR",
            })
            .replace(/LKR/g, "")}{" "}
          {/* Removing LKR */}
        </p>
      </div>
    </section>
  );
};

export default CostingReport;
