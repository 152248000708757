import React, { useEffect, useState } from "react";
import { Table } from "antd";
import apiService from "../../../http";

const ProductModal = ({ handleClose, selectedOrder }) => {
    const [products, setProducts] = useState([]);

    const columns = [
        {
            title: "#",
            width: "5%",
            render: (arg, record, index) => {
                const rowNumber = index + 1;
                return <p className="text-center">{rowNumber}</p>;
            },
        },

        {
            title: "PRODUCT TITLE",
            dataIndex: "title",
            key: "title",
            align: "center",
            width: "30%",
        },
        {
            title: "QUANTITY",
            dataIndex: "quantity",
            key: "quantity",
            align: "center",
            width: "20%",
        },
        {
            title: "RATE",
            align: "center",
            width: "20%",
            render: (arg) => {
                console.log(arg);
                const formattedPrice = Number(arg.unitPrice).toLocaleString(
                    "en-US",
                    {
                        style: "currency",
                        currency: "LKR",
                    }
                );
                return formattedPrice;
            },
        },
        {
            title: "TOTAL",
            align: "center",
            width: "20%",
            render: (arg) => {
                const total = arg.unitPrice * arg.quantity;
                const formattedPrice = total.toLocaleString("en-US", {
                    style: "currency",
                    currency: "LKR",
                });
                return formattedPrice;
            },
        },
        

    ];

    const getData = async () => {
        try {
            const orderData = await apiService.get(`order/${selectedOrder.id}`);

            setProducts(orderData.products);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75 ">
                <div className=" shadow-xl transition-all w-[70%] mx-auto mt-[100px] rounded-lg bg-white">
                    <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
                        <h3 className="py-1 mx-auto">ORDERED PRODUCT STATUS </h3>
                        <span
                            className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
                            onClick={handleClose}
                        >
                            X
                        </span>
                    </div>
                    <div className="mx-4 mt-2">
                        <div className="w-full">
                            <Table
                                pagination={{
                                    defaultPageSize: 4,
                                }}
                                columns={columns}
                                dataSource={products}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductModal;
