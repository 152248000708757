import React, { useEffect, useState } from "react";
import { Input, Select, Button } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";

const SupplierModal = ({ handleClose, customer }) => {
  const inputDataStructure = {
    paidAmount: {
      key: "paidAmount",
      label: "Paid Amount",
      required: true,
      type: "number",
      error: null,
    },
    chequeDate: {
      key: "chequeDate",
      label: "Date Of Cheque",
      type: "date",
      error: null,
      required: true,
      allowFutureDates: true, // Change this flag to true if future dates should be allowed
    },
    chequeRecievedDate: {
      key: "chequeRecievedDate",
      label: "Cheque Received Date",
      type: "date",
      error: null,
      required: true,
    },
    chequeRef: {
      key: "chequeRef",
      label: "Cheque Reference",
      type: "text",
      error: null,
    },
    paymentMethod: {
      key: "paymentMethod",
      label: "Payment Method",
      type: "select",
      optList: [],
      error: null,
      required: true,
    },
    chequeNum: {
      key: "chequeNum",
      label: "Cheque Number",
      type: "text",
      error: null,
      required: true,
    },
    chequebank: {
      key: "chequebank",
      label: "Bank Name",
      type: "text",
      error: null,
      required: true,
    },
    accountName: {
      key: "accountName",
      label: "Account Name",
      type: "text",
      error: null,
      required: true,
    },
    bank: {
      key: "bank",
      label: "Bank Name",
      type: "text",
      error: null,
      required: true,
    },
    accountNumber: {
      key: "accountNumber",
      label: "Account Number",
      type: "text",
      error: null,
      required: true,
    },
  };
  const [inputs, setInputs] = useState(inputDataStructure);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    setInputs(input_list);
  };

  const buildForm = async () => {
    let input_list = { ...inputs };

    try {
      input_list.paidAmount.data = customer.outstandValue;

      const paymentMethodOptions = [
        { id: "cash", title: "Cash" },
        { id: "bank", title: "Bank" },
        { id: "cheque", title: "Cheque" },
      ];
      input_list.paymentMethod.optList = paymentMethodOptions;
    } catch (error) {
      console.error("Error fetching supplier data:", error);
    }
    setInputs(input_list);
  };

  const handlePrint = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US");
    const formattedTime = currentDate.toLocaleTimeString("en-US");

    let printContent = `
          <table style="width: 100%; border-collapse: collapse;">
            <thead>
              <tr>
                <th style="border: 1px solid black; padding: 5px;">Description</th>
                <th style="border: 1px solid black; padding: 5px;">Outstanding Amount</th>
                <th style="border: 1px solid black; padding: 5px;">Paid Amount</th>
                <th style="border: 1px solid black; padding: 5px;">Payment Method</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="border: 1px solid black; padding: 5px;">Outstand Payment</td>
                <td style="border: 1px solid black; padding: 5px; text-align: right;">${customer.outstandValue}</td>
                <td style="border: 1px solid black; padding: 5px; text-align: right;">${inputs.paidAmount.data}</td>
                <td style="border: 1px solid black; padding: 5px; text-align: right;">${inputs.paymentMethod.data}</td>
              </tr>
            </tbody>
          </table>
        `;

    const printWindow = window.open("", "_blank"); // Open a new blank window
    printWindow.document.write(
      `<html><head><title>Invoice</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                margin: 0;
                padding: 0;
                font-size: 12px;
                color: #000;
              }
              .container {
                width: 95%;
                margin: 0 auto;
                padding: 20px;
                border: 1px solid #000;
                box-sizing: border-box; /* Ensures the padding and border are included in the width */
              }
              .header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
              }
              .header-left {
                width: 70%;
                font-weight: bold;
                line-height: 1.5;
              }
              .header-right {
                width: 25%;
                text-align: right;
              }
              .header-right img {
                max-width: 100px;
              }
              .invoice-title {
                text-align: center;
                font-size: 24px;
                font-weight: bold;
                margin-bottom: 20px;
              }
              .invoice-details {
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
              }
              .invoice-details div {
                width: 30%;
              }
              .invoice-details div p {
                margin: 5px 0;
              }
              table {
                width: 100%;
                border-collapse: collapse;
                margin-bottom: 20px;
              }
              table, th, td {
                border: 1px solid black;
                padding: 8px;
              }
              th {
                background-color: #f2f2f2;
                text-align: center;
              }
              .footer {
                text-align: center;
                font-size: 10px;
                margin-top: 20px;
              }
              .signature {
                margin-top: 40px;
                text-align: right;
              }
              .signature p {
                margin: 0;
              }
            </style>
            </head><body>
            
            <div class="container">
              <div class="header">
                <div class="header-left">
                  Nsteel Engineering<br>
                  විලකොළදෙනිය, මලංගම, රත්නපුර<br>
                  Tel: 045-2121750, 074-0327850
                </div>
                <div class="header-right">
                  <img src="/logo.png" alt="Logo">
                </div>
              </div>
              
              <div class="invoice-title">RECEIPT</div>
              
              <div class="invoice-details">
                <div>
                  <p><strong>Receipt Date:</strong> ${formattedDate}</p>
                  <p><strong>Receipt Time:</strong> ${formattedTime}</p>
                </div>
                <div>
                  <p><strong>Invoiced By:</strong> Nsteel Engineering</p>
                  <p><strong>Customer Name:</strong> ${
                    customer.title || "N/A"
                  }</p>
                  <p><strong>Customer Phone:</strong> ${
                    customer.numberMob || "N/A"
                  }</p>
                </div>
              </div>
      
              ${printContent}
      
              <div class="footer">
                * 30 days credit only *<br>
                Cheques to be written in favor of Nsteel Engineering & CROSSED AS A/C PAYEE ONLY<br>
              </div>
              
              <div class="signature">
                <p>Authorized Signature: _______________________</p>
              </div>
            </div>
            
            <script>
              window.onload = function() { 
                window.print(); 
                window.onafterprint = function() { window.close(); }; 
              };
            </script>
          </body></html>`
    );
    printWindow.document.close();
  };

  useEffect(() => {
    buildForm();
  }, []);

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    const formData = {
      id: customer.id,
      paymentMethod: inputs.paymentMethod.data,
      paidAmount: inputs.paidAmount.data,

      accountNumber: inputs.accountNumber.data,
      bank: inputs.bank.data,
      accountName: inputs.accountName.data,

      chequeDate: inputs.chequeDate.data,
      chequeRecievedDate: inputs.chequeRecievedDate.data,
      chequeRef: inputs.chequeRef.data,
      chequeNum: inputs.chequeNum.data,
      chequebank: inputs.chequebank.data,
    };

    try {
      setIsLoading(true);

      await apiService.post("customer/pay", formData);
      handlePrint();
      toast.success("Payment Success!");

      handleClose();
      setIsLoading(false);
    } catch (e) {
      toast.error(e);
      setIsLoading(false);
    }
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <style>
        {`
                    /* Hide number up-down buttons for number inputs */
                    input[type=number]::-webkit-inner-spin-button,
                    input[type=number]::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                    input[type=number] {
                        -moz-appearance: textfield; /* Firefox */
                    }
                `}
      </style>
      <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75">
        <div className="shadow-xl transition-all w-[50%] mx-auto mt-[100px] rounded-lg bg-white">
          <div className="flex flex-col h-full">
            <div className="flex items-center justify-between w-full mb-3 text-xl text-white bg-primary">
              <h3 className="py-1 mx-auto">OUTSTANDING PAYMENT</h3>
              <span
                className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
                onClick={handleClose}
              >
                X
              </span>
            </div>
            <div className="flex justify-between px-4 mt-1">
              <div className="w-[45%]">
                <Input input={inputs.paidAmount} handleChange={handleChange} />
                <Select
                  input={inputs.paymentMethod}
                  handleChange={handleChange}
                />
              </div>
              <datalist id="bank">
                <option key={"Sampath Bank"} value={"Sampath Bank"} />
                <option
                  key={"Bank Of Ceylon Bank"}
                  value={"Bank Of Ceylon Bank"}
                />
                <option
                  key={"National Development Bank"}
                  value={"National Development Bank"}
                />
                <option key={"DFCC Bank"} value={"DFCC Bank"} />
                <option
                  key={"Hatton National Bank"}
                  value={"Hatton National Bank"}
                />

                <option key={"Amana Bank"} value={"Amana Bank"} />
                <option key={"Pan Asia Bank"} value={"Pan Asia Bank"} />
                <option
                  key={"Nations Trust Bank"}
                  value={"Nations Trust Bank"}
                />
                <option
                  key={"National Savings bank"}
                  value={"National Savings bank"}
                />
                <option key={"Commercial Bank"} value={"Commercial Bank"} />

                <option key={"Seylan Bank"} value={"Seylan Bank"} />
                <option key={"Hsbc"} value={"Hsbc"} />
                <option key={"People's Bank"} value={"People's Bank"} />
                <option key={"Cargills Bank"} value={"Cargills Bank"} />
              </datalist>
              <div className="w-[45%]">
                {["cheque"].includes(inputs.paymentMethod.data) && (
                  <>
                    <Input
                      input={inputs.chequeDate}
                      handleChange={handleChange}
                    />
                    <Input
                      input={inputs.chequeRecievedDate}
                      handleChange={handleChange}
                    />
                    <Input
                      input={inputs.chequeNum}
                      handleChange={handleChange}
                    />
                    <Input
                      input={inputs.chequeRef}
                      handleChange={handleChange}
                    />
                    <Input
                      input={{
                        ...inputs.chequebank,
                        list: "bank",
                      }}
                      handleChange={handleChange}
                    />
                  </>
                )}
                {["bank"].includes(inputs.paymentMethod.data) && (
                  <>
                    <Input
                      input={inputs.accountName}
                      handleChange={handleChange}
                    />
                    <Input
                      input={{
                        ...inputs.bank,
                        list: "bank",
                      }}
                      handleChange={handleChange}
                    />
                    <Input
                      input={inputs.accountNumber}
                      handleChange={handleChange}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="px-4 mt-1">
            {isLoading ? (
              "Wait..."
            ) : (
              <Button handleClick={handleSubmit} className="mt-1" text="PAY" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierModal;
