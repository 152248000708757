import React from "react";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import {
    FaPowerOff,
} from "react-icons/fa";
const navigationData = [
    {
        title: "Logout",
        url: "/logout",
        icon: <FaPowerOff />,
    }
];

const NavBar = ({ dashboard }) => {
    const userMenuclassList = () => {
        let classList =
            "absolute z-10 right-0 w-48 mt-2 py-2 bg-white rounded-md shadow-xl transform transition-all duration-300 ";

        return classList;
    };

    return (
        <div>
            <ToastContainer />

            <nav className="fixed top-0 left-0 z-30 w-full h-16 border-b-2 header-nav bg-primary border-slate-500">
                <div className="w-[95%] mx-auto flex justify-between items-center">
                    <a className="flex" href="/">
                        <img
                            className="h-[50px] mt-2"
                            src={require("../../assets/img/logo.png")}
                            alt="Logo"
                        />
                    </a>
                    <div className="flex space-x-4">
                        {navigationData.map((item, index) => (
                            <a 
                                key={index} 
                                href={item.url} 
                                className="flex items-center font-semibold text-white hover:text-gray-300"
                                onClick={item.onClick} // Attach the onClick handler
                            >
                                {item.icon && <span className="mr-1">{item.icon}</span>}
                                {item.title}
                            </a>
                        ))}
                    </div>
                </div>
            </nav>

        </div>
    );
};

export default NavBar;
