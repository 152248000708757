import React, { useEffect, useState } from "react";
import { Input, Button } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";
import { Table } from "antd";

const ProductModal = ({ handleClose, selectedService }) => {
  const [isLoading, setIsLoading] = useState(false);

  const inputDataStructure = {
    thickness: {
      key: "thickness",
      label: "Thickness",
      data: "",
      type: "text",
      required: true,
      error: null,
    },

    price: {
      key: "price",
      label: "Price",
      required: true,
      min: 1,
      data: 0,
      type: "number",
      error: null,
    },
  };

  const [inputs, setInputs] = useState(inputDataStructure);
  const [thickness, setThickness] = useState([]);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;
    setInputs(input_list);
  };

  const getData = async () => {
    const service = await apiService.get(
      `service/thickness/${selectedService.id}`
    );
    setThickness(service);
  };

  useEffect(() => {
    getData();
  }, []);
  const handleDelete = async (date) => {
    try {
      const formData = {
        date: date,
        service: selectedService.id,
      };
      setIsLoading(true);
      await apiService.patch(`service/deleteService`, formData);
      toast.success("Thickness deleted successfully!");
      getData();

      setIsLoading(false);
    } catch (e) {
      toast.error(e.message);
      setIsLoading(false);
    }
  };
  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    const formData = {
      service: selectedService.id,
      thickness: inputs.thickness.data,
      price: inputs.price.data,
    };

    try {
      setIsLoading(true);

      await apiService.patch("service/machines", formData);
      toast.success("Service updated successfully!");
      getData();

      setIsLoading(false);

      inputs.price.data = 0;
      inputs.thickness.data = "";
    } catch (e) {
      toast.error(e);
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: "#",
      width: "5%",
      render: (arg, record, index) => {
        const rowNumber = index + 1;
        return <p className="text-center">{rowNumber}</p>;
      },
    },
    {
      title: "Thickness",
      dataIndex: "thickness",
      key: "Thickness",
      align: "center",
      width: "25%",
    },
    {
      title: "Price",
      key: "price",
      align: "center",
      width: "25%",
      dataIndex: "price",
    },
    // {
    //     title: "OT RATE",
    //     align: "center",
    //     width: "20%",
    //     render: (arg) => {
    //         const formattedPrice = arg.labouroverTime
    //             ? arg.labouroverTime.toLocaleString("en-US", {
    //                   style: "currency",
    //                   currency: "LKR",
    //               })
    //             : "0";
    //         return formattedPrice;
    //     },
    // },
    // {
    //     title: "RATE",
    //     align: "center",
    //     width: "20%",
    //     render: (arg) => {
    //         const formattedPrice = arg.labourHourlyRate.toLocaleString(
    //             "en-US",
    //             {
    //                 style: "currency",
    //                 currency: "LKR",
    //             }
    //         );
    //         return formattedPrice;
    //     },
    // },
    {
      title: "ACTIONS",
      align: "center",
      width: "5%",
      render: (arg) => {
        return (
          <Button handleClick={() => handleDelete(arg.date)} text="Delete" />
        );
      },
    },
  ];

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      onClick={handleClose}
    >
      <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75">
        <div
          className="transform shadow-xl transition-all w-[70%] mx-auto mt-[100px] rounded-lg bg-white"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
            <h3 className="py-1 mx-auto text-xl text-center text-white bg-primary">
              PRICE CENTER
            </h3>
            <span
              className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
              onClick={handleClose}
            >
              X
            </span>
          </div>
          <div className="flex gap-4 px-4">
            <div className="w-[80%]">
              <div className="w-full">
                <h3 className="w-full text-lg text-center bg-slate-200 text-slate-500">
                  Thickness prices
                </h3>
                <Table
                  columns={columns}
                  dataSource={thickness}
                  scroll={{ y: 400 }}
                />
              </div>
            </div>
            <div className="w-[25%] mt-2">
              <Input input={inputs.thickness} handleChange={handleChange} />

              <Input input={inputs.price} handleChange={handleChange} />

              {isLoading ? (
                "Wait..."
              ) : (
                <Button
                  handleClick={handleSubmit}
                  className="w-full mt-6"
                  text={"ADD"}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
