import React, { useEffect, useState } from "react";
import Moment from "moment";
import { Input, Button, Select, Confirm } from "../../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../../http";
import { Table } from "antd";

const ProductMAterialModal = ({ handleClose, selectedJob }) => {
    const inputDataStructure = {
        transactionType: {
            key: "transactionType",
            label: "Transaction Type",
            required: true,
            optTitle: "a type",
            optList: [
                { id: "import", title: "Import from the stock" },
                { id: "return", title: "Return to the stock" },
            ],
            data: null,
            error: null,
        },

        material: {
            key: "material",
            label: "Material",
            required: true,
            optTitle: "a material",
            optList: [],
            data: null,
            error: null,
        },

        quantity: {
            key: "quantity",
            label: "Quantity",
            required: true,
            min: 0,
            data: 0,
            type: "number",
            error: null,
        },
        offCutQuantity: {
            key: "offCutQuantity",
            label: "Off Cut",
            required: true,
            min: 0,
            data: 0,
            type: "number",
            error: null,
        },
        remarks: {
            key: "remarks",
            label: "Remarks",
            data: "",
            type: "text",
            error: null,
        },
    };

    const [inputs, setInputs] = useState(inputDataStructure);

    const [materials, setMaterials] = useState([]);
    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [materialList, setMaterialList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (input) => {
        let input_list = { ...inputs };
        input_list[input.key] = input;

        if (input.key === "material") {
            const material = materials.find((item) => {
                
                return item.id === input.data;

            });

            setSelectedMaterial(material);

            console.log();
        }
        setInputs(input_list);
    };

    const buildForm = async () => {
        let materials = await apiService.get(`material`);
        materials = materials.filter(material => material.status === true);

        let input_list = { ...inputs };

        const optList = materials.map((material) => ({
            id: material.id,
            title: `${material.title} (Full: ${material.stock.full}, Offset: ${material.stock.offCut})`
        }));

        input_list.material.optList = optList;

        setInputs(input_list);
        setMaterials(materials);
    };

    const getData = async () => {
        const materialList = await apiService.get(
            `job/materials/${selectedJob.id}`
        );

        setMaterialList(materialList);
    };

    useEffect(() => {
        buildForm();
        getData();
    }, []);

    const handleTransaction = async () => {
        const event = window.event;
        event.preventDefault();
    
        if (!selectedMaterial) {
            toast.error("Please select a valid material.");
            return;
        }
        // if(inputs.transactionType.data==="import"){
        //     if (selectedMaterial.stock.full < inputs.quantity.data && inputs.quantity.data !== 0) {
        //         toast.error("Quantity Error: Quantity exceeds available stock.");
        //         return;
        //     } else if (inputs.offCutQuantity.data !== 0 && selectedMaterial.stock.offCut < inputs.offCutQuantity.data) {
        //         toast.error("Offcut Quantity Error: Offcut quantity exceeds available stock.");
        //         return;
        //     }
        // }else if(inputs.transactionType.data==="return"){
        //     const selectedMaterialInTable = materialList.find(material => material.id === inputs.material.data);
        
        //     if (!selectedMaterialInTable) {
        //         toast.error("Selected material not found in the table.");
        //         return;
        //     }
        
        //     if (stockCount(selectedMaterialInTable) < inputs.quantity.data && inputs.quantity.data !== 0) {
        //         toast.error("Quantity Error: Quantity exceeds available stock.");
        //         return;
        //     } else if (inputs.offCutQuantity.data !== 0 && offstockCount(selectedMaterialInTable) < inputs.offCutQuantity.data) {
        //         toast.error("Offcut Quantity Error: Offcut quantity exceeds available stock.");
        //         return;
        //     }
        // }
    
        
    
        const data = {
            job: selectedJob.id,
            material: selectedMaterial.id,
            quantity: inputs.quantity.data,
            offCutQuantity: inputs.offCutQuantity.data,
            transactionType: inputs.transactionType.data,
        };
    
        try {
            setIsLoading(true);
            await apiService.patch("job/materials", data);
            toast.success("Transaction completed successfully!");
            buildForm();
            getData();
            setIsLoading(false);
            inputs.quantity.data=0;
        } catch (e) {
            toast.error(e);
            setIsLoading(false);

        }
    };
    
    const stockCount = (material) => {
        return material.amount;
    };

    const offstockCount = (material) => {
        return material.offCut;
    };
    const handleDelete = async (jobId, id) => {
        try {
            const formData = {
                jobId: jobId,
                materialId: id,
            };
            console.log(formData);
            setIsLoading(true);
            await apiService.patch(`job/deleteMaterial`,formData);
            toast.success("Material deleted successfully!");
            getData();
            setIsLoading(false);
        } catch (e) {
            toast.error(e.message);
            setIsLoading(false);
        }
    };
    const columns = [
        {
            title: "#",
            width: "15%",
            render: (arg, record, index) => {
                const rowNumber = index + 1;
                return <p className="text-center">{rowNumber}</p>;
            },
        },
        {
            title: "Date",
            align: "center",
            width: "10%",
            render: (arg) => {
                const date = Moment(arg.date);

                const formattedDatetime = date.format("YYYY-MM-DD");

                return formattedDatetime;
            },        },
        {
            title: "Title",
            align: "center",
            width: "20%",
            render: (arg) => {
                return arg.title;
            },
        },
        {
            title: "Transaction Type",
            align: "center",
            width: "10%",
            render: (arg) => {
                return arg.transactionType;
            },
        },
        {
            title: "Unit",
            align: "center",
            width: "10%",
            render: (arg) => {
                return arg.priceCalcMethod;
            },
        },
        {
            title: "Full",
            align: "center",
            width: "10%",
            render: (arg) => stockCount(arg),
        },
        {
            title: "OffCut",
            align: "center",
            width: "10%",
            render: (arg) => offstockCount(arg),
        },
        {
            title: "ACTIONS",
            align: "center",
            width: "5%",
            render: (arg) => {
                return (
                    <Button
                        handleClick={() => handleDelete(selectedJob.id, arg.materialId)}
                        text="Delete"
                    />
                );
            },
        },
    ];
    const availableMaterialsInTable = materialList.map(material => ({
        id: material.id,
        title: material.title
    }));
    
    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75 ">
                <div className=" shadow-xl transition-all w-[90%] mx-auto mt-[100px] rounded-lg bg-white">
                    <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
                        <h3 className="py-1 mx-auto">MATERIAL CENTER</h3>
                        <span
                            className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
                            onClick={handleClose}
                        >
                            X
                        </span>
                    </div>
                    <div className="mx-4 mt-2">
                        <div className="flex gap-4">
                            <div className="w-full">
                                <Table
                                    columns={columns}
                                    dataSource={materialList}
                                    scroll={{ y: 400 }}

                                />
                            </div>

                            <div className="w-[25%] ">
                                <h3 className="w-full mb-4 text-lg text-center bg-slate-200 text-slate-500">
                                    New operation
                                </h3>
                                <Select
                                    input={inputs.transactionType}
                                    handleChange={handleChange}
                                />
                                {inputs.transactionType.data === "return" && (
        <Select
            input={{
                ...inputs.material,
                optList: availableMaterialsInTable
            }}
            handleChange={handleChange}
        />
    )}
    {inputs.transactionType.data !== "return" && (
        <Select
            input={inputs.material}
            handleChange={handleChange}
        />
    )}

                                <Input
                                    input={inputs.quantity}
                                    handleChange={handleChange}
                                />
                                {selectedMaterial &&
                                    ["m", "cm","mm","ft","sqft","sqm"].includes(
                                        selectedMaterial.priceCalcMethod
                                    ) && (
                                        <Input
                                            input={inputs.offCutQuantity}
                                            handleChange={handleChange}
                                        />
                                    )}

                                <Input
                                    input={inputs.remarks}
                                    handleChange={handleChange}
                                />
                                {isLoading ? (
                        "Wait..."
                    ) : (
                                <Button
                                    handleClick={handleTransaction}
                                    className="w-full mt-2"
                                    text={"ADD"}
                                />)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductMAterialModal;
