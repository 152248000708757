import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";

import NavBar from "../../components/NavBar";
import { Input, Button } from "../../components/ui";
import apiService from "../../http";
import { userLoggedIn } from "../../redux/actions/userActions";
import { FaUser, FaLock } from "react-icons/fa";
import jwt_decode from "jwt-decode";

const SignupForm = () => {
    const dispatch = useDispatch();

    const inputDataStructure = {
        email: {
            key: "email",
            label: "Email",
            data: "",
            type: "email",
            placeholder: "Enter the email",
            icon: <FaUser />,
            error: null,
        },
        password: {
            key: "password",
            label: "Password",
            data: "",
            type: "password",
            placeholder: "Enter the password",
            icon: <FaLock />,
            error: null,
        },
        confirmPassword: {
            key: "confirmPassword",
            label: "Confirm Password",
            data: "",
            type: "password",
            placeholder: "Confirm the password",
            icon: <FaLock />,
            error: null,
        },
    };

    const [inputs, setInputs] = useState(inputDataStructure);

    const handleChange = (input) => {
        let input_list = { ...inputs };
        input_list[input.key] = { ...input_list[input.key], data: input.data, error: null };
        setInputs(input_list);
    };

    const handleSubmit = async (event) => {

        let data = {
            email: inputs.email.data,
            password: inputs.password.data,
            confirmPassword: inputs.confirmPassword.data,
        };

        // Manual validation
        const errors = {};
        if (!data.email) {
            errors.email = "Email is required";
        } else if (!/^\S+@\S+\.\S+$/.test(data.email)) {
            errors.email = "Please enter a valid email address";
        }

        if (!data.password) {
            errors.password = "Password is required";
        } else if (data.password.length < 8 || data.password.length > 30) {
            errors.password = "Password must be between 8 and 30 characters long";
        }

        if (!data.confirmPassword) {
            errors.confirmPassword = "Password confirmation is required";
        } else if (data.password !== data.confirmPassword) {
            errors.confirmPassword = "Passwords must match";
        }

        if (Object.keys(errors).length > 0) {
            const updatedInputs = { ...inputs };
            for (const key in errors) {
                updatedInputs[key].error = errors[key];
            }
            setInputs(updatedInputs);
            return;
        }

        try {
            const result = await apiService.post("user/signup", data);

            toast.success("Signed up successfully!");

            var decodedToken = jwt_decode(result.token);

            const userData = {
                token: result.token,
                email: decodedToken.email,
                avatar: decodedToken.avatar,
                role: decodedToken.role,
                exp: decodedToken.exp,
            };

            setTimeout(() => {
                dispatch(userLoggedIn(userData));
            }, 1000);
        } catch (e) {
            console.log(e);
            toast.error(e.response?.data?.message || "Signup failed");
        }
    };

    return (
        <div className="flex items-center justify-center w-full h-screen bg-img">
            <NavBar />
            <ToastContainer />
            <form className="drop-blur-lg bg-white bg-opacity-80 backdrop-blur-lg  w-[80vw] lg:w-[30vw] pb-20 mx-auto mt-[30%] lg:mt-[8%]  shadow-lg rounded-xl  bg-clip-padding  flex flex-col items-center">
                <h2 className="w-full py-1 mb-2 text-xl font-bold text-center text-white rounded-t-lg bg-primary md:text-xl">
                    Sign Up
                </h2>

                <div className="w-4/5">
                    <Input input={inputs.email} handleChange={handleChange} />
                    <Input
                        input={inputs.password}
                        handleChange={handleChange}
                    />
                    <Input
                        input={inputs.confirmPassword}
                        handleChange={handleChange}
                    />
                </div>

                <div className="w-4/5">
                    <Button text="Sign Up" handleClick={handleSubmit} />
                </div>
            </form>
        </div>
    );
};

export default SignupForm;
