import React, { useState } from "react";
import OrdersReport from "./orders";
import MaterialReport from "./material";
import ExpencesReport from "./expences";
import Sales from "./sales";
import Cost from "./cost";
import CostOrder from "./costorder";
import Customer from "./customer";
import Products from "./products";
import OrderCost from "./orderProfit";
import CusromerOuts from "./customerOutstanding";
import CustomerAll from "./customerAll";
import CustomerOut from "./customerOut";
import "./Reports.css";

const Reports = () => {
  const [subSection, setSubSection] = useState("");

  const renderSubSection = () => {
    switch (subSection) {
      case "orders":
        return <OrdersReport />;
      case "material":
        return <MaterialReport />;
      case "expences":
        return <ExpencesReport />;
      case "sale":
        return <Sales />;
      case "cost":
        return <Cost />;
      case "costorder":
        return <CostOrder />;
      case "customer":
        return <Customer />;
      case "products":
        return <Products />;
      case "orderCost":
        return <OrderCost />;
      case "customerOutstanding":
        return <CusromerOuts />;
      case "customerAll":
        return <CustomerAll />;
      case "customeroutPay":
        return <CustomerOut />;
      default:
        return null;
    }
  };

  return (
    <div className="reports-container">
      {subSection && (
        <button
          onClick={() => setSubSection("")}
          className="px-4 py-2 text-white bg-blue-500 back-button"
        >
          Back
        </button>
      )}
      {subSection ? (
        <div className="sub-section-content">{renderSubSection()}</div>
      ) : (
        <div className="grid-container">
          <button
            onClick={() => setSubSection("orders")}
            className="tile-button"
          >
            All Orders
          </button>
          <button
            onClick={() => setSubSection("material")}
            className="tile-button"
          >
            All Materials
          </button>
          <button
            onClick={() => setSubSection("products")}
            className="tile-button"
          >
            All Products
          </button>
          <button
            onClick={() => setSubSection("expences")}
            className="tile-button"
          >
            All Expences
          </button>
          <button onClick={() => setSubSection("sale")} className="tile-button">
            All Sales
          </button>
          <button onClick={() => setSubSection("cost")} className="tile-button">
            Costing For Job
          </button>
          <button
            onClick={() => setSubSection("costorder")}
            className="tile-button"
          >
            Costing For Order
          </button>
          <button
            onClick={() => setSubSection("orderCost")}
            className="tile-button"
          >
            Costing For All Orders
          </button>
          <button
            onClick={() => setSubSection("customer")}
            className="tile-button"
          >
            Customer
          </button>

          <button
            onClick={() => setSubSection("customerOutstanding")}
            className="tile-button"
          >
            Customer Outstanding Report
          </button>
          <button
            onClick={() => setSubSection("customerAll")}
            className="tile-button"
          >
            All Customers
          </button>
          <button
            onClick={() => setSubSection("customeroutPay")}
            className="tile-button"
          >
            Customer Outstanding Payment
          </button>
        </div>
      )}
      {/* {!subSection && (
        <div className="bottom-logo">
          <img src="/logo.png" alt="Logo" style={{ width: "200px" }} />
        </div>
      )} */}
    </div>
  );
};

export default Reports;
