import React, { useEffect, useState } from "react";
import { Table } from "antd";
import apiService from "../../../http";
import OutstandPayment from "./SupplierPayment";
import { Button } from "../../../components/ui";
import { toast } from "react-toastify";

const ProductModal = ({ handleClose, selectedSupplier }) => {
  const [products, setProducts] = useState([]);
  const [grn, setSelectedGrn] = useState([]);
  const [paymentModalState, setPaymentModalState] = useState(false);

  const columns = [
    {
      title: "#",
      width: "5%",
      render: (arg, record, index) => {
        const rowNumber = index + 1;
        return <p className="text-center">{rowNumber}</p>;
      },
    },
    {
      title: "DATE",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      width: "20%",
      render: (date) => new Date(date).toLocaleDateString("en-US"),
    },
    {
      title: "GRN ID",
      dataIndex: "grnId",
      key: "grnId",
      align: "center",
      width: "20%",
    },
    {
      title: "PAID AMOUNT",
      dataIndex: "payAmount",
      key: "payAmount",
      align: "center",
      width: "20%",
    },
    {
      title: "TOTAL AMOUNT",
      dataIndex: "totalAmount",
      key: "totalAmount",
      align: "center",
      width: "20%",
    },

    {
      title: "ACTION",
      align: "center",
      width: "15%",
      render: (arg) => (
        <div className="flex justify-center">
          {arg.totalAmount > arg.payAmount && (
            <Button
              text="PAY"
              className="px-2 py-1 mx-2 text-white bg-blue-500 rounded cursor-pointer hover:bg-blue-700"
              handleClick={() => {
                setSelectedGrn(arg);
                setPaymentModalState(true);
              }}
            />
          )}
          {arg.status === true && (
            <Button
              text="RETURN"
              className="px-2 py-1 mx-2 text-white bg-blue-500 rounded cursor-pointer hover:bg-blue-700"
              handleClick={() => {
                returndata(arg._id);
              }}
            />
          )}
        </div>
      ),
    },
  ];

  const getData = async (id) => {
    try {
      const response = await apiService.get(
        `supplier/grnMat/${selectedSupplier.id}`
      );

      setProducts(response);
    } catch (e) {
      console.log(e);
    }
  };
  const returndata = async (id) => {
    try {
      await apiService.get(`supplier/return/${id}`);
      toast.success("GRN successfully returned!");
      getData();
      handleClose();
    } catch (e) {
      toast.error(e.message);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      {paymentModalState && (
        <OutstandPayment
          selectedSupplier={selectedSupplier}
          handleClose={() => setPaymentModalState(false)}
          grn={grn}
        />
      )}
      <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75">
        <div className="shadow-xl transition-all w-[55%] mx-auto mt-[100px] rounded-lg bg-white">
          <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
            <h3 className="py-1 mx-auto">Supplier GRN</h3>
            <span
              className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
              onClick={handleClose}
            >
              X
            </span>
          </div>
          <div className="mx-4 mt-2">
            <div className="w-full">
              <Table
                pagination={{ defaultPageSize: 4 }}
                columns={columns}
                dataSource={products}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
