import React from "react";

const PrintReport = ({ orders }) => {
  // Calculate total profit, handling cases where values may be null or undefined
  const totalAmount = orders.reduce(
    (acc, order) =>
      acc +
      (Number(order.totalOrderAmount || 0) - Number(order.totalCost || 0)),
    0
  );

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <div style={{ flex: "1", textAlign: "left" }}>
          <img src="/logo.png" alt="Logo" style={{ width: "200px" }} />
        </div>
        <div style={{ flex: "2", textAlign: "right" }}>
          <p
            style={{
              fontFamily: "Sinhala, Arial",
              fontSize: "15px",
              margin: "20px 0 10px 0",
            }}
          >
            තහඩු නැවීම, කැපිම, රොල් කිරීම, රූෆින් ශීට් සහ උපාංග, ස්ටීල්
            ෆැබ්‍රිකේශන් වැඩ
            <br />
            විලකොළදෙනිය, මලංගම, රත්නපුර
            <br />
            045 2121750, 074 0327850
          </p>
        </div>
      </div>

      <h3 style={{ textAlign: "center" }}>Profit Report</h3>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Created At
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Order ID
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Customer Name
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Total Order Amount
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Total Cost
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Profit
            </th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order, index) => (
            <tr key={index}>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                {order.createdAt
                  ? new Date(order.createdAt).toLocaleDateString()
                  : "N/A"}
              </td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                {order.orderId}
              </td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                {order.customerName || "N/A"}
              </td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                {Number(order.totalOrderAmount || 0).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                {Number(order.totalCost || 0).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                {(
                  Number(order.totalOrderAmount || 0) -
                  Number(order.totalCost || 0)
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <p style={{ textAlign: "right", marginTop: "20px", fontWeight: "bold" }}>
        Total Profit:{" "}
        {totalAmount
          .toLocaleString("en-US", {
            style: "currency",
            currency: "LKR",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          .replace(/LKR/g, "")}{" "}
        {/* Removing LKR */}
      </p>
    </div>
  );
};

export default PrintReport;
