import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { toast } from "react-toastify";
import { Button, Select } from "../../../../components/ui";
import apiService from "../../../../http";
import ReactDOMServer from "react-dom/server";

const MaterialDetails = ({ materials }) => {
  const materialColumns = [
    { title: "Title", dataIndex: "title", key: "title", width: "20%" },
    {
      title: "Transaction Type",
      dataIndex: "transactionType",
      key: "transactionType",
      width: "20%",
    },
    { title: "Full Amount", dataIndex: "amount", key: "amount", width: "15%" },
    { title: "Offcut", dataIndex: "offCut", key: "offCut", width: "15%" },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
      render: (text) => text,
      width: "15%",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (_, record) =>
        `${record.transactionType === "return" ? "-" : ""}${
          record.transactionType === "return"
            ? record.amount * record.unitPrice
            : record.amount * record.unitPrice
        }`,
      width: "15%",
    },
  ];

  const materialData = materials.map((material) => ({
    key: material.materialId,
    ...material,
  }));

  return (
    <Table
      columns={materialColumns}
      dataSource={materialData}
      pagination={false}
      style={{ width: "100%" }}
    />
  );
};

const MachineDetails = ({ machines }) => {
  const machineColumns = [
    {
      title: "Title",
      dataIndex: "machineTitle",
      key: "machineTitle",
      width: "40%",
    },
    { title: "Hours", dataIndex: "hours", key: "hours", width: "20%" },
    { title: "Unit", dataIndex: "unit", key: "unit", width: "20%" },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text) => text,
      width: "20%",
    },
  ];

  const machineData = machines.map((machine) => ({
    key: machine.machineId,
    ...machine,
  }));

  return (
    <Table
      columns={machineColumns}
      dataSource={machineData}
      pagination={false}
      style={{ width: "100%" }}
    />
  );
};

const LabourDetails = ({ labours }) => {
  const labourColumns = [
    {
      title: "Labour ID",
      dataIndex: "labourId",
      key: "labourId",
      width: "25%",
    },
    { title: "Hours", dataIndex: "hours", key: "hours", width: "25%" },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (_, record) =>
        `${
          record.total === "option1"
            ? record.labouroverTime * record.hours
            : record.labourHourlyRate * record.hours
        }`,
      width: "50%",
    },
  ];

  const labourData = labours.map((labour) => ({
    key: labour.labourId,
    ...labour,
  }));

  return (
    <Table
      columns={labourColumns}
      dataSource={labourData}
      pagination={false}
      style={{ width: "100%" }}
    />
  );
};

const CostingReport = () => {
  const [jobs, setJobs] = useState([]);
  const [filteredJob, setFilteredJob] = useState(null);
  const [selectedJobId, setSelectedJobId] = useState(null);

  const refreshJobs = async () => {
    try {
      const jobs = await apiService.get("job");
      const parsedJobs = jobs.map((job) => ({
        ...job,
        createdAt: new Date(job.createdAt),
        labours: job.labours || [],
        materials: job.materials || [],
        machines: job.machines || [],
      }));
      setJobs(parsedJobs);
    } catch (e) {
      toast.error(e.message);
    }
  };

  useEffect(() => {
    refreshJobs();
  }, []);

  useEffect(() => {
    if (selectedJobId) {
      const job = jobs.find((job) => job.jobId === selectedJobId);
      setFilteredJob(job || null);
    } else {
      setFilteredJob(null);
    }
  }, [selectedJobId, jobs]);

  const uniqueJobIds = [...new Set(jobs.map((job) => job.jobId))].map(
    (jobId) => ({
      id: jobId,
      title: jobId,
    })
  );

  const jobColumns = [
    { title: "Job ID", dataIndex: "jobId", key: "jobId", width: "20%" },
    {
      title: "Product ID",
      dataIndex: ["product", "productId"],
      key: "productId",
      width: "20%",
    },
    {
      title: "Product Name",
      dataIndex: ["product", "title"],
      key: "productTitle",
      width: "30%",
    },
    {
      title: "Make Quantity",
      dataIndex: "makeQuantity",
      key: "makeQuantity",
      width: "15%",
    },
    {
      title: "Finished Quantity",
      dataIndex: "finishedQuantity",
      key: "finishedQuantity",
      width: "15%",
    },
  ];

  const calculateTotalCost = (labours, materials, machines) => {
    const labourCost = labours.reduce(
      (total, labour) =>
        total +
        (labour.total === "option1"
          ? labour.labouroverTime * labour.hours
          : labour.labourHourlyRate * labour.hours),
      0
    );
    const materialCost = materials.reduce((total, material) => {
      if (material.transactionType === "return") {
        return total - material.amount * material.unitPrice;
      } else {
        return total + material.amount * material.unitPrice;
      }
    }, 0);
    const machineCost = machines.reduce(
      (total, machine) => total + machine.total,
      0
    );
    return {
      labourCost,
      materialCost,
      machineCost,
      totalCost: labourCost + materialCost + machineCost,
    };
  };

  const handlePrint = (job, costs) => {
    const printContent = ReactDOMServer.renderToString(
      <div>
        <Table
          columns={jobColumns}
          dataSource={[job]}
          rowKey="id"
          pagination={false}
          style={{ width: "100%" }}
        />
        <h3>
          <u>Material Details</u>
        </h3>
        <MaterialDetails materials={job.materials} />
        <h3>
          <u>Machine Details</u>
        </h3>
        <MachineDetails machines={job.machines} />
        <h3>
          <u>Labour Details</u>
        </h3>
        <LabourDetails labours={job.labours} />
        <h3>
          <u>Total Costs</u>
        </h3>
        <table style={{ width: "50%" }}>
          <tr>
            <td>Labour Cost</td>
            <td>: {costs.labourCost}</td>
          </tr>
          <tr>
            <td>Material Cost</td>
            <td>: {costs.materialCost}</td>
          </tr>
          <tr>
            <td>Machine Cost</td>
            <td>: {costs.machineCost}</td>
          </tr>
          <tr>
            <td>Total Cost</td>
            <td>: {costs.totalCost}</td>
          </tr>
        </table>
      </div>
    );
    const printWindow = window.open("", "Print", "width=800,height=600");
    printWindow.document.write(
      `<html><head><title>Costing Report</title>
            <style>
                table {
                    width: 100%;
                    border-collapse: collapse;
                }
                th, td {
                    text-align: left;
                    padding: 8px;
                }
                th {
                    background-color: #f2f2f2;
                }
                img {
                    width: 150px; /* Adjust the width as needed */
                }
                .print-content {
                    text-align: center;
                    margin-bottom: 20px;
                }
                .print-content p {
                    font-family: Sinhala, Arial;
                    font-size: 12px;
                    margin: 20px 0 10px 0;
                }
                h1 {
                    text-align: center;
                }
                p{
                    fontFamily: Sinhala, Arial;
                    fontSize: 12px;
                    margin: 10px 0 5px 0;
                }
                .classdiv{
                    flex:2;
                    text-align: right;
                }
                .fullCls{
                    display:flex;
                    justify-content:space-between;
                    align-itmes:center;
                    margin-bottom:20px;
                }.imgcls{
                    flex:1;
                    text-align:left;
                    width:150px;
                }
            </style>
            </head><body>
            <div class="fullCls">
        <div class="imgcls">
          <img src="/logo.png" alt="Logo"/>
        </div>
        <div class="classdiv">
          <p>
            තහඩු නැවීම, කැපිම, රොල් කිරීම, රූෆින් ශීට් සහ උපාංග, ස්ටීල්
            ෆැබ්‍රිකේශන් වැඩ
            <br />
            විලකොළදෙනිය, මලංගම, රත්නපුර
            <br />
            045 2121750, 074 0327850
          </p>
        </div>
      </div>
            <h3>Costing Report</h3>
            ${printContent}
            </body></html>`
    );
    printWindow.document.write(
      `<script>window.onload = function() { window.print(); window.onafterprint = function() { window.close(); }; };</script>`
    );
    printWindow.document.close();
  };

  return (
    <section className="w-full">
      <div className="p-6 mx-3 my-1 border-2 rounded-md">
        <p className="text-lg font-bold">COSTING REPORT</p>
        <div className="flex flex-wrap mb-4 space-x-3">
          <div className="w-[200px] mr-3">
            <Select
              input={{
                label: "Filter By Job ID",
                data: selectedJobId,
                optList: uniqueJobIds,
                optTitle: "Job ID",
              }}
              handleChange={(input) => setSelectedJobId(input.data)}
              inputClassName="w-[200px] mr-3"
              labelClassName=""
            />
          </div>
        </div>
        {filteredJob && (
          <>
            <h3>
              <b>Job Details</b>
            </h3>
            <Table
              columns={jobColumns}
              dataSource={[filteredJob]}
              rowKey="id"
              pagination={false}
              style={{ width: "100%" }}
            />
            <h3>
              <b>Material Details</b>
            </h3>
            <MaterialDetails materials={filteredJob.materials} />
            <h3>
              <b>Machine Details</b>
            </h3>
            <MachineDetails machines={filteredJob.machines} />
            <h3>
              <b>Labour Details</b>
            </h3>
            <LabourDetails labours={filteredJob.labours} />
            <h3>
              <b>Total Costs</b>
            </h3>
            {(() => {
              const costs = calculateTotalCost(
                filteredJob.labours,
                filteredJob.materials,
                filteredJob.machines
              );
              return (
                <>
                  <div>Labour Cost: {costs.labourCost}</div>
                  <div>Material Cost: {costs.materialCost}</div>
                  <div>Machine Cost: {costs.machineCost}</div>
                  <div>Total Cost: {costs.totalCost}</div>
                  <Button
                    text="Print"
                    className="hover:bg-primary hover:text-white px-[14px] mt-4"
                    handleClick={() => handlePrint(filteredJob, costs)}
                  />
                </>
              );
            })()}
          </>
        )}
      </div>
    </section>
  );
};

export default CostingReport;
