import React, { useEffect, useState } from "react";
import { Table, Input } from "antd";
import { toast } from "react-toastify";
import ProductModal from "./ProductModal";
import ProductStockModal from "./ProductStockModal";
import PriceModal from "./PriceModal";
import DepartmentModal from "./Department";
import ReturnStock from "./returnStock";

import { Button, Confirm } from "../../../components/ui";
import apiService from "../../../http";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [productModalState, setProductModalState] = useState(false);
  const [productStockModal, setProductStockModalState] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [confirmModalState, setConfirmModalState] = useState(false);
  const [priceModalState, setPriceModalState] = useState(false);
  const [DepartmentModalState, setDepartmentModalState] = useState(false);
  const [stockReturnModalState, setStockReturnModalState] = useState(false); // State for stock edit modal

  const handleCloseModal = () => {
    setSelectedProduct(null);
    setProductModalState(false);
    setConfirmModalState(false);
    setPriceModalState(false);
    setProductStockModalState(false);
    setDepartmentModalState(false);
    refreshProducts();
    setStockReturnModalState(false);
  };
  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const filteredServices = products.filter((product) => {
    const columnsToSearch = ["productId", "title"];
    if (
      String(product.remarks).toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return true;
    }
    return columnsToSearch.some((column) =>
      String(product[column]).toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
  const refreshProducts = async () => {
    try {
      const products = await apiService.get("product");

      setProducts(products);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    refreshProducts();
  }, []);

  const actionBtns = (product) => {
    return (
      <div className="flex flex-wrap items-center">
        <Button
          text="PRICE"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedProduct(product);
            setPriceModalState(true);
          }}
        />

        <Button
          text="EDIT"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedProduct(product);
            setProductModalState(true);
          }}
        />
        <Button
          text="STOCK"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedProduct(product);
            setProductStockModalState(true);
          }}
        />
        <Button
          text="RETURN"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedProduct(product);
            setStockReturnModalState(true);
          }}
        />
      </div>
    );
  };

  const columns = [
    {
      title: "#",
      width: "5%",
      render: (arg, record, index) => {
        const rowNumber = index + 1;
        return <p className="text-center">{rowNumber}</p>;
      },
    },

    {
      title: "PRODUCT ID",
      dataIndex: "productId",
      key: "productId",
      align: "center",
      width: "5%",
      sorter: (a, b) => {
        const productIdA = a.productId || "";
        const productIdB = b.productId || "";
        return productIdA.localeCompare(productIdB);
      },
    },
    {
      title: "TITLE",
      dataIndex: "title",
      key: "title",
      align: "center",
      width: "10%",
    },

    {
      title: "STOCK QUANTITY",
      dataIndex: "stock",
      align: "center",
      width: "2%",
    },
    {
      title: "REMARKS",
      align: "center",
      width: "10%",
      render: (arg) => {
        return arg.remarks ? arg.remarks : "N/A";
      },
    },

    {
      title: "OPERATIONS",
      align: "center",
      width: "25%",
      render: (arg) => actionBtns(arg),
    },
  ];

  return (
    <section className="w-full mt-6">
      {productModalState && (
        <ProductModal
          selectedProduct={selectedProduct}
          handleClose={handleCloseModal}
        />
      )}

      {productStockModal && (
        <ProductStockModal
          selectedProduct={selectedProduct}
          handleClose={handleCloseModal}
        />
      )}
      {priceModalState && (
        <PriceModal
          selectedProduct={selectedProduct}
          handleClose={handleCloseModal}
        />
      )}
      {DepartmentModalState && (
        <DepartmentModal handleClose={handleCloseModal} />
      )}
      {stockReturnModalState && (
        <ReturnStock
          selectedProduct={selectedProduct}
          handleClose={handleCloseModal}
        />
      )}
      {confirmModalState && (
        <Confirm
          title="Finish job"
          confirmMsg="Do you want to finish the product job?"
          actionLabel="OK"
          cancelHandler={handleCloseModal}
          confirmHandler={async () => {
            await apiService.patch("product/status/" + selectedProduct.id);
            toast.success("Product status changed successfully!");
            handleCloseModal();
            setSelectedProduct(null);
          }}
        />
      )}

      <div className="flex gap-3 px-2 py-2 mb-3 bg-white rounded-md">
        <div className="flex items-center">
          <Button
            text={"NEW PRODUCT"}
            handleClick={() => setProductModalState(true)}
          />
          <Button
            text={"ADD NEW DEPARTMENT"}
            handleClick={() => setDepartmentModalState(true)}
          />
        </div>
        <div className="flex items-center justify-end" style={{ flex: 1 }}>
          <Input
            placeholder="Type To Search"
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
            style={{
              width: "70%", // Use 100% width to fill the available space
              maxWidth: "43%", // Limit the width if necessary
              border: "1px solid #878686",
              borderRadius: "4px",
              height: "40px",
            }}
          />
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={filteredServices}
        style={{ width: "100%" }}
      />
    </section>
  );
};

export default Products;
