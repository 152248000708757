import React, { useEffect, useState } from "react";
import { Table } from "antd";
import apiService from "../../../http";

const ProductModal = ({ handleClose, selectedSupplier }) => {
  const [products, setProducts] = useState([]);

  const columns = [
    {
      title: "#",
      width: "5%",
      render: (arg, record, index) => {
        const rowNumber = index + 1;
        return <p className="text-center">{rowNumber}</p>;
      },
    },

    {
      title: "DATE",
      dataIndex: "date",
      key: "date",
      align: "center",
      width: "30%",
      render: (date) => new Date(date).toLocaleDateString("en-US"),
    },
    {
      title: "GRN NUMBER",
      dataIndex: "invoiceNum",
      key: "invoiceNum",
      align: "center",
      width: "30%",
      render: (invoiceNum, record) => {
        return (
          <span
            className={"rounded px-2 py-1 cursor-pointer"}
            onClick={() =>
              alert(
                `Invoice Date: ${record.invoiceDate}, Invoice Amount: ${record.invoiceAmount}`
              )
            }
          >
            {invoiceNum}
          </span>
        );
      },
    },

    {
      title: "PAYMENT METHOD",
      dataIndex: "paymentMethod",
      key: "pMethod",
      align: "center",
      width: "30%",
      render: (pMethod, record) => {
        if (pMethod === "cheque") {
          return (
            <span
              className="px-2 py-1 text-white bg-blue-500 rounded cursor-pointer hover:bg-blue-700"
              çonClick={() =>
                alert(
                  `Cheque Number: ${record.chequeNum}, Bank: ${record.chequebank}, Cheque Recieved Date: ${record.chequeRecievedDate}, Cheque Date: ${record.chequeDate}`
                )
              }
            >
              Cheque
            </span>
          );
        } else if (pMethod === "cash") {
          const method = "Cash";
          return (
            <button className="px-2 py-1 text-white bg-blue-500 rounded cursor-pointer hover:bg-blue-700">
              {method}
            </button>
          );
        } else if (pMethod === "bank") {
          return (
            <span
              className={
                "rounded px-2 py-1 bg-blue-500 text-white cursor-pointer  hover:bg-blue-700"
              }
              onClick={() =>
                alert(
                  `Account Name: ${record.accountName}, Bank: ${record.bank}, Account Number: ${record.accountNumber}`
                )
              }
            >
              Bank
            </span>
          );
        }
      },
    },
    {
      title: "PAID AMOUNT",
      align: "center",
      width: "35%",
      dataIndex: "paidAmount",
    },
  ];

  const getData = async () => {
    try {
      const orderData = await apiService.get(`supplier/` + selectedSupplier.id);
      console.log(orderData);
      setProducts(orderData.payment);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75 ">
        <div className=" shadow-xl transition-all w-[70%] mx-auto mt-[100px] rounded-lg bg-white">
          <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
            <h3 className="py-1 mx-auto">SUPPLIER PAYMENT </h3>
            <span
              className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
              onClick={handleClose}
            >
              X
            </span>
          </div>
          <div className="mx-4 mt-2">
            <div className="w-full">
              <Table
                pagination={{
                  defaultPageSize: 4,
                }}
                columns={columns}
                dataSource={products}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
