import React, { useEffect, useState } from "react";
import { Input, Button } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";

const SupplierModal = ({ handleClose, selectedSupplier }) => {
  const inputDataStructure = {
    name: {
      key: "name",
      label: "Name",
      data: "",
      required: true,
      type: "text",
      error: null,
    },
    remarks: {
      key: "remarks",
      label: "Remarks",
      data: "",
      type: "text",
      error: null,
    },
    contactNumber: {
      key: "contactNumber",
      label: "Contact Number",
      data: "",
      min: 0,
      type: "number",
      error: null,
    },
    status: {
      key: "status",
      label: "Status",
      data: true, // Assuming the default status is true (active)
      type: "toggle",
      error: null,
    },
  };

  const [inputs, setInputs] = useState(inputDataStructure);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    setInputs(input_list);
  };

  const buildForm = async () => {
    console.log(selectedSupplier);
    if (selectedSupplier) {
      let input_list = { ...inputs };

      input_list.name.data = selectedSupplier.name;
      input_list.remarks.data = selectedSupplier.remarks;
      input_list.contactNumber.data = selectedSupplier.contactNumber;
      input_list.status.data = selectedSupplier.status;

      setInputs(input_list);
    }
  };

  useEffect(() => {
    buildForm();
  }, []);

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    const formData = {
      name: inputs.name.data,
      remarks: inputs.remarks.data,
      contactNumber: inputs.contactNumber.data,
      status: inputs.status.data,
    };

    try {
      setIsLoading(true);

      if (selectedSupplier) {
        formData.id = selectedSupplier.id;
        await apiService.patch("supplier", formData);
        toast.success("Service updated successfully!");
      } else {
        await apiService.post("supplier", formData);
        toast.success("Service added successfully!");
      }

      handleClose();
      setIsLoading(false);
    } catch (e) {
      toast.error(e);
      setIsLoading(false);
    }
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <style>
        {`
                    /* Hide number up-down buttons for number inputs */
                    input[type=number]::-webkit-inner-spin-button,
                    input[type=number]::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                    input[type=number] {
                        -moz-appearance: textfield; /* Firefox */
                    }
                `}
      </style>
      <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75 ">
        <div className=" shadow-xl transition-all w-[25%] mx-auto mt-[100px] rounded-lg bg-white">
          <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
            <h3 className="py-1 mx-auto">
              {!selectedSupplier && "ADD NEW "}
              {selectedSupplier && "EDIT "}
              SUPPLIER
            </h3>
            <span
              className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
              onClick={handleClose}
            >
              X
            </span>
          </div>

          <div className="px-4 mt-2">
            <Input
              input={{
                ...inputs.name,
              }}
              handleChange={handleChange}
            />

            <Input
              input={{
                ...inputs.contactNumber,
                placeholder: "Mobile No. (Without leading zero)",
              }}
              handleChange={handleChange}
            />

            <Input input={inputs.remarks} handleChange={handleChange} />
            <ToggleInput input={inputs.status} handleChange={handleChange} />
            {isLoading ? (
              "Wait..."
            ) : (
              <Button
                handleClick={handleSubmit}
                className="mt-6"
                text={selectedSupplier ? "SAVE" : "CREATE"}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ToggleInput = ({ input, handleChange }) => {
  return (
    <div className="flex items-center mt-4">
      <label className="mr-2">{input.label}:</label>
      <button
        className={`bg-${
          input.data ? "green" : "red"
        }-500 px-3 py-1 rounded-md text-black`}
        onClick={() => handleChange({ ...input, data: !input.data })}
      >
        {input.data ? "ACTIVE" : "INACTIVE"}
      </button>
    </div>
  );
};

export default SupplierModal;
