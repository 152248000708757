import React, { useEffect, useState } from "react";
import { Table, Input } from "antd";
import { toast } from "react-toastify";
import SupplierModal from "./SupplierModal";
import SupplierPay from "./SupplierPayment";
import GRN from "./grn";
import PAY from "./payView";
import ViewModel from "./ViewModal";
import { Confirm, Button } from "../../../components/ui";
import apiService from "../../../http";

const Supplier = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [Services, setServices] = useState([]);
  const [dataModalState, setDataModalState] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [confirmModalState, setConfirmModalState] = useState(false);
  const [viewModalState, setViewModalState] = useState(false);
  const [supplierPay, setSupplierPay] = useState(false);
  const [grn, setGRN] = useState(false);
  const [pay, setPay] = useState(false);

  const handleEdit = (supplier) => {
    setSelectedSupplier(supplier);
    setDataModalState(true);
  };

  const handleDelete = async () => {
    try {
      await apiService.delete("supplier/" + selectedSupplier.id);
      setConfirmModalState(false);
      refreshServices();
      setSelectedSupplier(null);
      toast.success("supplier deleted successfully");
    } catch (e) {
      toast.error(e);
    }
  };

  const handleCloseModal = () => {
    refreshServices();
    setDataModalState(false);
    setSupplierPay(false);
    setConfirmModalState(false);
    setViewModalState(false);
    setSelectedSupplier(null);
    setPay(false);
    setGRN(false);
  };
  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const filteredServices = Services.filter((supplier) => {
    const columnsToSearch = ["name", "serviceId"];
    return columnsToSearch.some((column) =>
      String(supplier[column]).toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
  const refreshServices = async () => {
    try {
      const services = await apiService.get("supplier");
      setServices(services);
    } catch (e) {
      toast.error(e);
    }
  };

  useEffect(() => {
    refreshServices();
  }, []);

  const actionBtns = (supplier) => {
    return (
      <div className="flex">
        {/* <Button
                    text="View"
                    className={
                        "ml-3 hover:bg-primary hover:text-white px-[14px]"
                    }
                    handleClick={() => handleEdit(service)}
                /> */}
        <Button
          text="DELETE"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedSupplier(supplier);
            setConfirmModalState(true);
          }}
        />
        <Button
          text="PAYMENT HISTORY"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedSupplier(supplier);
            setViewModalState(true);
          }}
        />
        <Button
          text="EDIT"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedSupplier(supplier);
            handleEdit(supplier);
          }}
        />
        <Button
          text="ADD GRN"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedSupplier(supplier);
            setGRN(true);
          }}
        />
        <Button
          text="GRN PAY"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedSupplier(supplier);
            setPay(true);
          }}
        />
      </div>
    );
  };

  const columns = [
    {
      title: "#",
      width: "5%",
      render: (arg, record, index) => {
        const rowNumber = index + 1;
        return <p className="text-center">{rowNumber}</p>;
      },
    },
    {
      title: "SUPPLIER ID",
      dataIndex: "serviceId",
      key: "serviceId",
      align: "center",
      width: "20%",
      sorter: (a, b) => a.serviceId.localeCompare(b.serviceId),
    },
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: "20%",
    },
    {
      title: "REMARKS",
      align: "center",
      width: "20%",
      render: (arg) => {
        return arg.remarks ? arg.remarks : "N/A";
      },
    },

    {
      title: "CONTACT NUMBER",
      align: "center",
      width: "20%",
      dataIndex: "contactNumber",
      key: "contactNumber",
      //   sorter: (a, b) => a.id - b.id,
    },
    {
      title: "OUTSTANDING",
      align: "center",
      width: "20%",
      dataIndex: "outstanding",
      key: "outstanding",
    },

    {
      title: "OPERATIONS",
      align: "center",
      width: "60%",
      render: (arg) => actionBtns(arg),
    },
  ];

  return (
    <section className="w-full mt-6">
      {dataModalState && (
        <SupplierModal
          selectedSupplier={selectedSupplier}
          handleClose={handleCloseModal}
        />
      )}

      {supplierPay && (
        <SupplierPay
          selectedSupplier={selectedSupplier}
          handleClose={handleCloseModal}
        />
      )}
      {confirmModalState && (
        <Confirm
          cancelHandler={handleCloseModal}
          confirmHandler={handleDelete}
        />
      )}
      {viewModalState && (
        <ViewModel
          selectedSupplier={selectedSupplier}
          handleClose={handleCloseModal}
        />
      )}
      {grn && (
        <GRN
          selectedSupplier={selectedSupplier}
          handleClose={handleCloseModal}
        />
      )}
      {pay && (
        <PAY
          selectedSupplier={selectedSupplier}
          handleClose={handleCloseModal}
        />
      )}
      <div className="flex gap-3 px-2 py-2 mb-3 bg-white rounded-md">
        <div className="flex items-center">
          <Button
            text={"ADD NEW SUPPLIER"}
            handleClick={() => setDataModalState(true)}
          />
          {/* <Button
            text={"SUPPLIER PAYMENT"}
            handleClick={() => setSupplierPay(true)}
          /> */}
        </div>
        <div className="flex items-center justify-end" style={{ flex: 1 }}>
          <Input
            placeholder="Type To Search"
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
            style={{
              width: "70%", // Use 100% width to fill the available space
              maxWidth: "43%", // Limit the width if necessary
              border: "1px solid #878686",
              borderRadius: "4px",
              height: "40px",
            }}
          />
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={filteredServices}
        style={{ width: "100%" }}
      />
    </section>
  );
};

export default Supplier;
