import React from "react";

const SingleItem = ({ item, number, handleRemove }) => {
  let amount = 0;

  if (item.types === "material") {
    amount =
      (item.price - (item.price * item.discount) / 100) * item.fullquantity +
      item.offCutUsage * item.offcutprice;
  } else {
    amount = (item.price - (item.price * item.discount) / 100) * item.quantity;
  }

  let quantity = item.quantity;
  let rate = item.price;

  return (
    <tr className="py-6 mt-6 border-b-2 h-9 border-slate-300">
      <td className="text-left"># {number}</td>
      <td className="text-left">{item.title}</td>
      <td className="text-right">{quantity}</td>
      <td className="text-right">
        {rate.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </td>
      <td className="text-right">{`${item.discount}%`}</td>
      <td className="text-right">
        {amount.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </td>
      <td className="flex flex-col items-center py-2">
        <span
          onClick={() => {
            handleRemove(item.posId);
          }}
          className="px-3 py-1 text-sm text-white bg-red-500 rounded-lg cursor-pointer hover:bg-red-600"
        >
          Remove
        </span>
      </td>
    </tr>
  );
};

export default SingleItem;
