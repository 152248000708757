import React, { useEffect, useState } from "react";
import { Input, Button, Select } from "../../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../../http";

const ProductModal = ({ handleClose, selectedProduct }) => {
    const inputDataStructure = {
        product: {
            key: "product",
            required: true,
            label: "Product",
            itemLabel: "a product",
            optList: [],
            data: "",
            type: "text",
            error: null,
        },

        remarks: {
            key: "remarks",
            label: "Remarks",
            data: "",
            type: "text",
            error: null,
        },

        quantity: {
            key: "quantity",
            label: "Quantity",
            required: true,
            min: 1,
            data: 1,
            type: "number",
            error: null,
        },
    };

    const [inputs, setInputs] = useState(inputDataStructure);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (input) => {
        let input_list = { ...inputs };
        input_list[input.key] = input;
        setInputs(input_list);
    };

    const buildForm = async () => {
        const products = await apiService.get("product");
        const filteredProducts = products.filter((product) => product.status === true);

        const optList = filteredProducts.map((product) => {
            return { id: product.id, title: product.title };
        });

        let input_list = { ...inputs };
        input_list.product.optList = optList;
        setInputs(input_list);
    };

    useEffect(() => {
        buildForm();
    }, []);

    const handleSubmit = async () => {
        const event = window.event;
        event.preventDefault();

        const formData = {
            product: inputs.product.data,
            remarks: inputs.remarks.data,
            quantity: inputs.quantity.data,
        };

        try {
            setIsLoading(true);
            await apiService.post("job", formData);
            toast.success("Job created successfully!");

            handleClose();
            setIsLoading(false);

        } catch (e) {
            toast.error(e);
            setIsLoading(false);

        }
    };

    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
            onClick={handleClose}
        >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75">
                <div
                    className="transform shadow-xl transition-all w-[25%] mx-auto mt-[100px] rounded-lg bg-white"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <h3 className="w-full py-1 mb-6 text-xl text-center text-white bg-primary">
                        CREATE NEW JOB
                    </h3>

                    <div className="px-4">
                        <Select
                            input={inputs.product}
                            handleChange={handleChange}
                        />
                        <Input
                            input={inputs.quantity}
                            handleChange={handleChange}
                        />
                        <Input
                            input={inputs.remarks}
                            handleChange={handleChange}
                        />
                         {isLoading ? (
                        "Wait..."
                    ) : (
                        <Button
                            handleClick={handleSubmit}
                            className="w-full mt-6"
                            text={"CREATE"}
                        />)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductModal;
