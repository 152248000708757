import React, { useEffect, useState } from "react";
import { Input, Button, Select } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";

const ProductModal = ({ handleClose, handleAdd }) => {
  const inputDataStructure = {
    material: {
      key: "material",
      label: "Material",
      required: true,
      optTitle: "a material",
      data: null,
      optList: [],
      type: "number",
      error: null,
    },
    quantity: {
      key: "quantity",
      label: "Quantity",
      required: true,
      min: 0,
      data: 0,
      type: "number",
      error: null,
    },
    offCutQuantity: {
      key: "offCutQuantity",
      label: "Off cuts",
      min: 0,
      data: 0,
      type: "number",
      error: null,
    },
    unitPrice: {
      key: "unitPrice",
      label: "Unit price for off cut (LKR)",
      readOnly: true,
      min: 0,
      data: 0,
      type: "number",
      error: null,
    },
    discount: {
      key: "discount",
      label: "Discount (%)",
      min: 0,
      data: 0,
      type: "number",
      error: null,
    },
  };

  const [inputs, setInputs] = useState(inputDataStructure);
  const [materials, setMaterials] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    if (input.key === "material") {
      const material = materials.find((item) => item.id === input.data);
      setSelectedMaterial(material || null);

      if (material) {
        if (material.unitPrice) {
          input_list.unitPrice.readOnly = true;
        } else {
          input_list.unitPrice.readOnly = false;
        }
        if (material.maxDiscount === 0) {
          input_list.discount.readOnly = true;
        } else {
          input_list.discount.readOnly = false;
        }
      } else {
        input_list.unitPrice.data = 0;
        input_list.unitPrice.readOnly = true;
        input_list.discount.data = 0;
        input_list.discount.readOnly = false;
      }
    }

    setInputs(input_list);
  };

  const buildForm = async () => {
    let input_list = { ...inputs };

    let materials = await apiService.get("material");
    const filteredMaterials = materials.filter(
      (material) => material.status === true
    );

    const optList = filteredMaterials.map((material) => ({
      id: material.id,
      title: `${material.title}  (${material.stock.full} items left)`,
    }));

    input_list.material.optList = optList;
    setInputs(input_list);
    setMaterials(filteredMaterials);
  };

  useEffect(() => {
    buildForm();
  }, []);

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    if (!inputs.material.data) {
      toast.error("Please select a material!");
      return;
    }

    if (inputs.offCutQuantity.data > 0 && inputs.unitPrice.data === 0) {
      toast.error("Please enter a unit price!");
      return;
    }

    const material = materials.find(
      (material) => material.id === inputs.material.data
    );

    if (!material) {
      toast.error("Selected material not found!");
      return;
    }

    if (inputs.quantity.data === 0) {
      toast.error("Quantity can't be zero!");
      return;
    }

    if (inputs.quantity.data > material.stock.full) {
      toast.error("Quantity can't be greater than the stock count!");
      return;
    }
    if (inputs.offCutQuantity.data > material.stock.offCut) {
      toast.error("Offcut Quantity can't be greater than the stock count!");
      return;
    }
    if (!material.unitPriceExt) {
      toast.error("Please Enter Selling Price First!");
      return;
    }

    if (material.maxDiscount && material.maxDiscount < inputs.discount.data) {
      toast.error(`Maximum allowed discount is: ${material.maxDiscount}%`);
      return;
    }

    const fullUsage = inputs.quantity.data;
    const offCutUsage = inputs.offCutQuantity.data;

    const { id, priceCalcMethod, materialId, title } = material;

    let usageAmount;

    if (priceCalcMethod === "nos" || priceCalcMethod === "weight-based") {
      usageAmount = fullUsage;
    } else if (priceCalcMethod === "length-based") {
      usageAmount = fullUsage;
    } else {
      usageAmount = fullUsage;
    }

    let returnData = {
      id,
      title,
      priceCalcMethod,
      itemId: materialId,
      fullquantity: usageAmount,
      quantity: `full: ${usageAmount}| off: ${offCutUsage}`,
      price: material.unitPriceExt,
      types: "material",
      offCutUsage: offCutUsage,
      offcutprice: inputs.unitPrice.data,
      discount: material.maxDiscount ? inputs.discount.data : 0,
    };

    handleAdd(returnData);
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75">
        <div className="shadow-xl transition-all w-[25%] mx-auto mt-[100px] rounded-lg bg-white">
          <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
            <h3 className="py-1 mx-auto">ADD MATERIALS</h3>
            <span
              className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
              onClick={handleClose}
            >
              X
            </span>
          </div>

          <div className="px-4 mt-2">
            <Select input={inputs.material} handleChange={handleChange} />

            <Input input={inputs.quantity} handleChange={handleChange} />

            {selectedMaterial &&
              ["m", "cm", "mm", "ft", "sqft", "sqm"].includes(
                selectedMaterial.priceCalcMethod
              ) && (
                <>
                  <Input
                    input={inputs.offCutQuantity}
                    handleChange={handleChange}
                  />
                  <Input input={inputs.unitPrice} handleChange={handleChange} />
                </>
              )}

            {!inputs.discount.readOnly && (
              <Input input={inputs.discount} handleChange={handleChange} />
            )}

            {isLoading ? (
              "Wait..."
            ) : (
              <Button
                handleClick={handleSubmit}
                className="mt-6"
                text={"ADD"}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
