import React, { useEffect, useState } from "react";
import { Input, Button } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";

const ServiceModal = ({ handleClose, selectedService }) => {
  const inputDataStructure = {
    title: {
      key: "title",
      label: "Title",
      data: "",
      required: true,
      type: "text",
      error: null,
    },
    remarks: {
      key: "remarks",
      label: "Remarks",
      data: "",
      type: "text",
      error: null,
    },
  };

  const [inputs, setInputs] = useState(inputDataStructure);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    setInputs(input_list);
  };

  const buildForm = async () => {
    if (selectedService) {
      let input_list = { ...inputs };

      input_list.title.data = selectedService.title;
      input_list.remarks.data = selectedService.remarks;
      setInputs(input_list);
    }
  };

  useEffect(() => {
    buildForm();
  }, []);

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    const formData = {
      title: inputs.title.data,
      remarks: inputs.remarks.data,
    };

    try {
      setIsLoading(true);
      if (selectedService) {
        formData.id = selectedService.id;
        await apiService.patch("service", formData);
        toast.success("Service updated successfully!");
      } else {
        await apiService.post("service", formData);
        toast.success("Service added successfully!");
      }

      handleClose();
      setIsLoading(false);
    } catch (e) {
      toast.error(e);
      setIsLoading(false);
    }
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75 ">
        <div className=" shadow-xl transition-all w-[25%] mx-auto mt-[100px] rounded-lg bg-white">
          <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
            <h3 className="py-1 mx-auto">
              {!selectedService && "ADD NEW "}
              {selectedService && "EDIT "}
              SERVICE
            </h3>
            <span
              className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
              onClick={handleClose}
            >
              X
            </span>
          </div>

          <div className="px-4 mt-2">
            <Input input={inputs.title} handleChange={handleChange} />

            <Input input={inputs.remarks} handleChange={handleChange} />

            {isLoading ? (
              "Wait..."
            ) : (
              <Button
                handleClick={handleSubmit}
                className="mt-6"
                text={selectedService ? "EDIT" : "CREATE"}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceModal;
