import React, { useEffect, useState } from "react";
import { Table,Input } from "antd";
import { toast } from "react-toastify";
import CustomerModal from "./CustomerModal";
import ViewModal from "./ViewModal";
import { Confirm, Button } from "../../../components/ui";
import apiService from "../../../http";

const Customers = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [Labours, setLabours] = useState([]);
    const [dataModalState, setDataModalState] = useState(false);
    const [selectedLabour, setSelectedLabour] = useState(null);
    const [confirmModalState, setConfirmModalState] = useState(false);
    const [viewModalState, setviewModalState] = useState(false);

    const handleEdit = (labour) => {
        setSelectedLabour(labour);
        setDataModalState(true);
    };

    const handleDelete = async () => {
        try {
            await apiService.delete("customer/" + selectedLabour.id);
            setConfirmModalState(false);
            setviewModalState(false);
            refreshLabours();
            setSelectedLabour(null);
            toast.success("customer deleted successfully");
        } catch (e) {
            toast.error(e);
        }
    };

    const handleCloseModal = () => {
        refreshLabours();
        setDataModalState(false);
        setConfirmModalState(false);
        setviewModalState(false);
        setSelectedLabour(null);
    };
    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    const filteredLabours = Labours.filter((labour) => {
        const columnsToSearch = ["title", "customerId"]; 
        return columnsToSearch.some((column) =>
            String(labour[column]).toLowerCase().includes(searchTerm.toLowerCase())
        );
    });
    const refreshLabours = async () => {
        try {
            const labours = await apiService.get("customer");
            setLabours(labours);
        } catch (e) {
            toast.error(e);
        }
    };

    useEffect(() => {
        refreshLabours();
    }, []);

    const actionBtns = (labour) => {
        return (
            <div className="flex">
                <Button
                    text="DELETE"
                    className={
                        "ml-1 hover:bg-primary hover:text-white px-[14px]"
                    }
                    handleClick={() => {
                        setSelectedLabour(labour);
                        setConfirmModalState(true);
                    }}
                />

                <Button
                    text="EDIT"
                    className={
                        "ml-1 hover:bg-primary hover:text-white px-[14px]"
                    }
                    handleClick={() => {
                        setSelectedLabour(labour);
                        handleEdit(labour);
                    }}
                />
                                <Button
                    text="INVOICES"
                    className={
                        "ml-1 hover:bg-primary hover:text-white px-[14px]"
                    }
                    handleClick={() => {
                        setSelectedLabour(labour);
                        setviewModalState(true);
                    }}
                />
            </div>
        );
    };



    const columns = [
        {
            title: "#",
            width: "2%",
            render: (arg, record, index) => {
                const rowNumber = index + 1;
                return <p className="text-center">{rowNumber}</p>;
            },
        },
        {
            title: "CUSTOMER ID",
            dataIndex: "customerId",
            key: "customerId",
            align: "center",
            width: "2%",
            sorter: (a, b) => a.customerId.localeCompare(b.customerId),
        },
        {
            title: "TITLE",
            dataIndex: "title",
            key: "title",
            align: "center",
            width: "2%",
        },
        // {
        //     title: "NIC NUMBER",
        //     align: "center",
        //     width: "2%",
        //     dataIndex: "nicNumber",
        // },
        {
            title: "MOBILE NUMBER",
            align: "center",
            width: "2%",
            dataIndex: "numberMob",
        },
        {
            title: "ADDRESS",
            align: "center",
            width: "2%",
            dataIndex: "address",
        },
        // {
        //     title: "STATUS",
        //     dataIndex: "status",
        //     key: "status",
        //     align: "center",
        //     width: "10%",
        //     render: (status) => (
        //         <span className={status ? "text-green-500" : "text-red-500"}>
        //             {status ? "ACTIVE" : "INACTIVE"}
        //         </span>
        //     ),
        // },
        {
            title: "OUTSTANDING LIMIT",
            align: "center",
            width: "2%",
            render: (arg) => {
                if (typeof arg.outstand === 'number') {
                    const formattedPrice = arg.outstand.toLocaleString("en-US", {
                        style: "currency",
                        currency: "LKR",
                    });
                    return formattedPrice;
                }
            },
            
        },
        {
            title: "OUTSTANDINGS",
            align: "center",
            width: "2%",
            render: (arg) => {
                if (typeof arg.outstandValue === 'number') {
                    const formattedPrice = arg.outstandValue.toLocaleString("en-US", {
                        style: "currency",
                        currency: "LKR",
                    });
                    return formattedPrice;
                }
            },
            
        },
        {
            title: "OPERATIONS",
            align: "center",
            width: "50%", // Adjust the width of the last column
            render: (arg) => actionBtns(arg),
        },
    ];
    

    return (
        <section className="w-full mt-6">
            {dataModalState && (
                <CustomerModal
                    selectedLabour={selectedLabour}
                    handleClose={handleCloseModal}
                />
            )}

            {confirmModalState && (
                <Confirm
                    cancelHandler={handleCloseModal}
                    confirmHandler={handleDelete}
                />
            )}
            {viewModalState && (
                <ViewModal
                selectedLabour={selectedLabour}
                handleClose={handleCloseModal}
                />
            )}

            <div className="grid grid-cols-3 gap-3 px-2 py-2 mb-3 bg-white rounded-md">
                <div>
                    <Button
                        text={"ADD NEW CUSTOMER"}
                        handleClick={() => setDataModalState(true)}
                    />
                </div>
                <div className="flex items-center justify-end col-span-2"  >
                
        <Input
            placeholder="   Type To Search " 
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
            style={{ width: "30%",border: "1px solid #878686", borderRadius: "4px",height: "40px" }}// Adjust width as needed

        />
       
    </div>
            </div>

            <Table
                columns={columns}
                dataSource={filteredLabours}
                style={{ width: "100%" }}
            />
        </section>
    );
};

export default Customers;
