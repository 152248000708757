import React, { useState } from "react";
import { Input, Button } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";

const ServiceModal = ({ handleClose, selectedProduct }) => {
  const inputDataStructure = {
    number: {
      key: "number",
      label: "How Many Products",
      data: "",
      required: true,
      type: "number",
      error: null,
      default: 0,
    },
  };

  const [inputs, setInputs] = useState(inputDataStructure);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (input) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [input.key]: input,
    }));
  };

  const handleSubmit = async () => {
    const formData = {
      stock: inputs.number.data,
    };

    try {
      setIsLoading(true);
      if (selectedProduct) {
        formData.id = selectedProduct.id;
        await apiService.patch("product/stock", formData);
        toast.success("Stock updated successfully!");
      } else {
        await apiService.post("product/stock", formData);
        toast.success("Product added successfully!");
      }

      handleClose();
    } catch (e) {
      toast.error(e.response?.data?.message || "An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75 ">
        <div className="shadow-xl transition-all w-[25%] mx-auto mt-[100px] rounded-lg bg-white">
          <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
            <h3 className="py-1 mx-auto">
              {!selectedProduct ? "ADD NEW " : "Return "}
              Products
            </h3>
            <span
              className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
              onClick={handleClose}
            >
              X
            </span>
          </div>

          <div className="px-4 mt-2">
            <Input input={inputs.number} handleChange={handleChange} />

            {isLoading ? (
              "Wait..."
            ) : (
              <Button
                handleClick={handleSubmit}
                className="mt-6"
                text={selectedProduct ? "EDIT" : "CREATE"}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceModal;
