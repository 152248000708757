import React from "react";

const PrintReport = ({ expenses }) => {
  const totalAmount = expenses.reduce(
    (acc, expense) => acc + Number(expense.amount),
    0
  );

  return (
    <div>
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ flex: "1", textAlign: "left" }}>
          <img src="/logo.png" alt="Logo" style={{ width: "200px" }} />
        </div>
        <div style={{ flex: "2", textAlign: "right" }}>
          <p
            style={{
              fontFamily: "Sinhala, Arial",
              fontSize: "15px",
              margin: "20px 0 10px 0",
            }}
          >
            තහඩු නැවීම, කැපිම, රොල් කිරීම, රූෆින් ශීට් සහ උපාංග, ස්ටීල්
            ෆැබ්‍රිකේශන් වැඩ
            <br />
            විලකොළදෙනිය, මලංගම, රත්නපුර
            <br />
            045 2121750, 074 0327850
          </p>
        </div>
      </div>
      <h1 style={{ textAlign: "center" }}>Expense Report</h1>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={{ textAlign: "left" }}>Date</th>
            <th style={{ textAlign: "left" }}>Expense ID</th>
            <th style={{ textAlign: "left" }}>Expense</th>
            <th style={{ textAlign: "left" }}>Invoice Number</th>
            <th style={{ textAlign: "left" }}>Invoice Buyer</th>
            <th style={{ textAlign: "left" }}>Amount</th>
          </tr>
        </thead>
        <tbody>
          {expenses.map((expense, index) => (
            <tr key={index}>
              <td>{new Date(expense.createdAt).toLocaleDateString()}</td>
              <td>{expense.expId}</td>
              <td>{expense.expencesType}</td>
              <td>{expense.invNumber}</td>
              <td>{expense.invBuyer}</td>
              <td>
                {Number(expense.amount).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <p style={{ textAlign: "right", fontWeight: "bold", marginTop: "20px" }}>
        Total:{" "}
        {totalAmount.toLocaleString("en-US", {
          style: "currency",
          currency: "LKR",
        })}
      </p>
    </div>
  );
};

export default PrintReport;
