import React from "react";

export const PrintReport = ({ materials }) => {
  const columns = [
    { title: "ID", key: "id" },
    { title: "Material ID", key: "materialId" },
    { title: "Material Name", key: "materialName" },
    { title: "Quantity", key: "quantity" },
    { title: "Unit Price", key: "unitPrice" },
    { title: "Total Price", key: "totalPrice" },
  ];
  const totalSum = materials.reduce((sum, material) => {
    const cost = material.cost || 0;
    const quantity = material.stock.full || 0;
    return sum + quantity * cost;
  }, 0);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <div style={{ flex: "1", textAlign: "left" }}>
          <img src="/logo.png" alt="Logo" style={{ width: "200px" }} />
        </div>
        <div style={{ flex: "2", textAlign: "right" }}>
          <p
            style={{
              fontFamily: "Sinhala, Arial",
              fontSize: "15px",
              margin: "20px 0 10px 0",
            }}
          >
            තහඩු නැවීම, කැපිම, රොල් කිරීම, රූෆින් ශීට් සහ උපාංග, ස්ටීල්
            ෆැබ්‍රිකේශන් වැඩ
            <br />
            විලකොළදෙනිය, මලංගම, රත්නපුර
            <br />
            045 2121750, 074 0327850
          </p>
        </div>
      </div>
      <h3>Material Stock Report</h3>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.key} style={{ textAlign: "left" }}>
                {column.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {materials.map((material, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{material.materialId}</td>
              <td>{material.title}</td>
              <td>{material.stock.full}</td>
              <td>
                {material.cost
                  ? material.cost
                      .toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD", // Use USD or any other currency for correct formatting
                        currencyDisplay: "code",
                      })
                      .replace(/USD/g, "") // Remove the "USD" symbol
                  : "0.00"}
              </td>
              <td>
                {(
                  (material.stock.full || 0) * (material.cost || 0)
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <p>
        Total Amount:{" "}
        {totalSum.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        })}
      </p>
    </div>
  );
};

export default PrintReport;
