import React from "react";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";

import HomeSection from "./Home";
import Materials from "./Materials";
import Labours from "./Labours";
import Services from "./Services";
import Products from "./Products";
import Jobs from "./Jobs";
import Orders from "./Orders";
import Pos from "./Pos";
import Sales from "./Sales";
import Costing from "./Costing";
import Supplier from "./Supplier";
import Customer from "./Customers";
import Expenses from "./Expenses";
import Reports from "./Reports";
import OrdersReport from "./Reports/orders";
import MaterialReport from "./Reports/material";
import ExpencesReport from "./Reports/expences";
import SalesReport from "./Reports/sales";
import Cost from "./Reports/cost";
import CostOrder from "./Reports/costorder";
import CustomerReport from "./Reports/customer";
import ProductsReport from "./Reports/products";
import OrderCost from "./Reports/orderProfit";
import CusromerOuts from "./Reports/customerOutstanding";
import CustomerAll from "./Reports/customerAll";
import CustomerOut from "./Reports/customerOut";
import SupplierGRN from "./Reports/supplier";

const Dashboard = ({ section }) => {
  return (
    <div>
      <NavBar dashboard={true} />
      <div className="flex mt-[85px]">
        <div className="w-[220px] p-10 bg-primary fixed h-screen -mt-[85px]">
          <SideBar section={section} />
        </div>
        <div className="ml-[240px] w-full p-4">
          {" "}
          {/* Adjusted margin for content */}
          {section === "home" && <HomeSection />}
          {section === "materials" && <Materials />}
          {section === "services" && <Services />}
          {section === "labours" && <Labours />}
          {section === "products" && <Products />}
          {section === "jobs" && <Jobs />}
          {section === "orders" && <Orders />}
          {section === "costing" && <Costing />}
          {section === "reports" && <Reports />}
          {section === "pos" && <Pos />}
          {section === "sales" && <Sales />}
          {section === "supplier" && <Supplier />}
          {section === "customer" && <Customer />}
          {section === "expenses" && <Expenses />}
          {/* Reports Sections */}
          {section === "ordersReport" && <OrdersReport />}
          {section === "materialReport" && <MaterialReport />}
          {section === "expencesReport" && <ExpencesReport />}
          {section === "salesReport" && <SalesReport />}
          {section === "cost" && <Cost />}
          {section === "costOrder" && <CostOrder />}
          {section === "customerReport" && <CustomerReport />}
          {section === "productsReport" && <ProductsReport />}
          {section === "orderCost" && <OrderCost />}
          {section === "cusromerOuts" && <CusromerOuts />}
          {section === "customerAll" && <CustomerAll />}
          {section === "customerOut" && <CustomerOut />}
          {section === "supplierGRN" && <SupplierGRN />}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
