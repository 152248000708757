import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Moment from "moment";
import apiService from "../../../http";

const Sales = () => {
    const [Sales, setSales] = useState([]);

    const refreshSales = async () => {
        try {
            const sales = await apiService.get("sale");
            setSales(sales);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        refreshSales();
    }, []);

    const columns = [
        {
            title: "#",
            width: "5%",
            render: (arg, record, index) => {
                const rowNumber = index + 1;
                return <p className="text-center">{rowNumber}</p>;
            },
        },

        {
            title: "Remarks",
            align: "center",
            width: "10%",
            render: (arg) => {
                return arg.remarks ? arg.remarks : "N/A";
            },
        },
        {
            title: "Customer details",
            align: "left",
            width: "20%",
            render: (arg) => {
                return (
                    <div className="flex flex-col items-start">
                        <p>{arg.customerName}</p>
                        <p>{arg.customerPhone}</p>
                        <p>{arg.customerAddress}</p>
                    </div>
                );
            },
            sorter: (a, b) => a.customerName.localeCompare(b.customerName),
        },
        {
            title: "Items",
            align: "left",
            width: "20%",
            render: (arg) => {
                return (
                    <div className="flex flex-col items-start">
                        {arg.items.map((item) => {
                            return <p>{item.title}</p>;
                        })}
                    </div>
                );
            },
        },

        {
            title: "Discount",
            align: "center",
            width: "5%",
            render: (arg) => {
                return arg.discount + "%";
            },
            sorter: (a, b) => a.discount - b.discount,
        },
        {
            title: "Invoice",
            align: "center",
            width: "20%",
            render: (arg) => {
                return (
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={arg.invoice}
                        className="bg-primary px-2 text-white py-1 rounded-lg"
                    >
                        view
                    </a>
                );
            },
        },
        {
            title: "Total",
            align: "center",
            width: "20%",
            render: (arg) => {
                const formattedPrice = arg.total.toLocaleString("en-US", {
                    style: "currency",
                    currency: "LKR",
                });
                return formattedPrice;
            },
            sorter: (a, b) => a.total - b.total,
        },
        {
            title: "Sub Total",
            align: "center",
            width: "20%",
            render: (arg) => {
                const subTotal = arg.total - (arg.total * arg.discount) / 100;
                const formattedPrice = subTotal.toLocaleString("en-US", {
                    style: "currency",
                    currency: "LKR",
                });
                return formattedPrice;
            },
            sorter: (a, b) => a.total - b.total,
        },
        {
            title: "Sale date",
            align: "center",
            width: "20%",
            render: (arg) => {
                const date = Moment(arg.createdAt);

                const formattedDatetime = date.format("YYYY-MM-DD HH:mm:ss");

                return formattedDatetime;
            },
            sorter: (a, b) => a.createdAt - b.createdAt,
        },
    ];

    return (
        <section className="w-full mt-6">
            <Table
                columns={columns}
                dataSource={Sales}
                style={{ width: "100%" }}
            />
        </section>
    );
};

export default Sales;
