import React, { useEffect, useState } from "react";
import { Input, Button, Select } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";

const ProductModal = ({ handleClose, handleAdd }) => {
  const inputDataStructure = {
    product: {
      key: "product",
      label: "Product",
      required: true,
      optTitle: "a product",
      data: null,
      optList: [],
      error: null,
    },
    quantity: {
      key: "quantity",
      label: "Quantity",
      required: true,
      min: 0,
      data: 0,
      type: "number",
      error: null,
    },
    unitPrice: {
      key: "unitPrice",
      label: "Unit price (LKR)",
      required: true,
      min: 0,
      data: 0,
      type: "number",
      error: null,
    },
    discount: {
      key: "discount",
      label: "Discount (%)",
      required: true,
      min: 0,
      data: 0,
      type: "number",
      error: null,
    },
  };

  const [inputs, setInputs] = useState(inputDataStructure);
  const [products, setProducts] = useState([]);

  const handleChange = async (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    if (input.key === "product" && input.data === null) {
      input_list.unitPrice.data = 0;
      input_list.discount.data = 0;
      input_list.discount.readOnly = true;
    }

    if (input.key === "product" && input.data !== null) {
      let product = products.find((item) => item.id === input.data);

      if (!product) {
        toast.error("Selected product not found!");
        return;
      }

      if (!product.unitPrice) {
        input_list.unitPrice.readOnly = false;
      } else {
        input_list.unitPrice.data = product.unitPrice;
      }

      if (product.maxDiscount === 0) {
        input_list.discount.readOnly = true;
        input_list.discount.data = 0;
      } else {
        input_list.discount.readOnly = false;
        input_list.discount.data = product.maxDiscount;
      }
    }

    setInputs(input_list);
  };

  const buildForm = async () => {
    let input_list = { ...inputs };

    const products = await apiService.get(`product`);
    const filteredProducts = products.filter(
      (product) => product.status === true
    );

    const optList = filteredProducts.map((product) => ({
      id: product.id,
      title: `${product.productId} - ${product.title} (${product.stock} items left)`,
    }));

    input_list.product.optList = optList;
    setProducts(filteredProducts);
    setInputs(input_list);
  };

  useEffect(() => {
    buildForm();
  }, []);

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    if (!inputs.product.data) {
      toast.error("Please select a product!");
      return;
    }

    let product = products.find((item) => item.id === inputs.product.data);

    if (!product) {
      toast.error("Selected product not found!");
      return;
    }

    if (inputs.quantity.data === 0) {
      toast.error("Quantity can't be zero!");
      return;
    }

    if (inputs.quantity.data > product.stock) {
      toast.error("Quantity can't be greater than the stock count!");
      return;
    }

    if (
      !product.unitPrice &&
      (!inputs.unitPrice.data || inputs.unitPrice.data === 0)
    ) {
      toast.error("Please enter a unit price!");
      return;
    }

    if (product.maxDiscount < inputs.discount.data) {
      toast.error(`Maximum allowed discount is: ${product.maxDiscount}%`);
      return;
    }

    handleAdd({
      id: product.id,
      title: product.title,
      productId: product.productId,
      quantity: Number(inputs.quantity.data),
      price: inputs.unitPrice.data,
      type: "material",
      discount: inputs.discount.data,
    });
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75">
        <div className="shadow-xl transition-all w-[25%] mx-auto mt-[100px] rounded-lg bg-white">
          <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
            <h3 className="py-1 mx-auto">ADD PRODUCTS</h3>
            <span
              className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
              onClick={handleClose}
            >
              X
            </span>
          </div>

          <div className="px-4 mt-2">
            <Select input={inputs.product} handleChange={handleChange} />
            <Input input={inputs.quantity} handleChange={handleChange} />
            <Input input={inputs.unitPrice} handleChange={handleChange} />
            {!inputs.discount.readOnly && (
              <Input input={inputs.discount} handleChange={handleChange} />
            )}
            <Button handleClick={handleSubmit} className="mt-6" text={"ADD"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
