import React, { useEffect, useState } from "react";
import { Input, Button, Select } from "../../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../../http";
import { Table } from "antd";
import Moment from "moment";

const ProductModal = ({ handleClose, selectedJob }) => {
    const [isLoading, setIsLoading] = useState(false);

    const inputDataStructure = {
        remarks: {
            key: "remarks",
            label: "Remarks",
            data: "",
            type: "text",
            error: null,
        },

        hours: {
            key: "hours",
            label: "Unit",
            required: true,
            min: 1,
            data: 0,
            type: "number",
            error: null,
        },
        perUnit: {
            key: "perUnit",
            label: "Per Unit",
            required: true,
            min: 1,
            data: 0,
            type: "number",
            error: null,
        },
        machine: {
            key: "machine",
            label: "Machine",
            required: true,
            optTitle: "a machine",
            data: null,
            optList: [],
            error: null,
        },

    };

    const [inputs, setInputs] = useState(inputDataStructure);
    const [labourList, setLabourList] = useState([]);

    const handleChange = (input) => {
        let input_list = { ...inputs };
        input_list[input.key] = input;
        setInputs(input_list);
    };

    const buildForm = async () => {
        let input_list = { ...inputs };

        let machines = await apiService.get("material/getmachine");

        const optList = machines.map((machine) => {
            return {
                id: machine.id,
                title: machine.machine,
            };
        });

        input_list.machine.optList = optList;

        setInputs(input_list);
    };

    const getData = async () => {
        const labours = await apiService.get(`job/machines/${selectedJob.id}`);

        setLabourList(labours);
    };

    useEffect(() => {
        buildForm();
        getData();
    }, []);
    const handleDelete = async (jobId, id) => {
        try {
            const formData = {
                jobId: jobId,
                machineId: id,
            };
            console.log(formData);
            setIsLoading(true);
            await apiService.patch(`job/deleteMachine`,formData);
            toast.success("Machine deleted successfully!");
            getData();
            setIsLoading(false);
        } catch (e) {
            toast.error(e.message);
            setIsLoading(false);
        }
    };
    const handleSubmit = async () => {
        const event = window.event;
        event.preventDefault();
    
        const formData = {
            job: selectedJob.id,
            machine: inputs.machine.data,
            remarks: inputs.remarks.data,
            hours: inputs.hours.data,
        };

        try {
            setIsLoading(true);

            await apiService.patch("job/machines", formData);
            toast.success("Product Machines updated successfully!");
            getData();
            setIsLoading(false);

            inputs.hours.data=0;
            inputs.perUnit.data=0;
            inputs.remarks.data="";
        } catch (e) {
            toast.error(e);
            setIsLoading(false);

        }
    };

    const columns = [
        {
            title: "#",
            width: "5%",
            render: (arg, record, index) => {
                const rowNumber = index + 1;
                return <p className="text-center">{rowNumber}</p>;
            },
        },

        {
            title: "DATE",
            align: "center",
            width: "20%",
            render: (arg) => {
                const date = Moment(arg.date);

                const formattedDatetime = date.format("YYYY-MM-DD HH:mm");

                return formattedDatetime;
            },
        },
        {
            title: "MACHINE ID",
            dataIndex: "machineId",
            key: "machineId",
            align: "center",
            width: "10%",
        },
        {
            title: "UNITS",
            key: "hours",
            align: "center",
            width: "5%",
            render: (arg) => {
                const total = arg.unit;
                const hours = arg.hours;
const unit=hours+total;
                return unit;
            },
        },
        // {
        //     title: "OT RATE",
        //     align: "center",
        //     width: "20%",
        //     render: (arg) => {
        //         const formattedPrice = arg.labouroverTime
        //             ? arg.labouroverTime.toLocaleString("en-US", {
        //                   style: "currency",
        //                   currency: "LKR",
        //               })
        //             : "0";
        //         return formattedPrice;
        //     },
        // },        
        // {
        //     title: "RATE",
        //     align: "center",
        //     width: "20%",
        //     render: (arg) => {
        //         const formattedPrice = arg.labourHourlyRate.toLocaleString(
        //             "en-US",
        //             {
        //                 style: "currency",
        //                 currency: "LKR",
        //             }
        //         );
        //         return formattedPrice;
        //     },
        // },
        {
                title: "TITLE",
                align: "center",
                width: "20%",
                dataIndex: "machineTitle",

            },
        {
            title: "TOTAL",
            align: "center",
            width: "20%",
            render: (arg) => {
                const total = arg.total;

                const formattedPrice = total
                            ? total.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "LKR",
                              })
                            : "0";
                        return formattedPrice;            },
        }
        ,{
            title: "ACTIONS",
            align: "center",
            width: "5%",
            render: (arg) => {
                return (
                    <Button
                        handleClick={() => handleDelete(selectedJob.id, arg.machineId)}
                        text="Delete"
                    />
                );
            },
        },
        
        
    ];

    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
            onClick={handleClose}
        >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75">
                <div
                    className="transform shadow-xl transition-all w-[70%] mx-auto mt-[100px] rounded-lg bg-white"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                                        <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">

                    <h3 className="py-1 mx-auto text-xl text-center text-white bg-primary">
                        MACHINE CENTER
                    </h3>
                    <span
                            className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
                            onClick={handleClose}
                        >
                            X
                        </span>
                        </div>
                    <div className="flex gap-4 px-4">
                        <div className="w-[80%]">
                            <div className="w-full">
                                <h3 className="w-full text-lg text-center bg-slate-200 text-slate-500">
                                    MACHINE COSTING
                                </h3>
                                <Table
                                    columns={columns}
                                    dataSource={labourList}
                                    scroll={{ y: 400 }}

                                />
                            </div>
                        </div>
                        <div className="w-[25%] mt-2">
                            <Select
                                input={inputs.machine}
                                handleChange={handleChange}
                            />


                            <Input
                                input={inputs.hours}
                                handleChange={handleChange}
                            />

                            <Input
                                input={inputs.remarks}
                                handleChange={handleChange}
                            />
 {isLoading ? (
                        "Wait..."
                    ) : (
                            <Button
                                handleClick={handleSubmit}
                                className="w-full mt-6"
                                text={"ADD"}
                            />)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductModal;
