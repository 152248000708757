import React, { useEffect, useState } from "react";
import { Input, Select, Button } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";

const AddExpModal = ({ handleClose, selectedSupplier }) => {
    const inputDataStructure = {
        amount: {
            key: "amount",
            label: "Amount",
            data: "",
            required: true,
            type: "text",
            error: null,
        },
        expencesType: {
            key: "expencesType",
            label: "Expences",
            data: null,
            optTitle: "a type",
            optList: [],
            type: "text",
            error: null,

        },
        invNumber: {
            key: "invNumber",
            label: "Invoice Number",
            data: "",
            type: "text",
            error: null,
        },
        invBuyer: {
            key: "invBuyer",
            label: "Invoice Buy",
            data: "",
            type: "text",
            error: null,
        },
        ordId: {
            key: "ordId",
            label: "Order ID or Job Id",
            data: "",
            type: "text",
            error: null,
        },
        jobId: {
            key: "jobId",
            label: "Job ID ",
            data: "",
            type: "text",
            error: null,
        },

        status: {
            key: "status",
            label: "Status",
            data: true, // Assuming the default status is true (active)
            type: "toggle",
            error: null,
        },

    };

    const [inputs, setInputs] = useState(inputDataStructure);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (input) => {
        let input_list = { ...inputs };
        input_list[input.key] = input;

        setInputs(input_list);
    };
    const buildForm = async () => {
        try {
            let input_list = { ...inputs };

            const type = await apiService.get("exType");
            const optList = type.map((category) => {
                return {
                    id: category.expences,
                    title: category.expences,
                };
            });
            input_list.expencesType.optList = optList;
            if (selectedSupplier) {
                input_list.amount.data = selectedSupplier.amount;
                input_list.expencesType.data = selectedSupplier.expencesType;
                input_list.jobId.data = selectedSupplier.jobId;
                input_list.ordId.data = selectedSupplier.ordId;
                input_list.invNumber.data = selectedSupplier.invNumber;
                input_list.invBuyer.data = selectedSupplier.invBuyer;
                input_list.status.data = selectedSupplier.status;
    
            }
            setInputs(input_list);

        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };
    

    useEffect(() => {
        buildForm();
    }, []);

    const handleSubmit = async () => {
        const event = window.event;
        event.preventDefault();

        const formData = {
            amount: inputs.amount.data,
            invNumber: inputs.invNumber.data,
            invBuyer: inputs.invBuyer.data,
            expencesType: inputs.expencesType.data,
            //jobId: inputs.jobId.data,
            ordId: inputs.ordId.data,
            status: inputs.status.data,

        };

        try {
            setIsLoading(true);

            if (selectedSupplier) {
                formData.id = selectedSupplier.id;
                await apiService.patch("expence", formData);
                toast.success("Expences updated successfully!");
            } else {
                await apiService.post("expence", formData);
                toast.success("Expences added successfully!");
            }

            handleClose();
            setIsLoading(false);

        } catch (e) {
            toast.error(e);
            setIsLoading(false);

        }
    };

    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75 ">
                <div className=" shadow-xl transition-all w-[25%] mx-auto mt-[100px] rounded-lg bg-white">
                    <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
                        <h3 className="py-1 mx-auto">
                            {!selectedSupplier && "ADD NEW "}
                            {selectedSupplier && "EDIT "}
                            EXPENCES
                        </h3>
                        <span
                            className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
                            onClick={handleClose}
                        >
                            X
                        </span>
                    </div>

                    <div className="px-4 mt-2">
                        <Input
                            input={inputs.amount}
                            handleChange={handleChange}
                        />

                        <Select
                            input={inputs.expencesType}
                            handleChange={handleChange}
                        />
                        {["indirect"].includes(
                            inputs.expencesType.data
                        ) && (
                            <Input
                                input={inputs.ordId}
                                handleChange={handleChange}
                            />
                        )}

                        {/* {["indirect"].includes(inputs.expencesType.data) && (
                            <Input
                                input={inputs.jobId}
                                handleChange={handleChange}
                            />
                            
                        )} */}

                        <Input
                            input={inputs.invNumber}
                            handleChange={handleChange}
                        />
                        <Input
                            input={inputs.invBuyer}
                            handleChange={handleChange}
                        />
                                                <ToggleInput input={inputs.status} handleChange={handleChange} />
                                                {isLoading ? (
                        "Wait..."
                    ) : (
                        <Button
                            handleClick={handleSubmit}
                            className="mt-6"
                            text={selectedSupplier ? "EDIT" : "CREATE"}
                        />)}
                    </div>
                </div>
            </div>
        </div>
    );
};
const ToggleInput = ({ input, handleChange }) => {
    return (
        <div className="flex items-center mt-4">
            <label className="mr-2">{input.label}:</label>
            <button
    className={`bg-${input.data ? 'green' : 'red'}-500 px-3 py-1 rounded-md text-black`}
    onClick={() => handleChange({ ...input, data: !input.data })}
>
                {input.data ? 'ACTIVE' : 'INACTIVE'}
            </button>
        </div>
    );
};

export default AddExpModal;
