import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { toast } from "react-toastify";
import { Button, Select } from "../../../../components/ui";
import apiService from "../../../../http";
import PrintReport from "./PrintReport"; // Adjust the import path as needed
import ReactDOMServer from "react-dom/server";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [searchCustomerName, setSearchCustomerName] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const refreshOrders = async () => {
    try {
      const orders = await apiService.get("order");
      const parsedOrders = orders.map((order) => ({
        ...order,
        createdAt: new Date(order.createdAt), // Store as Date object for easier comparison
      }));
      setOrders(parsedOrders);
      setFilteredOrders(parsedOrders);
    } catch (e) {
      toast.error(e.message);
    }
  };

  useEffect(() => {
    refreshOrders();
  }, []);

  useEffect(() => {
    filterOrders();
  }, [selectedStatus, searchCustomerName, fromDate, toDate]);

  const filterOrders = () => {
    let filtered = orders;

    if (selectedStatus) {
      filtered = filtered.filter((order) => order.status === selectedStatus);
    }

    if (searchCustomerName) {
      filtered = filtered.filter((order) =>
        order.customerName
          .toLowerCase()
          .includes(searchCustomerName.toLowerCase())
      );
    }

    if (fromDate) {
      const from = new Date(fromDate);
      from.setHours(0, 0, 0, 0);
      filtered = filtered.filter((order) => {
        const orderDate = new Date(order.createdAt);
        orderDate.setHours(0, 0, 0, 0);
        return orderDate >= from;
      });
    }

    if (toDate) {
      const to = new Date(toDate);
      to.setHours(23, 59, 59, 999);
      filtered = filtered.filter((order) => {
        const orderDate = new Date(order.createdAt);
        orderDate.setHours(0, 0, 0, 0);
        return orderDate <= to;
      });
    }
    setFilteredOrders(filtered);
  };

  const uniqueStatuses = [...new Set(orders.map((order) => order.status))].map(
    (status) => ({
      id: status,
      title: status,
    })
  );

  const columns = [
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "orderId",
      render: (text) => <p className="text-left">{text}</p>,
      align: "left",
      width: "15%", // Adjust percentage as needed
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      render: (text) => <p className="text-left">{text}</p>,
      align: "left",
      width: "20%", // Adjust percentage as needed
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text) => {
        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD", // Still needs to be set for correct formatting
          currencyDisplay: "code", // This ensures the currency code is used, which might be omitted in some cases
        });
        const formatted = formatter.format(text);
        return formatted.replace(/USD/g, ""); // Remove "USD" from the formatted string
      },
      align: "right",
      width: "15%", // Adjust percentage as needed
    },
    {
      title: "Advance Payment",
      dataIndex: "advancePayment",
      key: "advancePayment",
      render: (text) => {
        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD", // Still needs to be set for correct formatting
          currencyDisplay: "code", // This ensures the currency code is used, which might be omitted in some cases
        });
        const formatted = formatter.format(text);
        return formatted.replace(/USD/g, ""); // Remove "USD" from the formatted string
      },
      align: "right",
      width: "15%", // Adjust percentage as needed
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => (
        <p className="text-center">{new Date(date).toLocaleDateString()}</p>
      ),
      align: "center",
      width: "15%", // Adjust percentage as needed
    },
    {
      title: "Products",
      dataIndex: "products",
      key: "products",
      render: (products) => (
        <ol>
          {products.map((product) => (
            <li key={product.productId}>{product.title}</li>
          ))}
        </ol>
      ),
      align: "left",
      width: "10%", // Adjust percentage as needed
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <p className="text-center">{text}</p>,
      align: "center",
      width: "10%", // Adjust percentage as needed
    },
  ];

  const handlePrint = () => {
    const printContent = ReactDOMServer.renderToString(
      <PrintReport orders={filteredOrders} />
    );
    const printWindow = window.open("", "Print", "width=800,height=600");
    printWindow.document.write(
      `<html><head><title>Order Report</title></head><body>${printContent}</body></html>`
    );
    printWindow.document.write(
      `<script>window.onload = function() { window.print(); window.onafterprint = function() { window.close(); }; };</script>`
    );
    printWindow.document.close();
    printWindow.document.addEventListener("afterprint", function () {
      printWindow.document.close();
    });
  };

  const totalAmount = filteredOrders.reduce(
    (acc, order) => acc + Number(order.total),
    0
  );

  return (
    <section className="w-full">
      <div className="p-6 mx-3 my-1 border-2 rounded-md">
        <p className="text-lg font-bold">ORDERS REPORTS</p>
        <div className="flex mb-4 space-x-3">
          <Select
            input={{
              label: "Filter By Status",
              data: selectedStatus,
              optList: uniqueStatuses,
              optTitle: "Status",
            }}
            handleChange={(input) => setSelectedStatus(input.data)}
            inputClassName="w-[200px] mr-3"
            labelClassName=""
          />

          <div className="w-[200px]">
            <label className="block mb-2 text-sm font-medium text-gray-700">
              Search by Name
            </label>
            <input
              type="text"
              value={searchCustomerName}
              placeholder="Enter Customer Name"
              onChange={(e) => setSearchCustomerName(e.target.value)}
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </div>

          <div className="w-[200px]">
            <label className="block mb-2 text-sm font-medium text-gray-700">
              From Date
            </label>
            <input
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </div>

          <div className="w-[200px]">
            <label className="block mb-2 text-sm font-medium text-gray-700">
              To Date
            </label>
            <input
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </div>
        </div>
        <Button
          text="CLEAR FILTERS"
          className="ml-3 hover:bg-primary hover:text-white px-[14px]"
          handleClick={() => {
            setSelectedStatus(null);
            setSearchCustomerName("");
            setFromDate("");
            setToDate("");
            setFilteredOrders(orders);
          }}
        />
        {isLoading ? (
          "Loading..."
        ) : (
          <Button
            text="PRINT"
            className="ml-3 hover:bg-primary hover:text-white px-[14px]"
            handleClick={handlePrint}
          />
        )}
      </div>
      <div id="printableArea" className="p-6 mx-3 my-1 border-2 rounded-md">
        <Table
          columns={columns}
          dataSource={filteredOrders}
          rowKey="id"
          style={{ width: "100%" }}
        />
        <p className="mt-4 font-bold text-right">
          Total Amount:{" "}
          {totalAmount.toLocaleString("en-US", {
            style: "currency",
            currency: "LKR",
          })}
        </p>
      </div>
    </section>
  );
};

export default Orders;
