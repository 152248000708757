import React, { useEffect, useState } from "react";
import { Table, Input } from "antd";
import { toast } from "react-toastify";

import MaterialModal from "./MaterialModal";
import PriceModal from "./PriceModal";
import CategoryModal from "./CategoryModal";
import MachineModal from "./MachineModal";
import ViewGrn from "./ViewGrn";

import { Confirm, Button } from "../../../components/ui";
import apiService from "../../../http";
import ImportMaterialModal from "./ImportMaterialModal";

const Materials = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const [Materials, setMaterials] = useState([]);
  const [dataModalState, setDataModalState] = useState(false);
  const [importModalState, setImportModalState] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [confirmModalState, setConfirmModalState] = useState(false);
  const [priceModalState, setPriceModalState] = useState(false);
  const [CategoryModalState, setCategoryModalState] = useState(false);
  const [MachineModalState, setMachineModalState] = useState(false);
  const [ViewGrnState, setViewGrnState] = useState(false);

  const handleEdit = (material) => {
    setSelectedMaterial(material);
    setDataModalState(true);
  };

  const handleDelete = async () => {
    try {
      await apiService.delete("material/" + selectedMaterial.id);
      setConfirmModalState(false);
      refreshMaterials();
      setSelectedMaterial(null);
      toast.success("Material deleted successfully");
    } catch (e) {
      toast.error(e);
    }
  };

  const handleCloseModal = () => {
    refreshMaterials();
    setDataModalState(false);
    setImportModalState(false);
    setConfirmModalState(false);
    setPriceModalState(false);
    setCategoryModalState(false);
    setSelectedMaterial(null);
    setMachineModalState(false);
    setViewGrnState(false);
  };
  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const filteredServices = Materials.filter((Material) => {
    const columnsToSearch = ["materialId", "title"];
    return columnsToSearch.some((column) =>
      String(Material[column]).toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
  const refreshMaterials = async () => {
    try {
      const materials = await apiService.get("material");
      setMaterials(materials);
    } catch (e) {
      toast.error(e);
    }
  };

  useEffect(() => {
    refreshMaterials();
  }, []);

  const actionBtns = (material) => {
    return (
      <div className="flex">
        <Button
          text="PRICE"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedMaterial(material);
            setPriceModalState(true);
          }}
        />
        <Button
          text="DELETE"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedMaterial(material);
            setConfirmModalState(true);
          }}
        />

        <Button
          text="EDIT"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedMaterial(material);
            handleEdit(material);
          }}
        />
      </div>
    );
  };

  const stockCount = (material) => {
    if (material.priceCalcMethod === "weight-based") {
      return material.stock.full + " kg";
    } else if (material.priceCalcMethod === "nos") {
      return material.stock.full;
    } else if (material.priceCalcMethod === "length-based") {
      return (
        <div className="flex gap-3">
          <span>{`Full : ${material.stock.full}`}</span>
          <span>|</span>
          <span>{`Off Cut : ${material.stock.offCut} ft`}</span>
        </div>
      );
    } else if (material.priceCalcMethod === "area-based") {
      return (
        <div className="flex gap-3">
          <span>{`Full : ${material.stock.full}`}</span>
          <span>|</span>
          <span>{`Off Cut : ${material.stock.offCut} sq. ft`}</span>
        </div>
      );
    } else {
      return material.stock.full;
    }
  };
  const offstockCount = (material) => {
    return material.stock.offCut;
  };

  const columns = [
    {
      title: "#",
      width: "5%",
      render: (arg, record, index) => {
        const rowNumber = index + 1;
        return <p className="text-center">{rowNumber}</p>;
      },
    },

    {
      title: "ID",
      dataIndex: "materialId",
      key: "materialId",
      align: "center",
      width: "8%",
      sorter: (a, b) => a.materialId.localeCompare(b.materialId),
    },
    {
      title: "TITLE",
      dataIndex: "title",
      data: null,
      key: "title",
      align: "center",
      width: "10%",
    },
    {
      title: "SUPPLIER",
      dataIndex: "supplier",
      data: null,
      key: "supplier",
      align: "center",
      width: "6%",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: "12%",
      render: (status) => (
        <span
          className={`rounded px-2 py-1 ${
            status ? "bg-green-500 text-white" : "bg-red-500 text-white"
          }`}
        >
          {status ? "ACTIVE" : "INACTIVE"}
        </span>
      ),
    },
    {
      title: "UNIT",
      dataIndex: "priceCalcMethod",
      data: null,
      key: "priceCalcMethod",
      align: "center",
      width: "5%",
    },
    {
      title: "Off Cut",
      align: "center",
      width: "5%",
      render: (arg) => offstockCount(arg),
    },
    {
      title: "INSTOCK",
      align: "center",
      width: "13%",
      render: (arg) => stockCount(arg),
    },
    {
      title: "SELLING PRICE",
      align: "center",
      width: "6%",
      render: (arg) => {
        if (!arg.unitPriceExt) {
          return "N/A";
        }
        const formattedPrice = arg.unitPriceExt.toLocaleString("en-US", {
          style: "currency",
          currency: "LKR",
        });
        return formattedPrice;
      },
    },
    {
      title: "COST",
      align: "center",
      width: "10%",
      render: (arg) => {
        if (!arg.cost) {
          return "N/A";
        }
        const formattedPrice = arg.cost.toLocaleString("en-US", {
          style: "currency",
          currency: "LKR",
        });
        return formattedPrice;
      },
    },

    {
      title: "OPERATIONS",
      align: "center",
      width: "40%",
      render: (arg) => actionBtns(arg),
    },
  ];

  return (
    <section className="w-full mt-6">
      {dataModalState && (
        <MaterialModal
          selectedMaterial={selectedMaterial}
          handleClose={handleCloseModal}
        />
      )}
      {importModalState && (
        <ImportMaterialModal handleClose={handleCloseModal} />
      )}
      {CategoryModalState && <CategoryModal handleClose={handleCloseModal} />}
      {MachineModalState && <MachineModal handleClose={handleCloseModal} />}
      {ViewGrnState && <ViewGrn handleClose={handleCloseModal} />}
      {priceModalState && (
        <PriceModal
          selectedMaterial={selectedMaterial}
          handleClose={handleCloseModal}
        />
      )}
      {confirmModalState && (
        <Confirm
          cancelHandler={handleCloseModal}
          confirmHandler={handleDelete}
        />
      )}

      <div className="flex gap-3 py-2 mb-3 bg-white rounded-md">
        <div className="flex items-center">
          <Button
            text={"ADD NEW MATERIAL"}
            handleClick={() => setDataModalState(true)}
            className="px-6"
          />
          {/* <Button
            text={"IMPORT MATERIAL"}
            handleClick={() => setImportModalState(true)}
            className="px-6"
        /> */}
          <Button
            text={"ADD NEW CATEGORY"}
            handleClick={() => setCategoryModalState(true)}
            className="px-6"
          />
          <Button
            text={"ADD MACHINE"}
            handleClick={() => setMachineModalState(true)}
            className="px-6"
          />
          {/* <Button
            text={"GRN LOG"}
            handleClick={() => setViewGrnState(true)}
            className="px-6"
        /> */}
        </div>
        <div
          className="flex items-center justify-end mr-2"
          style={{ width: "100%" }}
        >
          <Input
            placeholder="   Type To Search"
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
            style={{
              width: "80%",
              border: "1px solid #878686",
              borderRadius: "4px",
              marginLeft: "auto",
              height: "40px",
            }} // Adjust width as needed
          />
        </div>
      </div>

      <Table
        columns={columns}
        dataSource={filteredServices}
        style={{ width: "100%" }}
      />
    </section>
  );
};

export default Materials;
