import React, { useState } from "react";
import ReactDOMServer from "react-dom/server";
import { Input, Button } from "../../../components/ui";

const ProductModal = ({ handleClose, selectedOrder }) => {
  const [inputs, setInputs] = useState({
    purpose: {
      key: "purpose",
      label: "Purpose",
      type: "text",
      error: null,
      readOnly: false,
    },
    payee: {
      key: "payee",
      label: "Payee",
      required: true,
      type: "text",
      error: null,
      readOnly: false,
    },
  });

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    setInputs(input_list);
  };

  const handlePrint = (order) => {
    const tableContent = (
      <div>
        <p>
          <strong>Payee:</strong> {inputs.payee.data}
        </p>
        <p>
          <strong>Date:</strong>{" "}
          {new Date(order.createdAt).toLocaleDateString()}
        </p>
        <p>
          <strong>Purpose:</strong> {inputs.purpose.data}
        </p>
        <p>
          <strong>Business Cost:</strong>{" "}
          {order.tip.toLocaleString("en-US", {
            style: "currency",
            currency: "LKR",
          })}
        </p>
        <br />
        <br />
        <p>............................</p>
        <p>Received By</p>
        <p className="signature">............................</p>
        <p className="signature">Authorized Signature</p>
      </div>
    );

    const printContent = ReactDOMServer.renderToString(tableContent);
    const printWindow = window.open("", "Print", "width=800,height=600");
    printWindow.document.write(
      `<html><head><title>Costing Report</title>
            <style>
            table.no-border, table.no-border th, table.no-border td {
              border: none !important;
              padding: 8px;
          }
              table, th, td {
                  border: 1px solid black;
                  border-collapse: collapse;
                  padding: 8px;
              }
            th {
                background-color: #f2f2f2;
            }
            img {
                width: 150px; /* Adjust the width as needed */
            }
            .print-content {
                text-align: center;
                margin-bottom: 20px;
            }
            .print-content p {
                font-family: Sinhala, Arial;
                font-size: 12px;
                margin: 20px 0 10px 0;
            }
            h1 {
                text-align: center;
            }
            p{
                fontFamily: Sinhala, Arial;
                fontSize: 12px;
                margin: 10px 0 5px 0;
            }
            .classdiv{
                flex:2;
                text-align: right;
            }
            .fullCls{
                display:flex;
                justify-content:space-between;
                align-itmes:center;
                margin-bottom:20px;
            }.imgcls{
                flex:1;
                text-align:left;
                width:150px;
            }    .signature {
                text-align: right;
            }
        </style>
        </head><body>
        <div class="fullCls">
      <div class="imgcls">
      <img src="/logo.png" alt="Logo"/>
      </div>
      <div class="classdiv">
      <p>
        තහඩු නැවීම, කැපිම, රොල් කිරීම, රූෆින් ශීට් සහ උපාංග, ස්ටීල්
        ෆැබ්‍රිකේශන් වැඩ
        <br />
        විලකොළදෙනිය, මලංගම, රත්නපුර
        <br />
        045 2121750, 074 0327850
      </p>
      </div>
      </div>
            <h1>PAYMENT VOUCHER</h1>
            ${printContent}
            </body></html>`
    );
    printWindow.document.write(
      `<script>window.onload = function() { window.print(); window.onafterprint = function() { window.close(); }; };</script>`
    );
    printWindow.document.close();
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75 ">
        <div className="shadow-xl transition-all w-[30%] mx-auto mt-[100px] rounded-lg bg-white">
          <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
            <h3 className="py-1 mx-auto">ORDER VOUCHER</h3>
            <span
              className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
              onClick={handleClose}
            >
              X
            </span>
          </div>
          <div className="mx-4 mt-2">
            <div className="w-full">
              <Input input={inputs.payee} handleChange={handleChange} />
              <Input input={inputs.purpose} handleChange={handleChange} />

              <Button
                handleClick={() => handlePrint(selectedOrder)}
                text={"PRINT"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
