import React, { useEffect, useState } from "react";
import { Input, Button, Select } from "../../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../../http";
import { Table } from "antd";
import Moment from "moment";

const ProductModal = ({ handleClose, selectedJob }) => {
    const [selectedOption, setSelectedOption] = useState('option1');
    const [isLoading, setIsLoading] = useState(false);

    const inputDataStructure = {
        remarks: {
            key: "remarks",
            label: "Remarks",
            data: "",
            type: "text",
            error: null,
        },
        hours: {
            key: "hours",
            label: "Hours",
            required: true,
            min: 1,
            max: 24,
            data: 0,
            type: "number",
            error: null,
        },
        labour: {
            key: "labour",
            label: "Labour",
            required: true,
            optTitle: "a labour",
            data: null,
            optList: [],
            error: null,
        },
        total:{
            key: "total",
            label: "Total",
            required: true,
            type: "number",
            error: null,
        },
        labourDate: {
            key: "labourDate",
            label: "Date",
            type: "date",
            error: null,
            required: true,
        },
    };

    const [inputs, setInputs] = useState(inputDataStructure);
    const [labourList, setLabourList] = useState([]);

    const handleChange = (input) => {
        let input_list = { ...inputs };
        input_list[input.key] = input;
        setInputs(input_list);
    };

    const handleChangeselect = (event) => {
        setSelectedOption(event.target.value);
    };

    const buildForm = async () => {
        let input_list = { ...inputs };

        let labours = await apiService.get("labour");
        const filteredLabours = labours.filter(labour => labour.status === true);

        const optList = filteredLabours.map((labour) => {
            return {
                id: labour.id,
                title: labour.title,
            };
        });

        input_list.labour.optList = optList;

        setInputs(input_list);
    };

    const getData = async () => {
        const labours = await apiService.get(`job/labours/${selectedJob.id}`);
        setLabourList(labours);
    };

    useEffect(() => {
        buildForm();
        getData();
    }, []);

    const handleSubmit = async () => {
        const event = window.event;
        event.preventDefault();

        const formData = {
            job: selectedJob.id,
            labour: inputs.labour.data,
            remarks: inputs.remarks.data,
            hours: inputs.hours.data,
            total: selectedOption,
            labourDate: inputs.labourDate.data,
        };

        try {
            setIsLoading(true);
            await apiService.patch("job/labours", formData);
            toast.success("Product labours updated successfully!");
            getData();
            setIsLoading(false);
            inputs.hours.data = 0;
            inputs.remarks.data = "";
        } catch (e) {
            toast.error(e);
            setIsLoading(false);
        }
    };

    const handleDelete = async (jobId, labourId) => {
        try {
            const formData = {
                jobId: jobId,
                labourId: labourId,
            };
            setIsLoading(true);
            await apiService.patch(`job/deletelabours`,formData);
            toast.success("Labour deleted successfully!");
            getData();
            setIsLoading(false);
        } catch (e) {
            toast.error(e.message);
            setIsLoading(false);
        }
    };

    const columns = [
        {
            title: "#",
            width: "5%",
            render: (arg, record, index) => {
                const rowNumber = index + 1;
                return <p className="text-center">{rowNumber}</p>;
            },
        },
        {
            title: "DATE",
            align: "center",
            width: "20%",
            render: (arg) => {
                const date = Moment(arg.labourDate);
                const formattedDatetime = date.format("YYYY-MM-DD");
                return formattedDatetime;
            },
        },
        {
            title: "LABOUR ID",
            dataIndex: "labourId",
            key: "labourId",
            align: "center",
            width: "10%",
        },
        {
            title: "HOURS",
            dataIndex: "hours",
            key: "hours",
            align: "center",
            width: "5%",
        },
        {
            title: "OT RATE",
            align: "center",
            width: "20%",
            render: (arg) => {
                const formattedPrice = arg.labouroverTime
                    ? arg.labouroverTime.toLocaleString("en-US", {
                          style: "currency",
                          currency: "LKR",
                      })
                    : "0";
                return formattedPrice;
            },
        },
        {
            title: "RATE",
            align: "center",
            width: "20%",
            render: (arg) => {
                const formattedPrice = arg.labourHourlyRate.toLocaleString(
                    "en-US",
                    {
                        style: "currency",
                        currency: "LKR",
                    }
                );
                return formattedPrice;
            },
        },
        {
            title: "TOTAL",
            align: "center",
            width: "20%",
            render: (arg) => {
                const total = arg.total;
                if (total === 'option1') {
                    const formattedPrice = (
                        arg.labouroverTime * arg.hours
                    ).toLocaleString("en-US", {
                        style: "currency",
                        currency: "LKR",
                    });
                    return formattedPrice;
                } else if (total === '2') {
                    const formattedPrice = (
                        arg.labourHourlyRate * arg.hours
                    ).toLocaleString("en-US", {
                        style: "currency",
                        currency: "LKR",
                    });
                    return formattedPrice;
                } else {
                    const formattedPrice = (
                        arg.labourHourlyRate * arg.hours
                    ).toLocaleString("en-US", {
                        style: "currency",
                        currency: "LKR",
                    });
                    return formattedPrice;
                }
            },
        },
        {
            title: "ACTIONS",
            align: "center",
            width: "5%",
            render: (arg) => {
                return (
                    <Button
                        handleClick={() => handleDelete(selectedJob.id, arg.labourId)}
                        text="Delete"
                    />
                );
            },
        },
    ];
    
    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
            onClick={handleClose}
        >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75">
                <div
                    className="transform shadow-xl transition-all w-[70%] mx-auto mt-[100px] rounded-lg bg-white"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
                        <h3 className="py-1 mx-auto text-xl text-center text-white bg-primary">
                            LABOUR CENTER
                        </h3>
                        <span
                            className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
                            onClick={handleClose}
                        >
                            X
                        </span>
                    </div>
                    <div className="flex gap-4 px-4">
                        <div className="w-[80%]">
                            <div className="w-full">
                                <h3 className="w-full text-lg text-center bg-slate-200 text-slate-500">
                                    Labour Costing
                                </h3>
                                <Table
                                    columns={columns}
                                    dataSource={labourList}
                                    scroll={{ y: 400 }}
                                />
                            </div>
                        </div>
                        <div className="w-[25%] mt-2">
                            <Select
                                input={inputs.labour}
                                handleChange={handleChange}
                            />
                            <div>
                                <label>Select a Rate:</label>
                                <select value={selectedOption} onChange={handleChangeselect}>
                                    <option value="1">OT Rate</option>
                                    <option value="2">Day Rate</option>
                                </select>
                            </div>
                            <Input
                                input={inputs.hours}
                                handleChange={handleChange}
                            />
                            <Input input={inputs.labourDate} handleChange={handleChange} />
                            <Input
                                input={inputs.remarks}
                                handleChange={handleChange}
                            />
                            {isLoading ? (
                                "Wait..."
                            ) : (
                                <Button
                                    handleClick={handleSubmit}
                                    className="w-full mt-6"
                                    text={"SAVE"}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductModal;
