import React, { useEffect, useState } from "react";
import { Table, Input } from "antd";
import { toast } from "react-toastify";
import ServiceModal from "./ServiceModal";
import { Confirm, Button } from "../../../components/ui";
import apiService from "../../../http";
import Thickness from "./thickness";
const Services = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [Services, setServices] = useState([]);
  const [dataModalState, setDataModalState] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [confirmModalState, setConfirmModalState] = useState(false);
  const [thickness, setThickness] = useState(false);
  const handleEdit = (service) => {
    setSelectedService(service);
    setDataModalState(true);
  };

  const handleDelete = async () => {
    try {
      await apiService.delete("service/" + selectedService.id);
      setConfirmModalState(false);
      refreshServices();
      setSelectedService(null);
      toast.success("Service deleted successfully");
    } catch (e) {
      toast.error(e);
    }
  };

  const handleCloseModal = () => {
    refreshServices();
    setDataModalState(false);
    setConfirmModalState(false);
    setSelectedService(null);
    setThickness(false);
  };
  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const filteredServices = Services.filter((service) => {
    const columnsToSearch = ["serviceId", "remarks"];
    return columnsToSearch.some((column) =>
      String(service[column]).toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
  const refreshServices = async () => {
    try {
      const services = await apiService.get("service");
      setServices(services);
    } catch (e) {
      toast.error(e);
    }
  };

  useEffect(() => {
    refreshServices();
  }, []);

  const actionBtns = (service) => {
    return (
      <div className="flex">
        {/* <Button
                    text="View"
                    className={
                        "ml-3 hover:bg-primary hover:text-white px-[14px]"
                    }
                    handleClick={() => handleEdit(service)}
                /> */}
        <Button
          text="DELETE"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedService(service);
            setConfirmModalState(true);
          }}
        />

        <Button
          text="EDIT"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedService(service);
            handleEdit(service);
          }}
        />

        <Button
          text="Add Prices"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setThickness(true);
            setSelectedService(service);
            handleEdit(service);
          }}
        />
      </div>
    );
  };

  const columns = [
    {
      title: "#",
      width: "10%",
      render: (arg, record, index) => {
        const rowNumber = index + 1;
        return <p className="text-center">{rowNumber}</p>;
      },
    },
    {
      title: "SERVICE ID",
      dataIndex: "serviceId",
      key: "serviceId",
      align: "center",
      width: "25%",
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "TITLE",
      dataIndex: "title",
      key: "title",
      align: "center",
      width: "25%",
    },
    {
      title: "REMARKS",
      align: "center",
      width: "25%",
      render: (arg) => {
        return arg.remarks ? arg.remarks : "N/A";
      },
    },

    {
      title: "OPERATIONS",
      align: "center",
      width: "65%",
      render: (arg) => actionBtns(arg),
    },
  ];

  return (
    <section className="w-full mt-6">
      {dataModalState && (
        <ServiceModal
          selectedService={selectedService}
          handleClose={handleCloseModal}
        />
      )}
      {thickness && (
        <Thickness
          selectedService={selectedService}
          handleClose={handleCloseModal}
        />
      )}
      {confirmModalState && (
        <Confirm
          cancelHandler={handleCloseModal}
          confirmHandler={handleDelete}
        />
      )}

      <div className="grid grid-cols-3 gap-3 px-2 py-2 mb-3 bg-white rounded-md">
        <div>
          <Button
            text={"ADD NEW SERVICE"}
            handleClick={() => setDataModalState(true)}
          />
        </div>
        <div className="flex items-center justify-end col-span-2">
          <Input
            placeholder="   Type To Search "
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
            style={{
              width: "30%",
              border: "1px solid #878686",
              borderRadius: "4px",
              height: "40px",
            }} // Adjust width as needed
          />
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={filteredServices}
        style={{ width: "100%" }}
      />
    </section>
  );
};

export default Services;
