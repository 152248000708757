import React from "react";

const PrintReport = ({ orders }) => {
  const totalAmount = orders.reduce(
    (acc, order) => acc + Number(order.total),
    0
  );

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <div style={{ flex: "1", textAlign: "left" }}>
          <img src="/logo.png" alt="Logo" style={{ width: "200px" }} />
        </div>
        <div style={{ flex: "2", textAlign: "right" }}>
          <p
            style={{
              fontFamily: "Sinhala, Arial",
              fontSize: "15px",
              margin: "20px 0 10px 0",
            }}
          >
            තහඩු නැවීම, කැපිම, රොල් කිරීම, රූෆින් ශීට් සහ උපාංග, ස්ටීල්
            ෆැබ්‍රිකේශන් වැඩ
            <br />
            විලකොළදෙනිය, මලංගම, රත්නපුර
            <br />
            045 2121750, 074 0327850
          </p>
        </div>
      </div>

      <h3 style={{ textAlign: "center" }}>Order Report</h3>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={{ border: "1px solid black" }}>Order ID</th>
            <th style={{ border: "1px solid black" }}>Customer Name</th>
            <th style={{ border: "1px solid black" }}>Total</th>
            <th style={{ border: "1px solid black" }}>Advance Payment</th>
            <th style={{ border: "1px solid black" }}>Products</th>
            <th style={{ border: "1px solid black" }}>Status</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order, index) => (
            <tr key={index}>
              <td style={{ border: "1px solid black" }}>{order.orderId}</td>
              <td style={{ border: "1px solid black" }}>
                {order.customerName}
              </td>
              <td style={{ border: "1px solid black" }}>
                {order.total.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </td>
              <td style={{ border: "1px solid black" }}>
                {order.advancePayment.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </td>
              <td style={{ border: "1px solid black" }}>
                <ul>
                  {order.products.map((product) => (
                    <li key={product.productId}>{product.title}</li>
                  ))}
                </ul>
              </td>
              <td style={{ border: "1px solid black" }}>{order.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <p className="mt-4 font-bold text-right">
        Total Amount:{" "}
        {totalAmount.toLocaleString("en-US", {
          style: "currency",
          currency: "LKR",
        })}
      </p>
    </div>
  );
};

export default PrintReport;
