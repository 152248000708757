import React, { useState } from "react";
import ListItem from "./listItem";
import {
  FaHome,
  FaBars,
  FaDollyFlatbed,
  FaShoppingCart,
  FaCashRegister,
  FaIdBadge,
  FaPeopleCarry,
  FaLuggageCart,
  FaScrewdriver,
  FaTruckMoving,
  FaUsers,
  FaChargingStation,
  FaClipboardList,
} from "react-icons/fa";
import "./SideBar.css"; // Import the CSS file

const SideBar = ({ section }) => {
  const navList = [
    {
      id: 1,
      title: "Home",
      url: "/",
      icon: <FaHome />,
      selected: section === "home",
    },
    {
      id: 2,
      title: "Materials",
      url: "/materials",
      icon: <FaBars />,
      selected: section === "materials",
    },
    {
      id: 3,
      title: "Products",
      url: "/products",
      icon: <FaScrewdriver />,
      selected: section === "products",
    },
    {
      id: 4,
      title: "Jobs",
      url: "/jobs",
      icon: <FaLuggageCart />,
      selected: section === "jobs",
    },
    {
      id: 5,
      title: "Orders",
      url: "/orders",
      icon: <FaTruckMoving />,
      selected: section === "orders",
    },
    {
      id: 6,
      title: "Services",
      url: "/services",
      icon: <FaDollyFlatbed />,
      selected: section === "services",
    },
    {
      id: 7,
      title: "Labours",
      url: "/labours",
      icon: <FaIdBadge />,
      selected: section === "labours",
    },
    {
      id: 8,
      title: "Customers",
      url: "/customers",
      icon: <FaUsers />,
      selected: section === "customers",
    },
    {
      id: 9,
      title: "Expenses",
      url: "/expenses",
      icon: <FaChargingStation />,
      selected: section === "expenses",
    },
    {
      id: 10,
      title: "POS",
      url: "/pos",
      icon: <FaCashRegister />,
      selected: section === "pos",
    },
    {
      id: 11,
      title: "Supplier",
      url: "/supplier",
      icon: <FaPeopleCarry />,
      selected: section === "supplier",
    },
    {
      id: 12,
      title: "Sales",
      url: "/sales",
      icon: <FaShoppingCart />,
      selected: section === "sales",
    },
    {
      id: 13,
      title: "Reports",
      url: "/reports",
      icon: <FaClipboardList />,
      selected: section === "reports",
      subItems: [
        {
          id: 17,
          title: "All",
          url: "#",
          selected: false,
          icon: <FaClipboardList />,
          subItems: [
            {
              id: 18,
              title1: "Orders",
              url: "/reports/ordersReport",
              selected: section === "ordersReport",
            },
            {
              id: 19,
              title1: "Materials",
              url: "/reports/materialReport",
              selected: section === "materialReport",
            },
            {
              id: 20,
              title1: "Expenses",
              url: "/reports/expencesReport",
              selected: section === "expencesReport",
            },
            {
              id: 21,
              title1: "Products",
              url: "/reports/productsReport",
              selected: section === "productsReport",
            },
            {
              id: 22,
              title1: "Sales",
              url: "/reports/salesReport",
              selected: section === "salesReport",
            },
          ],
        },
        {
          id: 24,
          title: "Customer",
          url: "#",
          selected: false,
          icon: <FaClipboardList />,
          subItems: [
            {
              id: 25,
              title1: "Customer",
              url: "/reports/customerReport",
              selected: section === "customerReport",
            },
            {
              id: 26,
              title1: "Outstanding",
              url: "/reports/cusromerOuts",
              selected: section === "cusromerOuts",
            },
            {
              id: 27,
              title1: "All",
              url: "/reports/customerAll",
              selected: section === "customerAll",
            },
            {
              id: 28,
              title1: "Outstanding Payment",
              url: "/reports/customerOut",
              selected: section === "customerOut",
            },
          ],
        },
        {
          id: 29,
          title: "Costing",
          url: "#",
          selected: false,
          icon: <FaClipboardList />,
          subItems: [
            {
              id: 23,
              title1: "All Orders",
              url: "/reports/orderCost",
              selected: section === "orderCost",
            },
            {
              id: 14,
              title1: "Jobs",
              url: "/reports/cost",
              selected: section === "cost",
            },
            {
              id: 15,
              title1: "Order",
              url: "/reports/costOrder",
              selected: section === "costOrder",
            },
          ],
        },
        {
          id: 24,
          title: "Supplier",
          url: "#",
          selected: false,
          icon: <FaClipboardList />,
          subItems: [
            {
              id: 16,
              title1: "Grn",
              url: "/reports/supplierGRN",
              selected: section === "supplierGRN",
            },
          ],
        },
      ],
    },
  ];

  return (
    <div className="sidebar">
      <ul className="w-full mt-20 mb-12 space-y-4">
        {navList.map((item) => (
          <ListItem key={item.id} item={item} />
        ))}
      </ul>
    </div>
  );
};

export default SideBar;
