import React, { useEffect, useState } from "react";
import { Input, Button } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";

const CustomerModal = ({ handleClose, sellAlert }) => {
  const inputDataStructure = {
    title: {
      key: "title",
      label: "NAME",
      required: true,
      type: "text",
      error: null,
    },
    nicNumber: {
      key: "nicNumber",
      label: "NIC NUMBER",
      data: "",
      required: true,
      type: "text",
      error: null,
    },
    numberMob: {
      key: "numberMob",
      label: "PHONE NUMBER",
      type: "text",
      error: null,
      required: true,
    },
    outstand: {
      key: "outstand",
      label: "OUTSTANDING LIMIT",
      data: "",
      required: true,
      type: "number",
      error: null,
    },
    address: {
      key: "address",
      label: "ADDRESS",
      type: "text",
      error: null,
      required: true,
    },
    status: {
      key: "status",
      label: "Status",
      data: true,
      type: "toggle",
      error: null,
    },
  };

  const [inputs, setInputs] = useState(inputDataStructure);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    setInputs(input_list);
  };

  const buildForm = async () => {};

  useEffect(() => {
    buildForm();
  }, []);

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    const formData = {
      title: inputs.title.data,
      numberMob: inputs.numberMob.data,
      nicNumber: inputs.nicNumber.data,
      address: inputs.address.data,
      status: inputs.status.data,
      outstand: inputs.outstand.data,
    };

    try {
      setIsLoading(true);

      await apiService.post("customer", formData);
      toast.success("customer added successfully!");

      handleClose();
      setIsLoading(false);
    } catch (e) {
      toast.error(e);
      setIsLoading(false);
    }
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75 ">
        <div className=" shadow-xl transition-all w-[25%] mx-auto mt-[100px] rounded-lg bg-white">
          <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
            <h3 className="py-1 mx-auto">ADD NEW CUSTOMER</h3>
            <span
              className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
              onClick={sellAlert}
            >
              X
            </span>
          </div>

          <div className="px-4 mt-2">
            <Input input={inputs.title} handleChange={handleChange} />
            <Input
              input={{
                ...inputs.numberMob,
                placeholder: "Mobile No. (Without leading zero)",
              }}
              handleChange={handleChange}
            />
            <Input input={inputs.outstand} handleChange={handleChange} />
            <Input input={inputs.nicNumber} handleChange={handleChange} />

            <Input input={inputs.address} handleChange={handleChange} />
            <ToggleInput input={inputs.status} handleChange={handleChange} />
            {isLoading ? (
              "Wait..."
            ) : (
              <Button
                handleClick={handleSubmit}
                className="mt-6"
                text="CREATE"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const ToggleInput = ({ input, handleChange }) => {
  return (
    <div className="flex items-center mt-4">
      <label className="mr-2">{input.label}:</label>
      <button
        className={`bg-${
          input.data ? "blue" : "red"
        }-500 px-3 py-1 rounded-md text-black`}
        onClick={() => handleChange({ ...input, data: !input.data })}
      >
        {input.data ? "ACTIVE" : "INACTIVE"}
      </button>
    </div>
  );
};
export default CustomerModal;
