import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { toast } from "react-toastify";
import { Button, Select } from "../../../../components/ui";
import apiService from "../../../../http";
import PrintReport from "./PrintReport"; // Adjust the import path as needed
import ReactDOMServer from "react-dom/server";

const Materials = () => {
  const [materials, setMaterials] = useState([]);
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const refreshMaterials = async () => {
    try {
      const materials = await apiService.get("material");
      console.log("Fetched materials:", materials);
      setMaterials(materials);
      setFilteredMaterials(materials);
    } catch (e) {
      toast.error(e.message);
    }
  };

  useEffect(() => {
    refreshMaterials();
  }, []);

  useEffect(() => {
    filterMaterials();
  }, [selectedSupplier, selectedUnit, selectedCategory]);

  const filterMaterials = () => {
    let filtered = materials;

    if (selectedSupplier) {
      filtered = filtered.filter(
        (material) => material.supplier === selectedSupplier
      );
    }

    if (selectedUnit) {
      filtered = filtered.filter(
        (material) => material.priceCalcMethod === selectedUnit
      );
    }

    if (selectedCategory) {
      filtered = filtered.filter(
        (material) => material.category === selectedCategory
      );
    }

    setFilteredMaterials(filtered);
  };

  const uniqueSuppliers = [
    ...new Set(materials.map((material) => material.supplier)),
  ].map((supplier, index) => ({
    id: supplier,
    title: supplier,
  }));
  const uniqueUnits = [
    ...new Set(materials.map((material) => material.priceCalcMethod)),
  ].map((unit, index) => ({
    id: unit,
    title: unit,
  }));
  const uniqueCategories = [
    ...new Set(materials.map((material) => material.category)),
  ].map((category) => ({
    id: category,
    title:
      materials.find((material) => material.category === category)
        ?.categoryName || category,
  }));

  const columns = [
    {
      title: "ID",
      key: "id",
      render: (arg, records, index) => (
        <p className="text-center">{index + 1}</p>
      ),
    },
    {
      title: "Material ID",
      key: "materialId",
      render: (args) => (
        <p className="w-[100px] text-left">{args.materialId}</p>
      ),
      align: "left",
      width: "10%",
    },
    {
      title: "Material Name",
      key: "materialName",
      render: (args) => <p className="min-w-[250px] text-left">{args.title}</p>,
      align: "left",
      width: "100%",
    },
    {
      title: "Quantity",
      key: "quantity",
      render: (arg) => stockCount(arg),
      align: "center",
      width: "100%",
    },
    {
      title: "Unit Price",
      key: "unitPrice",
      align: "right",
      render: (arg) => {
        if (!arg.cost) {
          return 0;
        }
        const formattedPrice = arg.cost.toLocaleString("en-US", {
          style: "currency",
          currency: "LKR",
        });
        return formattedPrice.replace(/LKR/g, ""); // Remove "LKR" from the formatted string
      },
      width: "100%",
    },
    {
      title: "Total Price",
      key: "totalPrice",
      align: "right",
      render: (args) => {
        const cost = Number(args.cost) || 0;
        const total = Number(stockCount(args)) * cost;
        const formattedPrice = total.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        });
        return formattedPrice;
      },
      width: "100%",
    },
  ];

  const stockCount = (material) => {
    return material.stock.full;
  };

  const calculateTotalSum = () => {
    return filteredMaterials.reduce((sum, material) => {
      const cost = Number(material.cost) || 0;
      const total = Number(stockCount(material)) * cost;
      return sum + total;
    }, 0);
  };

  const handlePrint = () => {
    const printContent = ReactDOMServer.renderToString(
      <PrintReport materials={filteredMaterials} />
    );
    const printWindow = window.open("", "Print", "width=800,height=600");
    printWindow.document.write(
      `<html><head><title>Material Stock Report</title></head><body>${printContent}</body></html>`
    );
    printWindow.document.write(
      `<script>window.onload = function() { window.print(); window.onafterprint = function() { window.close(); }; };</script>`
    );
    printWindow.document.close();
    printWindow.document.addEventListener("afterprint", function () {
      printWindow.document.close();
    });
  };

  return (
    <section className="w-full ">
      <div className="p-6 mx-3 my-1 border-2 rounded-md">
        <p className="text-lg font-bold">MATERIAL STOCK</p>
        <div className="flex mb-4 space-x-3">
          <Select
            input={{
              label: "Filter By Supplier",
              data: selectedSupplier,
              optList: uniqueSuppliers,
              optTitle: "Supplier",
            }}
            handleChange={(input) => setSelectedSupplier(input.data)}
            inputClassName="w-[200px]"
            labelClassName=""
          />
          <Select
            input={{
              label: "Filter By Unit",
              data: selectedUnit,
              optList: uniqueUnits,
              optTitle: "Unit",
            }}
            handleChange={(input) => setSelectedUnit(input.data)}
            inputClassName="w-[200px]"
            labelClassName=""
          />
          <Select
            input={{
              label: "Filter By Category",
              data: selectedCategory,
              optList: uniqueCategories,
              optTitle: "Category",
            }}
            handleChange={(input) => setSelectedCategory(input.data)}
            inputClassName="w-[200px]"
            labelClassName=""
          />
        </div>
        <Button
          text="CLEAR DATA"
          className="ml-3 hover:bg-primary hover:text-white px-[14px]"
          handleClick={() => {
            setSelectedSupplier(null);
            setSelectedUnit(null);
            setSelectedCategory(null);
            setFilteredMaterials(materials);
          }}
        />
        {isLoading ? (
          "Wait..."
        ) : (
          <Button
            text="PRINT"
            className="ml-3 hover:bg-primary hover:text-white px-[14px]"
            handleClick={handlePrint}
          />
        )}
      </div>
      <div id="printableArea" className="p-6 mx-3 my-1 border-2 rounded-md">
        <Table
          columns={columns}
          dataSource={filteredMaterials}
          style={{ width: "100%" }}
          pagination={false}
        />
        <div className="mt-4 font-bold text-right">
          Total Sum:{" "}
          {calculateTotalSum().toLocaleString("en-US", {
            minimumFractionDigits: 2,
          })}
        </div>
      </div>
    </section>
  );
};

export default Materials;
