import React, { useEffect, useState } from "react";
import { Table,Input } from "antd";
import { toast } from "react-toastify";
import { Confirm, Button } from "../../../components/ui";
import apiService from "../../../http";
import AddExpences from "./AddExpences";
import AddExpencesType from "./ExpencesType";

const Supplier = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [Services, setServices] = useState([]);
    const [dataModalState, setDataModalState] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [confirmModalState, setConfirmModalState] = useState(false);
    const [addModalState, setaddModalState] = useState(false);

    const handleEdit = (supplier) => {
        setSelectedSupplier(supplier);
        setDataModalState(true);
    };

    const handleDelete = async () => {
        try {
            await apiService.delete("expence/" + selectedSupplier.id);
            setConfirmModalState(false);
            refreshServices();
            setSelectedSupplier(null);
            toast.success("supplier deleted successfully");
        } catch (e) {
            toast.error(e);
        }
    };

    const handleCloseModal = () => {
        refreshServices();
        setDataModalState(false);
        setConfirmModalState(false);
        setSelectedSupplier(null);
        setaddModalState(false);

    };
    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    const filteredServices = Services.filter((supplier) => {
        const columnsToSearch = ["invNumber", "expId"]; 
        return columnsToSearch.some((column) =>
            String(supplier[column]).toLowerCase().includes(searchTerm.toLowerCase())
        );
    });
    const refreshServices = async () => {
        try {
            const services = await apiService.get("expence");
            setServices(services);
        } catch (e) {
            toast.error(e);
        }
    };

    useEffect(() => {
        refreshServices();
    }, []);

    const actionBtns = (supplier) => {
        return (
            <div className="flex">
                {/* <Button
                    text="View"
                    className={
                        "ml-3 hover:bg-primary hover:text-white px-[14px]"
                    }
                    handleClick={() => handleEdit(service)}
                /> */}
                <Button
                    text="DELETE"
                    className={
                        "ml-3 hover:bg-primary hover:text-white px-[14px]"
                    }
                    handleClick={() => {
                        setSelectedSupplier(supplier);
                        setConfirmModalState(true);
                    }}
                />

                <Button
                    text="EDIT"
                    className={
                        "ml-3 hover:bg-primary hover:text-white px-[14px]"
                    }
                    handleClick={() => {
                        setSelectedSupplier(supplier);
                        handleEdit(supplier);
                    }}
                />
            </div>
        );
    };

    const columns = [
        {
            title: "#",
            width: "2%",
            render: (arg, record, index) => {
                const rowNumber = index + 1;
                return <p className="text-center">{rowNumber}</p>;
            },
        },
        {
            title: "EXPENCES ID",
            dataIndex: "expId",
            key: "expId",
            align: "center",
            width: "10%",
            sorter: (a, b) => a.serviceId.localeCompare(b.serviceId),
        },
        {
            title: "EXPENCES",
            dataIndex: "expencesType",
            key: "expencesType",
            align: "center",
            width: "10%",
        },
        {
            title: "AMOUNT",
            align: "center",
            width: "10%",
            render: (item) =>'LKR.'+ item.amount, 
        },

        {
            title: "INVOICE NUMBER",
            align: "center",
            width: "10%",
            dataIndex: "invNumber",
            key: "invNumber",
         //   sorter: (a, b) => a.id - b.id,
        },
        {
            title: "DATE",
            align: "center",
            width: "4%",
            dataIndex: "createdAt",
            render: (createdAt) => {
                const date = new Date(createdAt);
                return date.toLocaleDateString(); 
            },
        },
        {
            title: "INVOICE BUY",
            align: "center",
            width: "4%",
            dataIndex: "invBuyer",
            key: "invBuyer",
         //   sorter: (a, b) => a.id - b.id,
        },
        {
            title: "JOB ID",
            align: "center",
            width: "10%",
            dataIndex: "ordId",
            key: "ordId",
         //   sorter: (a, b) => a.id - b.id,
        },
        {
            title: "STATUS",
            dataIndex: "status",
            key: "status",
            align: "center",
            width: "5%",
            render: (status) => (
                <span
                className={`rounded px-2 py-1 ${
                    status ? "bg-green-500 text-white" : "bg-red-500 text-white"
                }`}
            >
                {status ? "ACTIVE" : "INACTIVE"}
            </span>
          ),
          },
        {
            title: "OPERATIONS",
            align: "center",
            width: "35%",
            render: (arg) => actionBtns(arg),
        },
    ];

    return (
<section className="w-full mt-6">
    {dataModalState && (
        <AddExpences
            selectedSupplier={selectedSupplier}
            handleClose={handleCloseModal}
        />
    )}
    {confirmModalState && (
        <Confirm
            cancelHandler={handleCloseModal}
            confirmHandler={handleDelete}
        />
    )}
    {addModalState && (
        <AddExpencesType
            handleClose={handleCloseModal}
            selectedExpance={selectedSupplier}
        />
    )}
    <div className="flex gap-3 px-2 py-2 mb-3 bg-white rounded-md">
        <div className="flex items-center">
            <Button
                text={"ADD NEW EXPENCES"}
                handleClick={() => setDataModalState(true)}
            />
            <Button
                text={"ADD NEW TYPE"}
                handleClick={() => setaddModalState(true)}
            />
        </div>
        <div className="flex items-center justify-end" style={{ flex: 1 }}>
            <Input
                placeholder="Type To Search"
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
                style={{
                    width: "80%", // Use 100% width to fill the available space
                    maxWidth: "43%", // Limit the width if necessary
                    border: "1px solid #878686",
                    borderRadius: "4px",
                    height: "40px",
                }}
            />
        </div>
    </div>
    <Table
        columns={columns}
        dataSource={filteredServices}
        style={{ width: "100%" }}
    />
</section>

    );
};

export default Supplier;
