import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { Table } from "antd";
import { toast } from "react-toastify";
import apiService from "../../../../http";
import { Button } from "../../../../components/ui";

const CustomerOverview = () => {
  const [customers, setCustomers] = useState([]);

  const refreshCustomers = async () => {
    try {
      const response = await apiService.get("customer");
      setCustomers(response);
    } catch (e) {
      toast.error(e.message);
    }
  };

  useEffect(() => {
    refreshCustomers();
  }, []);

  const columns = [
    { title: "Customer Name", dataIndex: "title", key: "title" },
    { title: "Customer ID", dataIndex: "customerId", key: "customerId" },
    {
      title: "Total Invoiced Amount",
      dataIndex: "totalInvoicedAmount",
      key: "totalInvoicedAmount",
      render: (text, record) => {
        const totalInvoicedAmount = record.invoice
          ? record.invoice.reduce(
              (total, invoice) => total + invoice.customerAmount,
              0
            )
          : 0;
        return `${totalInvoicedAmount.toFixed(2)}`;
      },
    },
    {
      title: "Outstanding Limit",
      dataIndex: "outstand",
      key: "outstand",
      render: (text) => `${(text || 0).toFixed(2)}`,
    },
    {
      title: "Current Outstanding Amount",
      dataIndex: "outstandValue",
      key: "outstandValue",
      render: (text) => `${(text || 0).toFixed(2)}`,
    },
  ];

  const handlePrint = () => {
    const printContent = ReactDOMServer.renderToString(
      <Table
        columns={columns}
        dataSource={customers}
        rowKey="id"
        pagination={false}
      />
    );

    const printWindow = window.open("", "Print", "width=800,height=600");
    printWindow.document.write(
      `<html><head><title>Customer Overview</title>
      <style>
      table {
          width: 100%;
          border-collapse: collapse;
      }
      th, td {
          text-align: left;
          padding: 8px;
      }
      th {
          background-color: #f2f2f2;
      }
      img {
          width: 150px; /* Adjust the width as needed */
      }
      .print-content {
          text-align: center;
          margin-bottom: 20px;
      }
      .print-content p {
          font-family: Sinhala, Arial;
          font-size: 12px;
          margin: 20px 0 10px 0;
      }
      h1 {
          text-align: center;
      }
      p{
          fontFamily: Sinhala, Arial;
          fontSize: 12px;
          margin: 10px 0 5px 0;
      }
      .classdiv{
          flex:2;
          text-align: right;
      }
      .fullCls{
          display:flex;
          justify-content:space-between;
          align-itmes:center;
          margin-bottom:20px;
      }.imgcls{
          flex:1;
          text-align:left;
          width:150px;
      }
  </style>
  </head><body>
  <div class="fullCls">
<div class="imgcls">
<img src="/logo.png" alt="Logo"/>
</div>
<div class="classdiv">
<p>
  තහඩු නැවීම, කැපිම, රොල් කිරීම, රූෆින් ශීට් සහ උපාංග, ස්ටීල්
  ෆැබ්‍රිකේශන් වැඩ
  <br />
  විලකොළදෙනිය, මලංගම, රත්නපුර
  <br />
  045 2121750, 074 0327850
</p>
</div>
</div>
            <h1>CUSTOMER OVERVIEW</h1>
            ${printContent}
            </body></html>`
    );
    printWindow.document.write(
      `<script>window.onload = function() { window.print(); window.onafterprint = function() { window.close(); }; };</script>`
    );
    printWindow.document.close();
  };

  return (
    <section className="w-full">
      <div className="p-6 mx-3 my-1 border-2 rounded-md">
        <p className="text-lg font-bold">CUSTOMER OVERVIEW</p>
        <div className="mb-4">
          <Button
            text="Print"
            className="hover:bg-primary hover:text-white px-[14px]"
            handleClick={() => handlePrint()}
          />
        </div>
        <Table columns={columns} dataSource={customers} rowKey="id" />
      </div>
    </section>
  );
};

export default CustomerOverview;
