import React, { useEffect, useState } from "react";
import { Input, Button, Select } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";

const ProductModal = ({ handleClose, selectedProduct }) => {
  const inputDataStructure = {
    title: {
      key: "title",
      required: true,
      label: "Product title",
      data: "",
      type: "text",
      error: null,
    },

    remarks: {
      key: "remarks",
      label: "Remarks",
      data: "",
      type: "text",
      error: null,
    },
    status: {
      key: "status",
      label: "Status",
      data: true, // Assuming the default status is true (active)
      type: "toggle",
      error: null,
    },
    department: {
      key: "department",
      label: "Department",
      optList: [],
      required: false,
      type: "text",
      error: null,
    },
    // stock: {
    //     key: "stock",
    //     required: true,
    //     label: "Stock",
    //     data: '', // Assuming the default status is true (active)
    //     type: "text",
    //     error: null,
    // },
    // unitPrice: {
    //     key: "unitPrice",
    //     label: "Unit Price (LKR)",
    //     data: "",
    //     type: "number",
    //     min: 0,
    //     error: null,
    // },
    // maxDiscount: {
    //     key: "maxDiscount",
    //     label: "Maximum discount (%)",
    //     data: "",
    //     type: "number",
    //     min: 0,
    //     max: 100,
    //     error: null,
    // },
    // minAmountForDiscount: {
    //     key: "minAmountForDiscount",
    //     label: "Minimum quantity for discount",
    //     data: "",
    //     type: "number",
    //     min: 1,
    //     error: null,
    // },
  };

  const [inputs, setInputs] = useState(inputDataStructure);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    setInputs(input_list);
  };

  const buildForm = async () => {
    let input_list = { ...inputs };

    const departments = await apiService.get("product/department");
    const optList = departments.map((department) => {
      return {
        id: department.id,
        title: department.department,
      };
    });
    console.log("OptList:", optList); // Log optList to check if it's populated
    input_list.department.optList = optList;
    if (selectedProduct) {
      console.log(selectedProduct);
      input_list.department.data = selectedProduct.department;
      input_list.department.placeHolder = selectedProduct.departmentTitle; // Assuming selectedProduct has departmentTitle
      input_list.title.placeHolder = selectedProduct.title; // Assuming selectedProduct has departmentTitle

      input_list.title.data = selectedProduct.title;
      input_list.remarks.data = selectedProduct.remarks;
      input_list.status.data = selectedProduct.status;
      //input_list.stock.data = selectedProduct.stock ;
      // input_list.unitPrice.data = selectedProduct.unitPrice;
      // input_list.maxDiscount.data = selectedProduct.maxDiscount;
      // input_list.maxDiscount.data = selectedProduct.maxDiscount;
    }
    setInputs(input_list);
  };

  useEffect(() => {
    buildForm();
  }, []);

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    const data = {
      title: inputs.title.data,
      remarks: inputs.remarks.data,
      status: inputs.status.data,
      department: inputs.department.data,
    };

    try {
      setIsLoading(true);

      if (selectedProduct) {
        data.id = selectedProduct.id;
        await apiService.patch("product", data);
        toast.success("Product updated successfully!");
      } else {
        await apiService.post("product", data);
        toast.success("Product added successfully!");
      }

      handleClose();
      setIsLoading(false);
    } catch (e) {
      toast.error(e);
      setIsLoading(false);
    }
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75 ">
        <div className=" shadow-xl transition-all w-[25%] mx-auto mt-[100px] rounded-lg bg-white">
          <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
            <h3 className="py-1 mx-auto">
              {!selectedProduct && "ADD NEW "}
              {selectedProduct && "EDIT "}PRODUCT
            </h3>
            <span
              className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
              onClick={handleClose}
            >
              X
            </span>
          </div>
          <div className="mx-4 mt-2">
            <Input input={inputs.title} handleChange={handleChange} />
            <Select input={inputs.department} handleChange={handleChange} />
            {/* <Input
                            input={inputs.stock}
                            handleChange={handleChange}
                        /> */}
            {/* <Input
                            input={inputs.unitPrice}
                            handleChange={handleChange}
                        />
                        <Input
                            input={inputs.maxDiscount}
                            handleChange={handleChange}
                        />
                        <Input
                            input={inputs.minAmountForDiscount}
                            handleChange={handleChange}
                        /> */}
            <Input input={inputs.remarks} handleChange={handleChange} />
            <ToggleInput input={inputs.status} handleChange={handleChange} />
            {isLoading ? (
              "Wait..."
            ) : (
              <Button
                handleClick={handleSubmit}
                className="mt-6"
                text={selectedProduct ? "EDIT" : "CREATE"}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const ToggleInput = ({ input, handleChange }) => {
  return (
    <div className="flex items-center mt-4">
      <label className="mr-2">{input.label}:</label>
      <button
        className={`bg-${
          input.data ? "blue" : "red"
        }-500 px-3 py-1 rounded-md text-black`}
        onClick={() => handleChange({ ...input, data: !input.data })}
      >
        {input.data ? "ACTIVE" : "INACTIVE"}
      </button>
    </div>
  );
};

export default ProductModal;
