import React, { useEffect, useState } from "react";
import { Table } from "antd";
import apiService from "../../../http";
import OutstandPayment from "./OutstandPayment";
import { Button } from "../../../components/ui";

const ProductModal = ({ handleClose, selectedLabour }) => {
  const [products, setProducts] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [paymentModalState, setpaymentModalState] = useState(false);

  const columns = [
    {
      title: "#",
      width: "5%",
      render: (arg, record, index) => {
        const rowNumber = index + 1;
        return <p className="text-center">{rowNumber}</p>;
      },
    },

    {
      title: "DATE",
      dataIndex: "date",
      key: "date",
      align: "center",
      width: "30%",
      render: (date) => new Date(date).toLocaleDateString("en-US"),
    },
    {
      title: "INVOICE ID",
      dataIndex: "invoiceId",
      key: "invoiceId",
      align: "center",
      width: "30%",
    },

    {
      title: "PAYMENT METHOD",
      dataIndex: "pMethod",
      key: "pMethod",
      align: "center",
      width: "20%",
      render: (pMethod, record) => {
        if (pMethod === "cheque") {
          return (
            <span
              className={
                "rounded px-2 py-1 bg-blue-500 text-white cursor-pointer  hover:bg-blue-700"
              }
              onClick={() =>
                alert(
                  `Cheque Number: ${record.chequeNum}, Bank: ${record.bank}`
                )
              }
            >
              Cheque
            </span>
          );
        } else if (pMethod === "cash") {
          const method = "Cash";
          return (
            <button className="px-2 py-1 text-white bg-blue-500 rounded">
              {method}
            </button>
          );
        } else if (pMethod === "card") {
          const method = "Card";
          return (
            <button className="px-2 py-1 text-white bg-blue-500 rounded cursor-pointer hover:bg-blue-700">
              {method}
            </button>
          );
        } else if (pMethod === "bank") {
          return (
            <span
              className={
                "rounded px-2 py-1 bg-blue-500 text-white cursor-pointer  hover:bg-blue-700"
              }
              onClick={() =>
                alert(
                  `Bank: ${record.bank}, Account Name: ${record.accountName}, Account Number: ${record.accountNum}`
                )
              }
            >
              Bank
            </span>
          );
        } else if (pMethod === "cheque-return") {
          const method = "cheque-return";
          return (
            <button className="px-2 py-1 text-white bg-blue-500 rounded">
              {method}
            </button>
          );
        }
      },
    },

    {
      title: "CURRENT OUTSTAND",
      align: "center",
      width: "2%",
      render: (arg) => {
        if (typeof arg.currentoutstand === "number") {
          const formattedPrice = arg.currentoutstand.toLocaleString("en-US", {
            style: "currency",
            currency: "LKR",
          });
          return formattedPrice;
        }
      },
    },
    {
      title: "PAID AMOUNT",
      align: "center",
      width: "2%",
      render: (arg) => {
        if (typeof arg.paidAmount === "number") {
          const formattedPrice = arg.paidAmount.toLocaleString("en-US", {
            style: "currency",
            currency: "LKR",
          });
          return formattedPrice;
        }
      },
    },
    {
      title: "NET TOTAL",
      align: "center",
      width: "2%",
      render: (arg) => {
        if (typeof arg.customerAmount === "number") {
          const formattedPrice = arg.customerAmount.toLocaleString("en-US", {
            style: "currency",
            currency: "LKR",
          });
          return formattedPrice;
        }
      },
    },
    {
      title: "ACTION",
      align: "center",
      width: "25%",
      render: (arg) => {
        return (
          <div className="flex justify-between">
            {customer.outstandValue > 0 && (
              <div>
                <Button
                  text="PAY"
                  className={
                    "px-2 py-1 mx-2 text-white bg-blue-500 rounded cursor-pointer hover:bg-blue-700"
                  }
                  handleClick={() => {
                    setpaymentModalState(true);
                  }}
                />
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const getData = async () => {
    try {
      const orderData = await apiService.get(`customer/` + selectedLabour.id);
      setCustomer(orderData);

      setProducts(orderData.invoice);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      {paymentModalState && (
        <OutstandPayment customer={customer} handleClose={handleClose} />
      )}
      <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75 ">
        <div className=" shadow-xl transition-all w-[75%] mx-auto mt-[100px] rounded-lg bg-white">
          <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
            <h3 className="py-1 mx-auto">CUSTOMERS INVOICES </h3>
            <span
              className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
              onClick={handleClose}
            >
              X
            </span>
          </div>
          <div className="mx-4 mt-2">
            <div className="w-full">
              <Table
                pagination={{
                  defaultPageSize: 4,
                }}
                columns={columns}
                dataSource={products}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
