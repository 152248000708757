import React, { useEffect, useState } from "react";
import { Table } from "antd";
import apiService from "../../../http";

const ProductModal = ({ handleClose, selectedOrder }) => {
    const [jobIds, setJobIds] = useState([]);

    const columns = [
        {
            title: "#",
            width: "10%",
            render: (arg, record, index) => {
                return <p className="text-center">{index + 1}</p>;
            },
        },
        {
            title: "Job ID",
            dataIndex: "jobId",
            key: "jobId",
            align: "center",
            width: "90%",
        },
    ];

    const getData = async () => {
        try {
            const response = await apiService.get(`job/order/${selectedOrder.id}`);
            console.log(response); // Log the entire response to inspect its structure

            // Map the job IDs to the format required by the Table component
            const jobIdsData = response.jobIds.map((jobId) => ({ jobId }));
            setJobIds(jobIdsData);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        if (selectedOrder.id) {
            getData();
        }
    }, [selectedOrder.id]);

    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75 ">
                <div className="shadow-xl transition-all w-[30%] mx-auto mt-[100px] rounded-lg bg-white">
                    <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
                        <h3 className="py-1 mx-auto">Job IDs</h3>
                        <span
                            className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
                            onClick={handleClose}
                        >
                            X
                        </span>
                    </div>
                    <div className="mx-4 mt-2">
                        <div className="w-full">
                            <Table
                                pagination={{
                                    defaultPageSize: 4,
                                }}
                                columns={columns}
                                dataSource={jobIds}
                                rowKey="jobId"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductModal;
