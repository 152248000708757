import React from "react";

const SingleItem = () => {
    return (
        <thead>
            <tr className="px-3 mx-3 bg-slate-400">
                <th className="text-left">NO.</th>
                <th className="text-left">DESCRIPTION</th>
                <th className="text-right">QUANTITY</th>
                <th className="text-right">RATE</th>
                <th className="text-right">DISCOUNT</th>
                <th className="text-right">AMOUNT</th>
                <th className="text-center">ACTION</th>
            </tr>
        </thead>
    );
};

export default SingleItem;
