import React, { useEffect, useState } from "react";
import { Input, Button, Select, Confirm } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";
import { Table } from "antd";
import CustomerModal from "./CustomerModal";

const ProductModal = ({ handleClose, selectedProduct }) => {
    const initialState = {
        product: {
            key: "product",
            required: true,
            label: "Product",
            optList: [],
            data: null,
            type: "text",
            error: null,
        },
        quantity: {
            key: "quantity",
            label: "Quantity",
            required: true,
            min: 1,
            data: 1,
            type: "number",
            error: null,
        },
        unitPrice: {
            key: "unitPrice",
            label: "Unit Price (LKR)",
            required: true,
            min: 1,
            data: 0,
            type: "number",
            error: null,
            readOnly: false,
        },
        remarks: {
            key: "remarks",
            label: "Remarks",
            data: "",
            type: "text",
            error: null,
        },
        customerName: {
            key: "customerName",
            required: true,
            label: "Customer Name",
            data: "",
            type: "text",
            error: null,
        },
        customerPhone: {
            key: "customerPhone",
            required: true,
            label: "Customer Phone",
            data: "",
            type: "text",
            error: null,
        },
        customerAddress: {
            key: "customerAddress",
            required: true,
            label: "Customer Address",
            data: "",
            type: "text",
            error: null,
        },
        advancePayment: {
            key: "advancePayment",
            label: "Advance Payment (LKR)",
            min: 0,
            data: 0,
            type: "number",
            error: null,
        },
        tip: {
            key: "tip",
            label: "Business Cost",
            min: 0,
            data: 0,
            type: "number",
            error: null,
        },
    };

    const [inputs, setInputs] = useState(initialState);
    const [products, setProducts] = useState([]);
    const [cart, setCart] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [customerNames, setCustomerNames] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [customerModal, setCustomerModal] = useState(false);
    const [taxOption, setTaxOption] = useState(false); 

    const handleTaxOptionChange = () => {
        setTaxOption(!taxOption); 
    };

    const handleChange = async (input) => {
        setInputs((prevInputs) => {
            const updatedInputs = { ...prevInputs, [input.key]: input };

            if (input.key === "product") {
                const selectedProduct = products.find(item => item.id === input.data);
                if (selectedProduct) {
                    updatedInputs.unitPrice.data = selectedProduct.unitPrice || 0;
                    updatedInputs.unitPrice.readOnly = selectedProduct.unitPrice > 0;
                }
            }

            if (input.key === "customerName") {
                const selectedCustomer = customerData.find(customer => customer.title === input.data);
                if (selectedCustomer) {
                    apiService.get(`customer/${selectedCustomer.id}`).then(customerDetails => {
                        setInputs(prevInputs => ({
                            ...prevInputs,
                            customerPhone: { ...prevInputs.customerPhone, data: customerDetails.numberMob },
                            customerAddress: { ...prevInputs.customerAddress, data: customerDetails.address },
                        }));
                    }).catch(error => {
                        console.error("Error fetching customer details:", error);
                    });
                } else {
                    updatedInputs.customerPhone.data = "";
                    updatedInputs.customerAddress.data = "";
                }
            }

            return updatedInputs;
        });
    };

    const buildForm = async () => {
        const products = await apiService.get("product");
        const filteredProducts = products.filter(product => product.status === true);

        const optList = filteredProducts.map(product => ({
            id: product.id,
            title: `${product.productId} - ${product.title} (${product.stock} items left)`,
        }));

        setInputs(prevInputs => ({
            ...prevInputs,
            product: { ...prevInputs.product, optList },
        }));
        setProducts(filteredProducts);
    };

    const fetchCustomers = async () => {
        try {
            const customers = await apiService.get("customer");
            const customerNames = customers.map(customer => customer.title);
            const customerData = customers.map(customer => ({ id: customer.id, title: customer.title }));

            setCustomerNames(customerNames);
            setCustomerData(customerData);
        } catch (error) {
            console.error("Error fetching customers:", error);
        }
    };

    useEffect(() => {
        buildForm();
        fetchCustomers();
    }, []);

    const handleSubmit = async () => {
        const formData = {
            products: cart,
            advancePayment: inputs.advancePayment.data,
            remarks: inputs.remarks.data,
            customerName: inputs.customerName.data,
            customerPhone: inputs.customerPhone.data,
            customerAddress: inputs.customerAddress.data,
            tip: inputs.tip.data,
            taxOption: taxOption ? "tax" : "", 

        };

        try {
            setIsLoading(true);
            await apiService.post("order", formData);
            toast.success("Order created successfully!");
            handleClose();
        } catch (error) {
            toast.error("Error creating order: " + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddProduct = async () => {
        const productId = inputs.product.data;
        const product = products.find(item => item.id === productId);

        if (!product) {
            toast.error("Please select a product first");
            return;
        }

        const quantity = Number(inputs.quantity.data);
        if (quantity === 0) {
            toast.error("Please enter a valid quantity");
            return;
        }

        const unitPrice = Number(inputs.unitPrice.data);
        if (!unitPrice || unitPrice === 0) {
            toast.error("Please enter a unit price");
            return;
        }

        const cartItem = { ...product, quantity, unitPrice };
        setCart(prevCart => {
            const existingIndex = prevCart.findIndex(item => item.id === productId);
            if (existingIndex !== -1) {
                prevCart[existingIndex].quantity += quantity;
            } else {
                prevCart.push(cartItem);
            }
            return [...prevCart];
        });

        setInputs(prevInputs => ({
            ...prevInputs,
            quantity: { ...prevInputs.quantity, data: 0 },
            unitPrice: { ...prevInputs.unitPrice, data: 0 },
        }));

        if (product.unitPrice <= 0) {
            try {
                await apiService.post("order/unitPrice", { id: product.id, unitPrice });
            } catch (error) {
                toast.error("Error updating unit price: " + error.message);
            }
        }
    };

    const openAddCustomer = () => {
        setCustomerModal(true);
    };

    const actionBtns = (productId) => (
        <div className="flex">
            <Button
                text="REMOVE"
                className="ml-3 bg-slate-400 hover:text-white px-[14px]"
                handleClick={() => setCart(prevCart => prevCart.filter(product => product.id !== productId))}
            />
        </div>
    );

    const columns = [
        {
            title: "#",
            width: "5%",
            render: (text, record, index) => <p className="text-center">{index + 1}</p>,
        },
        {
            title: "PRODUCT TITLE",
            dataIndex: "title",
            key: "title",
            align: "center",
            width: "30%",
        },
        {
            title: "QUANTITY",
            dataIndex: "quantity",
            key: "quantity",
            align: "center",
            width: "20%",
        },
        {
            title: "RATE",
            align: "center",
            width: "20%",
            render: (text, record) => record.unitPrice.toLocaleString("en-US", {
                style: "currency",
                currency: "LKR",
            }),
        },
        {
            title: "TOTAL",
            align: "center",
            width: "20%",
            render: (text, record) => (record.unitPrice * record.quantity).toLocaleString("en-US", {
                style: "currency",
                currency: "LKR",
            }),
        },
        {
            title: "ACTIONS",
            align: "center",
            width: "20%",
            render: (text, record) => actionBtns(record.id),
        },
    ];

    return (
        <>
            {customerModal && (
                <CustomerModal
                    handleClose={() => setCustomerModal(false)}
                    inputValues={inputs}
                    sellAlert={() => setCustomerModal(false)}
                />
            )}
            <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75">
                    <div className="shadow-xl transition-all w-[70%] mx-auto mt-[100px] rounded-lg bg-white">
                        <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
                            <h3 className="py-1 mx-auto"> CREATE NEW ORDER</h3>
                            <span
                                className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
                                onClick={handleClose}
                            >
                                X
                            </span>
                        </div>
                        <div className="flex gap-3 mx-4 mt-2">
                            <div className="w-[80%]">
                                <div className="w-full">
                                    <h3 className="w-full text-lg text-center bg-slate-200 text-slate-500">
                                        PRODUCT LIST
                                    </h3>
                                    <Table
                                        pagination={{ defaultPageSize: 4 }}
                                        columns={columns}
                                        dataSource={cart}
                                    />
                                </div>
                            </div>
                            <div className="w-[20%]">
                                <h3 className="w-full mb-4 text-lg text-center bg-slate-200 text-slate-500">
                                    ADD PRODUCTS
                                </h3>
                                <Select input={inputs.product} handleChange={handleChange} />
                                <Input input={inputs.quantity} handleChange={handleChange} />
                                <Input input={inputs.unitPrice} handleChange={handleChange} />
                                <Button handleClick={handleAddProduct} className="w-full mt-6" text="ADD" />
                                <h3 className="w-full mb-4 text-lg text-center bg-slate-200 text-slate-500">
                                    CUSTOMER DETAILS
                                </h3>
                                <Input
                                    input={{ ...inputs.customerName, list: 'data' }}
                                    handleChange={input => handleChange({ ...input, data: input.data })}
                                />
                                <datalist id="data">
                                    {customerNames.map((name, index) => (
                                        <option key={index} value={name} />
                                    ))}
                                </datalist>
                                <Input
                                    input={{ ...inputs.customerPhone, placeholder: "Without leading zero" }}
                                    handleChange={handleChange}
                                />
                                <Input input={inputs.customerAddress} handleChange={handleChange} />
                                <Input input={inputs.advancePayment} handleChange={handleChange} />
                                <Input input={inputs.remarks} handleChange={handleChange} />
                                <Input input={inputs.tip} handleChange={handleChange} />
                                <label className="inline-flex items-center">
                                    <input
                                        type="checkbox"
                                        className="form-checkbox"
                                        checked={taxOption}
                                        onChange={handleTaxOptionChange}
                                    />
                                    <span className="ml-2">Include Tax</span>
                                </label>
                                {isLoading ? (
                                    "Wait..."
                                ) : (
                                    <Button
                                        handleClick={() => {
                                            if (!customerNames.includes(inputs.customerName.data)) {
                                                alert("Please first add customer before creating a new order");
                                            } else {
                                                handleSubmit();
                                            }
                                        }}
                                        className="w-full mt-6"
                                        text="PLACE ORDER"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductModal;
