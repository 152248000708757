import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { toast } from "react-toastify";
import apiService from "../../../../http";
import { Select, Button } from "../../../../components/ui";
import ReactDOMServer from "react-dom/server";

const SupplierReport = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplierId, setSelectedSupplierId] = useState(null);
  const [supplierData, setSupplierData] = useState([]);
  const [supplierDetails, setSupplierDetails] = useState(null);

  useEffect(() => {
    // Fetch suppliers list
    const fetchSuppliers = async () => {
      try {
        const response = await apiService.get("/supplier/");
        setSuppliers(response);
      } catch (e) {
        toast.error(e.message);
      }
    };

    fetchSuppliers();
  }, []);

  useEffect(() => {
    // Fetch GRN data based on selected supplier
    const fetchSupplierData = async () => {
      if (selectedSupplierId) {
        try {
          const response = await apiService.get(
            `/supplier/grnMat/${selectedSupplierId}`
          );

          setSupplierData(response);
        } catch (e) {
          toast.error("Suppier GRN Empty");
        }
      }
    };

    fetchSupplierData();
  }, [selectedSupplierId]);

  useEffect(() => {
    // Fetch supplier details based on selected supplier
    const fetchSupplierDetails = async () => {
      if (selectedSupplierId) {
        try {
          const response = await apiService.get(
            `/supplier/${selectedSupplierId}`
          );
          setSupplierDetails(response);
        } catch (e) {
          toast.error(e.message);
        }
      }
    };

    fetchSupplierDetails();
  }, [selectedSupplierId]);

  const columns = [
    { title: "GRN ID", dataIndex: "grnId", key: "grnId" },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    { title: "Total Amount", dataIndex: "totalAmount", key: "totalAmount" },
    { title: "Pay Amount", dataIndex: "payAmount", key: "payAmount" },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (status ? "Unpaid" : "Paid"),
    },
  ];

  const handlePrint = () => {
    const printContent = ReactDOMServer.renderToString(
      <div>
        <h1>Supplier Report</h1>
        <Table
          columns={columns}
          dataSource={supplierData}
          rowKey="_id"
          pagination={false}
        />
      </div>
    );

    const printWindow = window.open("", "Print", "width=800,height=600");
    printWindow.document.write(
      `<html><head><title>Supplier Report</title>
      <style>
      table {
          width: 100%;
          border-collapse: collapse;
      }
      th, td {
          text-align: left;
          padding: 8px;
      }
      th {
          background-color: #f2f2f2;
      }
      img {
          width: 150px; /* Adjust the width as needed */
      }
      .print-content {
          text-align: center;
          margin-bottom: 20px;
      }
      .print-content p {
          font-family: Sinhala, Arial;
          font-size: 12px;
          margin: 20px 0 10px 0;
      }
      h1 {
          text-align: center;
      }
      p{
          font-family: Sinhala, Arial;
          font-size: 12px;
          margin: 10px 0 5px 0;
      }
      .classdiv{
          flex:2;
          text-align: right;
      }
      .fullCls{
          display:flex;
          justify-content:space-between;
          align-items:center;
          margin-bottom:20px;
      }
      .imgcls{
          flex:1;
          text-align:left;
          width:150px;
      }
  </style>
  </head><body>
  <div class="fullCls">
    <div class="imgcls">
      <img src="/logo.png" alt="Logo"/>
    </div>
    <div class="classdiv">
      <p>
        තහඩු නැවීම, කැපිම, රොල් කිරීම, රූෆින් ශීට් සහ උපාංග, ස්ටීල්
        ෆැබ්‍රිකේශන් වැඩ
        <br />
        විලකොළදෙනිය, මලංගම, රත්නපුර
        <br />
        045 2121750, 074 0327850
      </p>
    </div>
  </div>
      ${printContent}
      </body></html>`
    );
    printWindow.document.write(
      `<script>window.onload = function() { window.print(); window.onafterprint = function() { window.close(); }; };</script>`
    );
    printWindow.document.close();
  };

  const calculateTotals = () => {
    const totalAmount = supplierData.reduce(
      (acc, item) => acc + (item.totalAmount || 0),
      0
    );
    const totalPaid = supplierData.reduce(
      (acc, item) => acc + (item.payAmount || 0),
      0
    );
    return { totalAmount, totalPaid };
  };

  const { totalAmount, totalPaid } = calculateTotals();

  return (
    <section className="w-full">
      <div className="p-6 mx-3 my-1 border-2 rounded-md">
        <p className="text-lg font-bold">SUPPLIER REPORT</p>
        <div className="flex flex-wrap mb-4 space-x-3">
          <div className="w-[200px] mr-3">
            <Select
              input={{
                label: "Select Supplier",
                data: selectedSupplierId,
                optList: suppliers.map((supplier) => ({
                  id: supplier.id,
                  title: supplier.name, // Assuming suppliers have a `name` property
                })),
                optTitle: "Supplier",
              }}
              handleChange={(input) => setSelectedSupplierId(input.data)}
              inputClassName="w-[200px] mr-3"
              labelClassName=""
            />
            <Button
              text="Print Report"
              className="hover:bg-primary hover:text-white px-[14px] mt-4"
              handleClick={handlePrint}
            />
          </div>
        </div>
        {supplierData.length > 0 && (
          <div className="flex">
            <div className="w-1/2">
              <h3>Supplier Details</h3>
              {supplierDetails && (
                <div>
                  <p>
                    <strong>Name:</strong> {supplierDetails.name}
                  </p>
                  <p>
                    <strong>Contact Number:</strong>{" "}
                    {supplierDetails.contactNumber}
                  </p>
                  <p>
                    <strong>Remarks:</strong> {supplierDetails.remarks}
                  </p>
                </div>
              )}
            </div>
            <div className="w-1/2 text-right">
              <h3>Total Amount</h3>
              <p>
                <strong>Total Amount:</strong> {totalAmount}
              </p>
              <p>
                <strong>Total Paid:</strong> {totalPaid}
              </p>
            </div>
          </div>
        )}
        {supplierData.length > 0 && (
          <Table
            columns={columns}
            dataSource={supplierData}
            rowKey="_id"
            pagination={false}
          />
        )}
      </div>
    </section>
  );
};

export default SupplierReport;
