import React, { useEffect, useState } from "react";
import { Input, Select, Button } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";

const ImportMaterialModal = ({ handleClose }) => {
    const inputDataStructure = {
        material: {
            key: "material",
            label: "Material",
            data: null,
            optList: [],
            required: true,
            type: "text",
            error: null,
        },
        supplier: {
            key: "supplier",
            label: "Supplier",
            optList: [],
            required: false,
            type: "text",
            error: null,
        },
        cost: {
            key: "cost",
            label: "Import cost (LKR)",
            data: "",
            required: true,
            type: "number",
            min: 1,
            error: null,
        },


        remarks: {
            key: "remarks",
            label: "Remarks",
            data: "",
            type: "text",
            error: null,
        },

        quantity: {
            key: "quantity",
            label: "Quantity",
            min: 1,
            data: "",
            type: "number",
            error: null,
        },
    };

    const [inputs, setInputs] = useState(inputDataStructure);
    const [materials, setMaterials] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (input) => {
        let input_list = { ...inputs };
        input_list[input.key] = input;
    
        if (input.key === "material") {
            const material = materials.find((item) => {
                return item.id === inputs.material.data;
            });
    
            // Check if material is defined before accessing its properties
            if (material) {
                if (material.priceCalcMethod === "weight-based") {
                    input_list.quantity.label = "Amount of material to import (kg)";
                } else {
                    input_list.quantity.label = "Number of items to import";
                }
            } else {
                console.error("Material not found for the given id");
            }
        }
    
        setInputs(input_list);
    };
    
    const buildForm = async () => {
        let input_list = { ...inputs };

        const materials = await apiService.get("supplier");
        const filteredMaterials = materials.filter(material => material.status === true);

        const optList = filteredMaterials.map((material) => {
            return {
                id: material.name,
                title: material.name,
            };
        });
        input_list.supplier.optList = optList;
        const supplier = await apiService.get("material");
        const filteredsupplier = supplier.filter(material => material.status === true);

        const optListt = filteredsupplier.map((material) => {
            return {
                id: material.id,
                title: material.title,
            };
        });
        input_list.material.optList = optListt;

        setMaterials(filteredMaterials);
        setInputs(input_list);
    };

    useEffect(() => {
        buildForm();
    }, []);

    const handleSubmit = async () => {
        const event = window.event;
        event.preventDefault();

        const formData = {
            material: inputs.material.data,
            remarks: inputs.remarks.data,
            cost: inputs.cost.data,
            quantity: inputs.quantity.data,
            supplier:inputs.supplier.data,
        };

        try {            setIsLoading(true);

            await apiService.post("material/import", formData);
            toast.success("Material imported successfully!");

            handleClose();
            setIsLoading(false);

        } catch (e) {
            toast.error(e);
            setIsLoading(false);

        }
    };


    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75 ">
                <div className=" shadow-xl transition-all w-[25%] mx-auto mt-[100px] rounded-lg bg-white">
                    <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
                        <h3 className="py-1 mx-auto">IMPORT MATERIAL</h3>
                        <span
                            className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
                            onClick={handleClose}
                        >
                            X
                        </span>
                    </div>

                    <div className="px-4 mt-2">
                        <Select
                            input={inputs.material}
                            handleChange={handleChange}
                        />
                        <Select
                            input={inputs.supplier}
                            handleChange={handleChange}
                        />
                        <Input
                            input={inputs.remarks}
                            handleChange={handleChange}
                        />
                        <Input
                            input={inputs.cost}
                            handleChange={handleChange}
                        />

                        {inputs.material.data && (
                            <Input
                                input={inputs.quantity}
                                handleChange={handleChange}
                            />
                        )}
{isLoading ? (
                        "Wait..."
                    ) : (
                        <Button
                            handleClick={handleSubmit}
                            className="mt-6"
                            text={"IMPORT"}
                        />)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImportMaterialModal;
