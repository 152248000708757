import React, { useEffect, useState } from "react";
import { Input, Select, Button } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";

const MaterialModal = ({ handleClose, selectedMaterial }) => {
    const inputDataStructure = {
        title: {
            key: "title",
            label: "Title",
            data: "",
            required: true,
            type: "text",
            error: null,
        },
        priceCalcMethod: {
            key: "priceCalcMethod",
            label: "Unit",
            data: null,
            required: true,
            optTitle: "a method",
            optList: [
                { id: "nos", title: "NOS" },
                { id: "kg", title: "KG" },
                { id: "g", title: "G" },
                { id: "mg", title: "MG" },
                { id: "l", title: "L" },
                { id: "ml", title: "ML" },
                { id: "ft", title: "FT" },
                { id: "m", title: "M" },
                { id: "sqft", title: "SQ-FT" },
                { id: "sqm", title: "SQ-M" },
                { id: "cm", title: "CM" },
                { id: "mm", title: "MM" },
                { id: "lnft", title: "LN-FT" },

            ],
            type: "text",
            error: null,

        },
        remarks: {
            key: "remarks",
            label: "Remarks",
            data: "",
            type: "text",
            error: null,
        },
        length: {
            key: "length",
            //readOnly: true,
            label: "Length of one item ",
            data: "",
            type: "number",
            error: null,
        },

        width: {
            key: "width",
            //readOnly: true,
            label: "Width of one item ",
            data: "",
            type: "number",
            error: null,
        },
        status: {
            key: "status",
            label: "Status",
            data: true, // Assuming the default status is true (active)
            type: "toggle",
            error: null,
        },
        category: {
            key: "category",
            label: "category",
            optList: [],
            required: true,
            type: "text",
            error: null,
        },
    };

    const [inputs, setInputs] = useState(inputDataStructure);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (input) => {
        let input_list = { ...inputs };
        input_list[input.key] = input;

        if (input.key === "priceCalcMethod") {
            switch (input.data) {
                case "nos":
                case "kg":
                case "g":
                case "mg":
                case "l":
                case "ml":
                case "ft":
                case "m":
                case "cm":
                case "mm":
       /*             input_list.length.required = false;
                    input_list.length.readOnly = true;
                    input_list.width.required = false;
                    input_list.width.readOnly = true;
                    break;*/
                case "lnft":
                case "sqft":
                case "sqm":
                  /*     input_list.length.required = true;
                    input_list.length.readOnly = false;
                    input_list.width.required = false;
                    input_list.width.readOnly = true;*/
                    break;
        
                default:
                    break;
            }
        }
        
        setInputs(input_list);
    };
    const buildForm = async () => {
        try {
            let input_list = { ...inputs };

            const categories = await apiService.get("material/mcategory");
            const optList = categories.map((category) => {
                return {
                    id: category.id,
                    title: category.category,
                };
            });
            input_list.category.optList = optList;
            if (selectedMaterial) {
                input_list.category.data = selectedMaterial.category;

                input_list.title.data = selectedMaterial.title;
                input_list.priceCalcMethod.data = selectedMaterial.priceCalcMethod;
                input_list.width.data = selectedMaterial.width;
                input_list.length.data = selectedMaterial.length;
                input_list.remarks.data = selectedMaterial.remarks;
                input_list.status.data = selectedMaterial.status;
    
            }
            setInputs(input_list);

        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };
    

    useEffect(() => {
        buildForm();
    }, []);

    const handleSubmit = async () => {
        const event = window.event;
        event.preventDefault();

        const formData = {
            title: inputs.title.data,
            remarks: inputs.remarks.data,
            priceCalcMethod: inputs.priceCalcMethod.data,
            width: inputs.width.data,
            length: inputs.length.data,
            status: inputs.status.data,
            category:inputs.category.data,
        };

        try {
            setIsLoading(true);

            if (selectedMaterial) {
                formData.id = selectedMaterial.id;
                await apiService.patch("material", formData);
                toast.success("Material updated successfully!");
            } else {
                await apiService.post("material", formData);
                toast.success("Material added successfully!");
            }

            handleClose();
            setIsLoading(false);

        } catch (e) {
            toast.error(e);
            setIsLoading(false);

        }
    };

    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75 ">
                <div className=" shadow-xl transition-all w-[25%] mx-auto mt-[100px] rounded-lg bg-white">
                    <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
                        <h3 className="py-1 mx-auto">
                            {!selectedMaterial && "ADD NEW "}
                            {selectedMaterial && "EDIT "}
                            MATERIAL
                        </h3>
                        <span
                            className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
                            onClick={handleClose}
                        >
                            X
                        </span>
                    </div>

                    <div className="px-4 mt-2">
                        <Input
                            input={inputs.title}
                            handleChange={handleChange}
                        />
<Select
                            input={inputs.category}
                            handleChange={handleChange}
                        />
                        <Select
                            input={inputs.priceCalcMethod}
                            handleChange={handleChange}
                        />
                        {["ft", "m", "cm", "sqft","lnft","sqm","mm"].includes(
                            inputs.priceCalcMethod.data
                        ) && (
                            <Input
                                input={inputs.length}
                                handleChange={handleChange}
                            />
                        )}

                        {["sqft", "lnft", "sqm"].includes(inputs.priceCalcMethod.data) && (
                            <Input
                                input={inputs.width}
                                handleChange={handleChange}
                            />
                        )}

                        <Input
                            input={inputs.remarks}
                            handleChange={handleChange}
                        />
                                                <ToggleInput input={inputs.status} handleChange={handleChange} />
                                                {isLoading ? (
                        "Wait..."
                    ) : (
                        <Button
                            handleClick={handleSubmit}
                            className="mt-6"
                            text={selectedMaterial ? "EDIT" : "CREATE"}
                        />)}
                    </div>
                </div>
            </div>
        </div>
    );
};
const ToggleInput = ({ input, handleChange }) => {
    return (
        <div className="flex items-center mt-4">
            <label className="mr-2">{input.label}:</label>
            <button
    className={`bg-${input.data ? 'green' : 'red'}-500 px-3 py-1 rounded-md text-black`}
    onClick={() => handleChange({ ...input, data: !input.data })}
>
                {input.data ? 'ACTIVE' : 'INACTIVE'}
            </button>
        </div>
    );
};

export default MaterialModal;
