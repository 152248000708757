import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import ProductModal from "./ProductModal";
import MaterialModal from "./MaterialModal";
import ServiceModal from "./ServiceModal";
import OrderModal from "./OrderModal";
import CustomerModal from "./CustomerModal";

import { Input, Button, Confirm, Select } from "../../../components/ui";

import SingleItem from "./SingleItem";
import HeadingRow from "./HeadingRow";
import apiService from "../../../http";
import ReactDOMServer from "react-dom/server";
import InvoicePrint from "./invoicePrint";
import InvoiceHead from "./InvoiceHead";

const inputDataStructure = {
  customerName: {
    key: "customerName",
    label: "Customer Name",
    type: "text",
    error: null,
  },
  chequeNum: {
    key: "chequeNum",
    label: "Cheque Number",
    data: "",
    type: "text",
    error: null,
  },
  bank: {
    key: "bank",
    label: "Bank Name",
    data: "",
    type: "text",
    error: null,
    required: true,
  },

  customerPhone: {
    key: "customerPhone",
    label: "Customer Phone",
    data: "",
    type: "text",
    error: null,
  },
  customerAddress: {
    key: "customerAddress",
    label: "Customer Address",
    data: "",
    type: "text",
    error: null,
  },
  total: {
    key: "total",
    label: "Total",
    readOnly: true,
    min: 0,
    data: 0,
    type: "number",
    error: null,
  },
  advancePayment: {
    key: "advancePayment",
    label: "Paid advance (LKR)",
    readOnly: true,
    min: 0,
    data: 0,
    type: "number",
    error: null,
  },
  netTotal: {
    key: "netTotal",
    label: "Net Total",
    readOnly: true,
    min: 0,
    data: "",
    type: "number",
    error: null,
  },
  paidAmount: {
    key: "paidAmount",
    label: "Paid Amount",
    min: 0,
    data: 0,
    type: "number",
    error: null,
  },
  chequeDate: {
    key: "chequeDate",
    label: "Date Of Cheque",
    type: "date",
    error: null,
    required: true,
  },
  chequeRecievedDate: {
    key: "chequeRecievedDate",
    label: "Cheque Received Date",
    type: "date",
    error: null,
    required: true,
  },
  chequeRef: {
    key: "chequeRef",
    label: "Cheque Reference",
    data: "",
    type: "text",
    error: null,
  },
  paymentMethod: {
    key: "paymentMethod",
    label: "Payment Method",
    type: "select",
    optList: [],
    error: null,
    required: true,
  },
};

const Projects = () => {
  const [items, setItems] = useState([]);

  const [productModalState, setProductModalState] = useState(false);
  const [materialModalState, setMaterialModalState] = useState(false);
  const [serviceModalState, setServiceModalState] = useState(false);
  const [orderModalState, setOrderModalState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customerNames, setCustomerNames] = useState([]);
  const [customerData, setCustomerData] = useState([]);

  const [confirmModalState, setConfirmModalState] = useState(false);
  const [addconfirmModalState, setAddConfirmModalState] = useState(false);
  const [customerModal, setCustomerModal] = useState(false);
  const [inputValues, setInputValues] = useState(inputDataStructure);

  const [inputs, setInputs] = useState(inputDataStructure);
  const [taxOption, setTaxOption] = useState(false);
  const [taxOptionView, setTaxOptionView] = useState(true);

  const handleTaxOptionChange = () => {
    setTaxOption(!taxOption);
  };
  const handleCloseModal = () => {
    setProductModalState(false);
    setMaterialModalState(false);
    setServiceModalState(false);
    setOrderModalState(false);
    setConfirmModalState(false);
    setAddConfirmModalState(false);
    setCustomerModal(false);
    reloadCustomerData();
  };

  const handleAddCloseModal = () => {
    setAddConfirmModalState(false);
    setCustomerModal(false);
    setConfirmModalState(true);
  };
  const handleOpenSellBtn = () => {
    setAddConfirmModalState(false);
    setConfirmModalState(true);
  };
  const clearData = () => {
    setInputs(inputDataStructure);
    inputs.customerName.data = "";
    inputs.customerPhone.data = "";
    inputs.customerAddress.data = "";
    inputs.chequeRef.data = "";
    inputs.paidAmount.data = 0;
    inputs.netTotal.data = "";
    inputs.total.data = "";
    inputs.chequeNum.data = "";
    inputs.bank.data = "";
    inputs.paymentMethod.data = null;
    inputs.advancePayment.data = 0;
    setTaxOption(false);

    setItems([]);
  };

  const openAddCustomer = async () => {
    setCustomerModal(true);
  };

  const fetchCustomers = async () => {
    try {
      const customers = await apiService.get(`customer`);
      const customerNames = customers.map((customer) => customer.title);
      const customerData = customers.map((customer) => ({
        id: customer.id,
        title: customer.title,
      }));

      setCustomerNames(customerNames);
      setCustomerData(customerData);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  const reloadCustomerData = async () => {
    await fetchCustomers();
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const handleChange = async (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    if (input.key === "customerName") {
      const selectedCustomer = customerData.find(
        (customer) => customer.title === input.data
      );

      if (selectedCustomer) {
        try {
          const customerDetails = await apiService.get(
            `customer/${selectedCustomer.id}`
          );
          input_list.customerPhone.data = customerDetails.numberMob;
          input_list.customerAddress.data = customerDetails.address;
        } catch (error) {
          console.error("Error fetching customer details:", error);
        }
      } else {
        input_list.customerPhone.data = "";
        input_list.customerAddress.data = "";
      }
    }

    setInputs(input_list);

    if (input.key === "discount") {
      calcTotal();
    }
  };

  const calcTotal = () => {
    let total = 0;
    let netTotal = 0;

    items.forEach((item) => {
      if (item.types === "material") {
        total +=
          item.fullquantity * item.price + item.offCutUsage * item.offcutprice;
        netTotal +=
          (item.price - (item.price * item.discount) / 100) *
            item.fullquantity +
          item.offCutUsage * item.offcutprice;
      } else if (item.type === "order") {
        let advpay = item.advancePayment || 0;
        console.log(advpay);
        total += item.quantity * item.price;
        netTotal +=
          (item.price - (item.price * item.discount) / 100) * item.quantity -
          advpay;
      } else if (item.type === "material") {
        total += item.quantity * item.price;
        netTotal +=
          (item.price - (item.price * item.discount) / 100) * item.quantity;
      } else if (item.type === "service") {
        total += item.quantity * item.price;
        netTotal +=
          (item.price - (item.price * item.discount) / 100) * item.quantity;
      }
    });
    let input_list = { ...inputs };

    input_list.total.data = parseFloat(total);
    input_list.netTotal.data = parseFloat(netTotal);
    setInputs(input_list);
  };

  const handleAddOrder = async (orderId) => {
    const order = await apiService.get(`order/${orderId}`);

    if (!order.productsAvailable) {
      toast.error("Items are not available in the stock!");
      return;
    }
    const advancePay = order.advancePayment;
    order.products.forEach((product) => {
      handleAddItem(
        {
          id: product.id,
          orderId: orderId,
          title: product.title,
          productId: product.productId,
          quantity: product.quantity,
          price: product.unitPrice,
          discount: product.maxDiscount,
          type: "order",
          advancePayment: advancePay,
        },
        false
      );
    });

    let input_list = { ...inputs };
    const paymentMethodOptions = [
      { id: "cash", title: "Cash" },
      { id: "card", title: "Card" },
      { id: "cheque", title: "Cheque" },
      { id: "credit", title: "Credit" },
    ];
    inputs.paymentMethod.optList = paymentMethodOptions;
    inputs.customerName.data = order.customerName;
    inputs.customerPhone.data = order.customerPhone;
    inputs.customerAddress.data = order.customerAddress;
    inputs.advancePayment.data += order.advancePayment;

    setInputs(input_list);

    toast.success("Items are added to the sale");
    if (order.taxOption === "tax") {
      setTaxOptionView(false);
      // handleTaxOptionChange()
    }
    handleCloseModal();
    // calcTotal();
  };
  const handleAddItem = (item, notify = true) => {
    let itemList = items;
    item.posId = uuidv4();
    items.push(item);
    const paymentMethodOptions = [
      { id: "cash", title: "Cash" },
      { id: "card", title: "Card" },
      { id: "cheque", title: "Cheque" },
      { id: "credit", title: "Credit" },
    ];
    inputs.paymentMethod.optList = paymentMethodOptions;
    setItems(itemList);

    if (notify) {
      toast.success("Item added to the sale");
    }
    handleCloseModal();
    calcTotal();
  };
  const handleRemove = (id) => {
    let itemList = items;

    itemList = itemList.filter((item) => {
      return item.posId !== id;
    });

    setItems(itemList);
    toast.success("Item removed from the sale");
    handleCloseModal();

    calcTotal();
  };
  const generateNewInvoiceId = (invoices) => {
    const currentYear = new Date().getFullYear();

    if (invoices.length === 0) {
      return `NS/INV/${currentYear}/1`;
    }

    const lastInvoice = invoices[0];
    const invID = lastInvoice.invID;

    // Debugging output
    console.log("Last Invoice:", lastInvoice);
    console.log("Last Invoice ID:", invID);

    // Check if invID exists and is a string
    if (!invID || typeof invID !== "string") {
      console.error("Invalid or missing invoice ID:", invID);
      return `NS/INV/${currentYear}/1`; // Fallback to a new ID if invID is invalid
    }

    const lastInvoiceIdParts = invID.split("/");

    // Validate the format of the last invoice ID
    if (
      lastInvoiceIdParts.length !== 4 ||
      lastInvoiceIdParts[0] !== "NS" ||
      lastInvoiceIdParts[1] !== "INV"
    ) {
      console.error("Invalid invoice ID format:", invID);
      return `NS/INV/${currentYear}/1`; // Fallback to a new ID if format is invalid
    }

    const lastInvoiceYear = parseInt(lastInvoiceIdParts[2], 10);
    if (lastInvoiceYear !== currentYear) {
      return `NS/INV/${currentYear}/1`;
    }

    const lastInvoiceNumber = parseInt(lastInvoiceIdParts[3], 10);
    if (isNaN(lastInvoiceNumber)) {
      console.error("Invalid invoice number:", invID);
      return `NS/INV/${currentYear}/1`; // Fallback to a new ID if number is invalid
    }

    const newInvoiceNumber = lastInvoiceNumber + 1;
    return `NS/INV/${currentYear}/${newInvoiceNumber}`;
  };

  const handlePrint = async (
    tableContent,
    customerName,
    customerPhone,
    paymentMethod,
    netTotal,
    chequeNum,
    bank,
    paidAmount,
    chequeReceivedDate,
    chequeDate,
    chequeRef,
    taxOption
  ) => {
    const invoices = await apiService.get("sale");
    const newInvoiceId = generateNewInvoiceId(invoices);
    const printContent = ReactDOMServer.renderToString(tableContent);
    const printWindow = window.open("", "Print", "width=800,height=600");
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US"); // Format date as MM/DD/YYYY
    const formattedTime = currentDate.toLocaleTimeString("en-US"); // Format time as HH:MM AM/PM

    const taxAmount = taxOption ? netTotal * 0.18 : 0;
    const grandTotal = netTotal + taxAmount;

    let chequeDetails = "";
    if (paymentMethod === "cheque") {
      chequeDetails = `
                  Cheque Number: ${chequeNum}<br>
                  Bank: ${bank}<br>
                  Cheque Received Date: ${chequeReceivedDate}<br>
                  Cheque Date: ${chequeDate}<br>
              `;
    }

    printWindow.document.write(
      `<html><head><title>Invoice</title>
          <style>
          body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
            font-size: 12px;
            color: #000;
        }
        
        .container {
            width: 95%;
            margin: 0 auto;
            padding: 20px;
            border: 1px solid #000;
            box-sizing: border-box; /* Ensures the padding and border are included in the width */
        }
        
        .header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
        }
        
        .header-left {
            width: 70%;
            font-weight: bold;
            line-height: 1.5;
        }
        
        .header-right {
            width: 25%;
            text-align: right;
        }
        
        .header-right img {
            max-width: 100px;
        }
        
        .invoice-title {
            text-align: center;
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 20px;
        }
        
        .invoice-details {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
        }
        
        .invoice-details div {
            width: 30%;
        }
        
        .invoice-details div p {
            margin: 5px 0;
        }
        
        table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 20px;
        }
        
        table, th, td {
            border: 1px solid black;
            padding: 8px;
        }
        
        th {
            background-color: #f2f2f2;
            text-align: center;
        }
        
        .totals {
            text-align: right;
            margin-bottom: 20px;
        }
        
        .totals div {
            margin: 5px 0;
        }
        
        .footer {
            text-align: center;
            font-size: 10px;
            margin-top: 20px;
        }
        
        .signature {
            margin-top: 40px;
            text-align: right;
        }
        
        .signature p {
            margin: 0;
        }
        
          </style>
          </head><body>
          
          <div class="container">
              <div class="header">
                  <div class="header-left">
                      Nsteel Engineering<br>
                      විලකොළදෙනිය, මලංගම, රත්නපුර<br>
                      Tel: 045-2121750, 074-0327850
                  </div>
                  <div class="header-right">
                      <img src="/logo.png" alt="Logo">
                  </div>
              </div>
              
              <div class="invoice-title">INVOICE</div>
              
              <div class="invoice-details">
                  <div>
                      <p><strong>Invoice Number:</strong> ${newInvoiceId}</p>
                      <p><strong>Invoice Date:</strong> ${formattedDate}</p>
                      <p><strong>Invoice Time:</strong> ${formattedTime}</p>
                  </div>
                  <div>
                      <p><strong>Invoiced By:</strong> Nsteel Engineering</p>
                      <p><strong>Customer Name:</strong> ${customerName}</p>
                      <p><strong>Customer Phone:</strong> ${customerPhone}</p>
                  </div>
              </div>
              
              <table>
                  <tbody>
                      ${printContent}
                  </tbody>
              </table>
              
              <div class="totals">
                  <div><strong>Total Amount:</strong> ${netTotal.toFixed(
                    2
                  )}</div>
                  ${
                    taxOption
                      ? `<div><strong>Tax Amount:</strong> ${taxAmount.toFixed(
                          2
                        )}</div>`
                      : ""
                  }
                  ${
                    taxOption
                      ? `<div><strong>Grand Total:</strong> ${grandTotal.toFixed(
                          2
                        )}</div>`
                      : ""
                  }
              </div>
              
              <div class="footer">
                  * 30 days credit only *<br>
                  Cheques to be written in favor of Nsteel Engineering & CROSSED AS A/C PAYEE ONLY<br>
                  ${netTotal.toFixed(2)} ONLY
              </div>
              
              <div class="signature">
                  <p>Authorized Signature: _______________________</p>
              </div>
          </div>
          
          <script>
              window.onload = function() { 
                  window.print(); 
                  window.onafterprint = function() { window.close(); }; 
              };
          </script>
          </body></html>`
    );

    printWindow.document.close();
    submitSale();
  };

  const submitSale = async () => {
    let paidAmount = +inputs.paidAmount.data;
    let advancePayment = +inputs.advancePayment.data;

    let newpaidAmount = paidAmount;

    if (advancePayment > 0) {
      newpaidAmount += advancePayment;
    }
    const formData = {
      items,
      customerName: inputs.customerName.data,
      customerPhone: inputs.customerPhone.data,
      customerAddress: inputs.customerAddress.data,
      paymentMethod: inputs.paymentMethod.data,
      chequeNum: inputs.chequeNum.data,
      bank: inputs.bank.data,
      paidAmount: inputs.paidAmount.data,
      chequeRecievedDate: inputs.chequeRecievedDate.data,
      chequeDate: inputs.chequeDate.data,
      chequeRef: inputs.chequeRef.data,
      taxOption: taxOption ? "tax" : "",
      natTotal: inputs.netTotal.data,
      total: inputs.total.data,
    };

    try {
      setIsLoading(true);
      const selectedCustomer = customerData.find(
        (customer) => customer.title === inputs.customerName.data
      );
      if (selectedCustomer) {
        formData.customerId = selectedCustomer.id;
        await apiService.post("sale", formData);
        toast.success("Order placed successfully!");
        setItems([]);
        clearData();
        setIsLoading(false);
        setInputs(inputDataStructure);
      } else {
        await apiService.post("sale", formData);
        toast.success("Order placed successfully!");
        setItems([]);
        clearData();
        setIsLoading(false);
        setInputs(inputDataStructure);
      }
    } catch (e) {
      toast.error(e);
      setIsLoading(false);
    }

    handleCloseModal();
  };

  return (
    <section className="w-full ">
      {productModalState && (
        <ProductModal
          handleAdd={handleAddItem}
          handleClose={handleCloseModal}
        />
      )}
      {materialModalState && (
        <MaterialModal
          handleAdd={handleAddItem}
          handleClose={handleCloseModal}
        />
      )}
      {serviceModalState && (
        <ServiceModal
          handleAdd={handleAddItem}
          handleClose={handleCloseModal}
        />
      )}
      {orderModalState && (
        <OrderModal handleAdd={handleAddOrder} handleClose={handleCloseModal} />
      )}
      {isLoading
        ? "Wait..."
        : confirmModalState && (
            <Confirm
              actionLabel="PRINT"
              title="Place order"
              confirmMsg="Are you sure you want to place the order?"
              cancelHandler={handleCloseModal}
              confirmHandler={() =>
                handlePrint(
                  <table>
                    <InvoiceHead />
                    {items.map((item, index) => (
                      <InvoicePrint
                        item={item}
                        number={index + 1}
                        key={item.posId}
                        handleRemove={handleRemove}
                      />
                    ))}
                  </table>,
                  inputs.customerName.data,
                  inputs.customerPhone.data,
                  inputs.paymentMethod.data,
                  inputs.netTotal.data,
                  inputs.chequeNum.data,
                  inputs.bank.data,
                  inputs.paidAmount.data,
                  inputs.chequeRecievedDate.data,
                  inputs.chequeDate.data,
                  inputs.chequeRef.data,
                  taxOption ? "tax" : ""
                )
              }
              printHandler={submitSale}
            />
          )}
      {isLoading
        ? "Wait..."
        : addconfirmModalState && (
            <Confirm
              actionLabel="Yes"
              title="Customer Save"
              confirmMsg="Customer is Not saved. Do you want to save?"
              cancelHandler={handleOpenSellBtn}
              confirmHandler={openAddCustomer}
            />
          )}
      {isLoading
        ? "Wait..."
        : customerModal && (
            <CustomerModal
              handleClose={handleCloseModal}
              inputValues={inputValues} // Pass the input values as a prop
              sellAlert={handleAddCloseModal}
            />
          )}

      <div className="flex w-full">
        <div className="w-[80%]">
          <div className="flex gap-3 px-2 py-2 mb-3 bg-white rounded-md">
            <Button
              text={"ADD PRODUCT"}
              handleClick={() => setProductModalState(true)}
            />
            <Button
              text={"ADD MATERIAL"}
              handleClick={() => setMaterialModalState(true)}
            />
            <Button
              text={"ADD SERVICE"}
              handleClick={() => setServiceModalState(true)}
            />
            <Button
              text={"ADD ORDER"}
              handleClick={() => setOrderModalState(true)}
            />
          </div>
          <div>
            <div style={{ height: "50dvh", overflowY: "auto" }}>
              <table className="w-[100%] mx-auto">
                <HeadingRow />
                {items.map((item, index) => (
                  <SingleItem
                    item={item}
                    number={index + 1}
                    key={item.posId}
                    handleRemove={handleRemove}
                  />
                ))}
              </table>
            </div>
            <div className="flex gap-3 px-2 py-1 mb-1 bg-white rounded-md">
              {!["credit"].includes(inputs.paymentMethod.data) && (
                <Input handleChange={handleChange} input={inputs.paidAmount} />
              )}

              <Select
                input={inputs.paymentMethod}
                handleChange={handleChange}
              />
              {["cheque"].includes(inputs.paymentMethod.data) && (
                <Input
                  input={inputs.chequeRecievedDate}
                  handleChange={handleChange}
                />
              )}
              {["cheque"].includes(inputs.paymentMethod.data) && (
                <Input input={inputs.chequeDate} handleChange={handleChange} />
              )}
            </div>
            <div className="flex gap-3 px-2 py-1 mb-1 bg-white rounded-md">
              {["cheque"].includes(inputs.paymentMethod.data) && (
                <Input
                  input={{
                    ...inputs.bank,
                    list: "bank",
                  }}
                  handleChange={handleChange}
                />
              )}
              {["cheque"].includes(inputs.paymentMethod.data) && (
                <Input input={inputs.chequeNum} handleChange={handleChange} />
              )}
              {["cheque"].includes(inputs.paymentMethod.data) && (
                <Input input={inputs.chequeRef} handleChange={handleChange} />
              )}
              {taxOptionView && ( // Conditionally render the tax option checkbox
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    checked={taxOption}
                    onChange={handleTaxOptionChange}
                  />
                  <span className="ml-2">Tax</span>
                </label>
              )}
            </div>
          </div>
        </div>
        <div className="w-[20%] mr-6">
          <Input
            handleChange={(input) => {
              const updatedInputs = { ...inputValues };
              updatedInputs[input.key].data = input.data;
              setInputValues(updatedInputs); // Update the state with all input values
              handleChange(input);
            }}
            input={{
              ...inputs.customerName,
              list: "data",
            }}
          />

          <datalist id="data">
            {customerNames.map((name, index) => (
              <option key={index} value={name} />
            ))}
          </datalist>

          <datalist id="bank">
            <option key={"Sampath Bank"} value={"Sampath Bank"} />
            <option key={"Bank Of Ceylon Bank"} value={"Bank Of Ceylon Bank"} />
            <option
              key={"National Development Bank"}
              value={"National Development Bank"}
            />
            <option key={"DFCC Bank"} value={"DFCC Bank"} />
            <option
              key={"Hatton National Bank"}
              value={"Hatton National Bank"}
            />

            <option key={"Amana Bank"} value={"Amana Bank"} />
            <option key={"Pan Asia Bank"} value={"Pan Asia Bank"} />
            <option key={"Nations Trust Bank"} value={"Nations Trust Bank"} />
            <option
              key={"National Savings bank"}
              value={"National Savings bank"}
            />
            <option key={"Commercial Bank"} value={"Commercial Bank"} />

            <option key={"Seylan Bank"} value={"Seylan Bank"} />
            <option key={"Hsbc"} value={"Hsbc"} />
            <option key={"People's Bank"} value={"People's Bank"} />
            <option key={"Cargills Bank"} value={"Cargills Bank"} />
          </datalist>
          <Input
            handleChange={(input) => {
              const updatedInputs = { ...inputValues };
              updatedInputs[input.key].data = input.data;
              setInputValues(updatedInputs); // Update the state with all input values
              handleChange(input);
            }}
            input={{
              ...inputs.customerPhone,
              placeholder: "Mobile No. (Without leading zero)",
            }}
          />
          <Input
            handleChange={(input) => {
              const updatedInputs = { ...inputValues };
              updatedInputs[input.key].data = input.data;
              setInputValues(updatedInputs); // Update the state with all input values
              handleChange(input);
            }}
            input={inputs.customerAddress}
          />
          <Input handleChange={handleChange} input={inputs.total} />
          <Input handleChange={handleChange} input={inputs.advancePayment} />
          <Input handleChange={handleChange} input={inputs.netTotal} />
          {isLoading ? (
            "Wait..."
          ) : (
            <Button
              handleClick={() => {
                if (!customerNames.includes(inputs.customerName.data)) {
                  setAddConfirmModalState(true);
                } else {
                  setConfirmModalState(true);
                }
              }}
              className={"w-full mt-2"}
              text={"PLACE ORDER"}
            />
          )}
          <Button
            handleClick={clearData}
            className={"w-full mt-2 bg-red-500"}
            text={"CLEAR DATA"}
          />
        </div>
      </div>
    </section>
  );
};

export default Projects;
