import React, { useEffect, useState } from "react";
import { Input, Button ,Select } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";

const TypeModal = ({ handleClose, selectedMaterial }) => {
    const inputDataStructure = {
        expences: {
            key: "expences",
            label: "Name",
            data: "",
            required: true,
            type: "text",
            error: null, 
        },
        type: {
            key: "type",
            label: "Type",
            data: null,
            required: true,
            optTitle: "a type",
            optList: [{ id: "direct", title: "Direct" },
            { id: "indirect", title: "Indirect" },],
            type: "text",
            error: null,

        },
    };
    
    const [inputs, setInputs] = useState(inputDataStructure);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (input) => {
        let input_list = { ...inputs };
        input_list[input.key] = input;
        
        setInputs(input_list);
    };

    const buildForm = async () => {
        if (selectedMaterial) {
            let input_list = { ...inputs };
            input_list.machine.data = selectedMaterial.machine;
            setInputs(input_list);
        }
    };
    

    useEffect(() => {
        buildForm();
    }, []);

    const handleSubmit = async () => {
        const event = window.event;
        event.preventDefault();

        const formData = {
            expences: inputs.expences.data,
            type: inputs.type.data,

        };

        try {
            setIsLoading(true);

            if (selectedMaterial) {
                formData.id = selectedMaterial.id;
                await apiService.patch("exType", formData);
                toast.success("Type updated successfully!");
            } else {
                await apiService.post("exType", formData);
                toast.success("Type added successfully!");
            }

            handleClose();
            setIsLoading(false);

        } catch (e) {
            toast.error(e);
            setIsLoading(false);

        }
    };

    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75 ">
                <div className=" shadow-xl transition-all w-[25%] mx-auto mt-[100px] rounded-lg bg-white">
                    <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
                        <h3 className="py-1 mx-auto">
                            {!selectedMaterial && "ADD NEW "}
                            {selectedMaterial && "EDIT "}
                            EXPENCES TYPE
                        </h3>
                        <span
                            className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
                            onClick={handleClose}
                        >
                            X
                        </span>
                    </div>

                    <div className="px-4 mt-2">
                    <Input
    input={inputs.expences} 
    handleChange={handleChange}
/>
<Select
                            input={inputs.type}
                            handleChange={handleChange}
                        />

                        
                                                {isLoading ? (
                        "Wait..."
                    ) : (
                        <Button
                            handleClick={handleSubmit}
                            className="mt-6"
                            text={selectedMaterial ? "EDIT" : "CREATE"}
                        />)}
                    </div>
                </div>
            </div>
        </div>
    );
};


export default TypeModal;
