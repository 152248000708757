import React, { useEffect, useState, useRef } from "react";
import { Table } from "antd";
import { toast } from "react-toastify";
import { Button, Select } from "../../../../components/ui";
import apiService from "../../../../http";
import PrintReport from "./PrintReport"; // Adjust the import path as needed
import ReactDOMServer from "react-dom/server";

const ProductReport = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchProducts = async () => {
    try {
      setIsLoading(true);
      const response = await apiService.get("product");
      setProducts(response);
      setFilteredProducts(response);
      setIsLoading(false);
    } catch (e) {
      toast.error(e.message);
      setIsLoading(false);
    }
  };

  const fetchDepartments = async () => {
    try {
      const response = await apiService.get("product/department");
      setDepartments(response);
    } catch (e) {
      toast.error(e.message);
    }
  };

  useEffect(() => {
    fetchProducts();
    fetchDepartments();
  }, []);

  useEffect(() => {
    if (selectedDepartment) {
      setFilteredProducts(
        products.filter((product) => product.department === selectedDepartment)
      );
    } else {
      setFilteredProducts(products);
    }
  }, [selectedDepartment, products]);

  const handleDepartmentChange = (input) => {
    setSelectedDepartment(input.data);
  };

  const columns = [
    {
      title: "ID",
      key: "id",
      render: (text, record, index) => (
        <p className="text-center">{index + 1}</p>
      ),
    },
    {
      title: "Product ID",
      key: "productId",
      render: (text, record) => <p className="text-left">{record.productId}</p>,
      align: "left",
      width: "20%",
    },
    {
      title: "Product Name",
      key: "title",
      render: (text, record) => <p className="text-left">{record.title}</p>,
      align: "left",
      width: "40%",
    },
    {
      title: "Stock",
      key: "stock",
      render: (text, record) => <p className="text-center">{record.stock}</p>,
      align: "center",
      width: "20%",
    },
    {
      title: "Unit Price",
      key: "unitPrice",
      align: "right",
      render: (text, record) => {
        const formattedPrice = record.unitPrice
          ? record.unitPrice.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00";
        return <p className="text-right">{formattedPrice}</p>;
      },
      width: "20%",
    },
    {
      title: "Total Price",
      key: "totalPrice",
      align: "right",
      render: (text, record) => {
        const total = (record.stock || 0) * (record.unitPrice || 0);
        const formattedPrice = total.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        return <p className="text-right">{formattedPrice}</p>;
      },
      width: "20%",
    },
  ];

  // Calculate the total sum of all total prices
  const totalSum = filteredProducts.reduce((sum, product) => {
    const unitPrice = product.unitPrice || 0;
    const stock = product.stock || 0;
    return sum + unitPrice * stock;
  }, 0);

  const handlePrint = () => {
    const printContent = ReactDOMServer.renderToString(
      <PrintReport materials={filteredProducts} />
    );
    const printWindow = window.open("", "Print", "width=800,height=600");
    printWindow.document.write(
      `<html><head><title>Product Report</title></head><body>${printContent}</body></html>`
    );
    printWindow.document.write(
      `<script>window.onload = function() { window.print(); window.onafterprint = function() { window.close(); }; };</script>`
    );
    printWindow.document.close();
    printWindow.document.addEventListener("afterprint", function () {
      printWindow.document.close();
    });
  };

  const transformedDepartments = [
    { id: null, title: "All Departments" },
    ...departments.map((dept) => ({ id: dept.id, title: dept.department })),
  ];

  return (
    <section className="w-full">
      <div className="p-6 mx-3 my-1 border-2 rounded-md">
        <p className="text-lg font-bold">PRODUCT REPORT</p>
        <div className="w-[200px] mr-3">
          <Select
            input={{
              label: "Select Department",
              data: selectedDepartment,
              optList: transformedDepartments,
              optTitle: "Department",
              placeholder: "Select Department",
            }}
            handleChange={handleDepartmentChange}
            inputClassName="w-[200px]"
            labelClassName="ml-3"
            clear={false}
          />

          <Button
            text="PRINT"
            className="ml-3 hover:bg-primary hover:text-white px-[14px]"
            handleClick={handlePrint}
          />
        </div>
      </div>
      <div id="printableArea" className="p-6 mx-3 my-1 border-2 rounded-md">
        <Table
          columns={columns}
          dataSource={filteredProducts}
          style={{ width: "100%" }}
          pagination={false}
          loading={isLoading}
        />
        <div style={{ textAlign: "right", fontWeight: "bold" }}>
          <p>
            Total:{" "}
            {totalSum.toLocaleString("en-US", { minimumFractionDigits: 2 })}
          </p>
        </div>
      </div>
    </section>
  );
};

export default ProductReport;
