import React, { useEffect, useState } from "react";
import { Table } from "antd";
import apiService from "../../../http";

const ProductModal = ({ handleClose, selectedOrder }) => {
    const [products, setProducts] = useState([]);

    const columns = [
        {
            title: "DATE",
            width: "5%",
            dataIndex: "createdAt",
            render: (createdAt) => {
                const date = new Date(createdAt);
                return date.toLocaleDateString(); 
            },
        },
        {
            title: "GRN ID",
            dataIndex: "grnId",
            key: "grnId",
            align: "center",
            width: "20%",
        },
        {
            title: "QUANTITY",
            align: "center",
            width: "20%",
            render: (item) => item.grnMeterial[0].stock.full, 
        },
        {
            title: "SUPPLYER",
            align: "center",
            width: "20%",
            render: (item) => item.grnMeterial[0].supplier,
        },
        {
            title: "MATERIAL NAME",
            align: "center",
            width: "20%",
            render: (item) => item.grnMeterial[0].title,

        },
        {
            title: "COST",
            align: "center",
            width: "40%",
            render: (item) =>'LKR.'+ item.grnMeterial[0].stock.full*item.grnMeterial[0].cost, 
        },
    ];
    
    const getData = async () => {
        try {
            const orderData = await apiService.get(`material/getGrn`);

            const flattenedProducts = orderData.map(item => ({
                ...item,
                grnMeterial: item.grnMeterial.map(material => ({
                    ...material,
                    ...material.stock
                }))
            }));
            setProducts(flattenedProducts);
        } catch (e) {
            console.log(e);
        }
    };
    

    useEffect(() => {
        getData();
    }, []);

    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75 ">
                <div className=" shadow-xl transition-all w-[70%] mx-auto mt-[100px] rounded-lg bg-white">
                    <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
                        <h3 className="py-1 mx-auto">GRN REPORT </h3>
                        <span
                            className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
                            onClick={handleClose}
                        >
                            X
                        </span>
                    </div>
                    <div className="mx-4 mt-2">
                        <div className="w-full">
                            <Table
                                pagination={{
                                    defaultPageSize: 6,
                                }}
                                columns={columns}
                                dataSource={products}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductModal;
