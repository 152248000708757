import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Button, Select } from "../../../components/ui";
import apiService from "../../../http";

const ServiceModal = ({ handleClose, handleAdd }) => {
    const inputDataStructure = {
        order: {
            key: "order",
            label: "Order",
            required: true,
            optTitle: "an order",
            data: null,
            optList: [],
            error: null,
        },
    };

    const [inputs, setInputs] = useState(inputDataStructure);

    const handleChange = (input) => {
        let input_list = { ...inputs };
        input_list[input.key] = input;
        setInputs(input_list);
    };

    const buildForm = async () => {
        let input_list = { ...inputs };

        let orders = await apiService.get("order");

        let optList = [];

        orders.forEach((order) => {
            if (order.status === "complete") {
                optList.push({
                    id: order.id,
                    title: order.orderId,
                });
            }
        });

        input_list.order.optList = optList;

        setInputs(input_list);
    };

    useEffect(() => {
        buildForm();
    }, []);

    const handleSubmit = async () => {
        const event = window.event;
        event.preventDefault();

        if (!inputs.order.data) {
            toast.error("Please select an order!");
            return;
        }

        handleAdd(inputs.order.data);
    };

    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75 ">
                <div className=" shadow-xl transition-all w-[25%] mx-auto mt-[100px] rounded-lg bg-white">
                    <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
                        <h3 className="py-1 mx-auto">ADD ORDER</h3>
                        <span
                            className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
                            onClick={handleClose}
                        >
                            X
                        </span>
                    </div>

                    <div className="px-4 mt-2">
                        <Select
                            input={inputs.order}
                            handleChange={handleChange}
                        />

                        <Button
                            handleClick={handleSubmit}
                            className="mt-6"
                            text={"ADD"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceModal;
