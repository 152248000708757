import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { toast } from "react-toastify";
import { Button, Select } from "../../../../components/ui";
import apiService from "../../../../http";
import PrintReport from "./PrintReport";
import ReactDOMServer from "react-dom/server";

const Expenses = () => {
  const [expenses, setExpenses] = useState([]);
  const [filteredExpenses, setFilteredExpenses] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [searchOrdId, setSearchOrdId] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    refreshExpenses();
  }, []);

  useEffect(() => {
    filterExpenses();
  }, [selectedType, searchOrdId, fromDate, toDate]);

  const refreshExpenses = async () => {
    try {
      const expenses = await apiService.get("expence");
      const parsedExpenses = expenses.map((expense) => ({
        ...expense,
        createdAt: new Date(expense.createdAt),
        amount: parseInt(expense.amount, 10), // Convert amount to integer
      }));
      setExpenses(parsedExpenses);
      setFilteredExpenses(parsedExpenses);
    } catch (e) {
      toast.error(e.message);
    }
  };

  const filterExpenses = () => {
    let filtered = expenses;

    if (selectedType) {
      filtered = filtered.filter(
        (expense) => expense.expencesType === selectedType
      );
    }

    if (searchOrdId) {
      filtered = filtered.filter(
        (expense) =>
          expense.invNumber &&
          expense.invNumber.toLowerCase().includes(searchOrdId.toLowerCase())
      );
    }

    if (fromDate) {
      const from = new Date(fromDate);
      from.setHours(0, 0, 0, 0);
      filtered = filtered.filter((expense) => {
        const expencesDate = new Date(expense.createdAt);
        expencesDate.setHours(0, 0, 0, 0);
        return expencesDate >= from;
      });
    }

    if (toDate) {
      const to = new Date(toDate);
      to.setHours(23, 59, 59, 999);
      filtered = filtered.filter((expense) => {
        const expencesDate = new Date(expense.createdAt);
        expencesDate.setHours(0, 0, 0, 0);
        return expencesDate <= to;
      });
    }
    setFilteredExpenses(filtered);
  };

  const uniqueTypes = [
    ...new Set(expenses.map((expense) => expense.expencesType)),
  ].map((type) => ({
    id: type,
    title: type,
  }));

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => (
        <p className="text-center">{new Date(date).toLocaleDateString()}</p>
      ),
      align: "center",
      width: "15%", // Adjust the percentage as needed
    },
    {
      title: "Expense ID",
      dataIndex: "expId",
      key: "expId",
      render: (text) => <p className="text-left">{text}</p>,
      align: "left",
      width: "15%", // Adjust the percentage as needed
    },
    {
      title: "Expense",
      dataIndex: "expencesType",
      key: "expencesType",
      render: (text) => <p className="text-left">{text}</p>,
      align: "left",
      width: "25%", // Adjust the percentage as needed
    },
    {
      title: "Invoice Number",
      dataIndex: "invNumber",
      key: "invNumber",
      render: (text) => <p className="text-center">{text}</p>,
      align: "center",
      width: "15%", // Adjust the percentage as needed
    },
    {
      title: "Invoice Buyer",
      dataIndex: "invBuyer",
      key: "invBuyer",
      render: (text) => <p className="text-center">{text}</p>,
      align: "center",
      width: "15%", // Adjust the percentage as needed
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => {
        return text.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
      align: "right",
      width: "15%", // Adjust the percentage as needed
    },
  ];

  const handlePrint = () => {
    const printContent = ReactDOMServer.renderToString(
      <PrintReport expenses={filteredExpenses} />
    );
    const printWindow = window.open("", "Print", "width=800,height=600");
    printWindow.document.write(
      `<html><head><title>Expense Report</title></head><body>${printContent}</body></html>`
    );
    printWindow.document.write(
      `<script>window.onload = function() { window.print(); window.onafterprint = function() { window.close(); }; };</script>`
    );
    printWindow.document.close();
    printWindow.document.addEventListener("afterprint", function () {
      printWindow.document.close();
    });
  };

  return (
    <section className="w-full">
      <div className="p-6 mx-3 my-1 border-2 rounded-md">
        <p className="text-lg font-bold">EXPENSES REPORTS</p>
        <div className="flex mb-4 space-x-3">
          <Select
            input={{
              label: "Filter By Type",
              data: selectedType,
              optList: uniqueTypes,
              optTitle: "Type",
            }}
            handleChange={(input) => setSelectedType(input.data)}
            inputClassName="w-[200px]"
            labelClassName=""
          />

          <div className="w-[200px]">
            <label className="block mb-2 text-sm font-medium text-gray-700">
              Search by Invoice number
            </label>
            <input
              type="text"
              value={searchOrdId}
              placeholder="Enter Order ID"
              onChange={(e) => setSearchOrdId(e.target.value)}
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </div>

          <div className="w-[200px]">
            <label className="block mb-2 text-sm font-medium text-gray-700">
              From Date
            </label>
            <input
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </div>

          <div className="w-[200px]">
            <label className="block mb-2 text-sm font-medium text-gray-700">
              To Date
            </label>
            <input
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </div>
        </div>
        <Button
          text="CLEAR FILTERS"
          className="ml-3 hover:bg-primary hover:text-white px-[14px]"
          handleClick={() => {
            setSelectedType(null);
            setSearchOrdId("");
            setFromDate("");
            setToDate("");
            setFilteredExpenses(expenses);
          }}
        />
        {isLoading ? (
          "Loading..."
        ) : (
          <Button
            text="PRINT"
            className="ml-3 hover:bg-primary hover:text-white px-[14px]"
            handleClick={handlePrint}
          />
        )}
      </div>
      <div id="printableArea" className="p-6 mx-3 my-1 border-2 rounded-md">
        <Table
          columns={columns}
          dataSource={filteredExpenses}
          rowKey="id"
          style={{ width: "100%" }}
          footer={() => (
            <p className="font-bold text-right">
              Total:{" "}
              {filteredExpenses
                .reduce((acc, expense) => acc + expense.amount, 0)
                .toLocaleString("en-US", {
                  style: "currency",
                  currency: "LKR",
                })}
            </p>
          )}
        />
      </div>
    </section>
  );
};

export default Expenses;
