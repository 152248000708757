import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Logout, Login, Dashboard, Register } from "../pages";
import { userLoggedOut } from "../redux/actions/userActions";

const Router = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const PrivateRouter = () => {
    if (user.authenticated) {
      const currentTime = Date.now();
      const expTime = user.data.exp * 1000;

      if (expTime < currentTime) {
        dispatch(userLoggedOut());

        return <Navigate to="/login" />;
      }

      return <Outlet />;
    } else {
      return <Navigate to="/login" />;
    }
  };

  const PreAuthRouter = () => {
    if (user.authenticated) {
      return <Navigate to="/" />;
    } else {
      return <Outlet />;
    }
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PreAuthRouter />}>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Register />} />{" "}
          {/* Add Signup Route */}
        </Route>

        <Route element={<PrivateRouter />}>
          <Route path="/" element={<Dashboard section={"home"} />} />

          <Route
            path="/materials"
            element={<Dashboard section={"materials"} />}
          />
          <Route
            path="/products"
            element={<Dashboard section={"products"} />}
          />
          <Route path="/jobs" element={<Dashboard section={"jobs"} />} />
          <Route path="/orders" element={<Dashboard section={"orders"} />} />
          <Route
            path="/services"
            element={<Dashboard section={"services"} />}
          />
          <Route path="/labours" element={<Dashboard section={"labours"} />} />
          <Route path="/costing" element={<Dashboard section={"costing"} />} />
          <Route path="/pos" element={<Dashboard section={"pos"} />} />
          <Route path="/sales" element={<Dashboard section={"sales"} />} />
          <Route path="/reports" element={<Dashboard section={"reports"} />} />
          <Route
            path="/settings"
            element={<Dashboard section={"settings"} />}
          />
          <Route
            path="/supplier"
            element={<Dashboard section={"supplier"} />}
          />
          <Route
            path="/expenses"
            element={<Dashboard section={"expenses"} />}
          />
          <Route path="/reports" element={<Dashboard section={"reports"} />} />
          <Route
            path="/customers"
            element={<Dashboard section={"customer"} />}
          />
          {/* Reports paths */}
          <Route
            path="/reports/ordersReport"
            element={<Dashboard section={"ordersReport"} />}
          />
          <Route
            path="/reports/materialReport"
            element={<Dashboard section={"materialReport"} />}
          />
          <Route
            path="/reports/expencesReport"
            element={<Dashboard section={"expencesReport"} />}
          />
          <Route
            path="/reports/salesReport"
            element={<Dashboard section={"salesReport"} />}
          />
          <Route
            path="/reports/cost"
            element={<Dashboard section={"cost"} />}
          />
          <Route
            path="/reports/costOrder"
            element={<Dashboard section={"costOrder"} />}
          />
          <Route
            path="/reports/customerReport"
            element={<Dashboard section={"customerReport"} />}
          />
          <Route
            path="/reports/productsReport"
            element={<Dashboard section={"productsReport"} />}
          />
          <Route
            path="/reports/orderCost"
            element={<Dashboard section={"orderCost"} />}
          />
          <Route
            path="/reports/cusromerOuts"
            element={<Dashboard section={"cusromerOuts"} />}
          />
          <Route
            path="/reports/customerAll"
            element={<Dashboard section={"customerAll"} />}
          />
          <Route
            path="/reports/customerOut"
            element={<Dashboard section={"customerOut"} />}
          />
          <Route
            path="/reports/supplierGRN"
            element={<Dashboard section={"supplierGRN"} />}
          />
          <Route path="/logout" element={<Logout />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
