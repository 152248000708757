import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Input, Button, Select } from "../../../components/ui";
import apiService from "../../../http";

const ServiceModal = ({ handleClose, handleAdd }) => {
  const inputDataStructure = {
    service: {
      key: "service",
      label: "Service",
      required: true,
      optTitle: "a service",
      data: null,
      optList: [],
      error: null,
    },
    unit: {
      key: "unit",
      label: "Thickness",
      required: true,
      optTitle: "a unit",
      data: null,
      optList: [],
      error: null,
    },
    price: {
      key: "sellingPrice",
      label: "Unit Price (LKR)",
      required: true,
      min: 0,
      data: 0,
      type: "number",
      error: null,
    },
    discount: {
      key: "discount",
      label: "Discount (%)",
      required: true,
      min: 0,
      data: 0,
      type: "number",
      error: null,
    },
    quantity: {
      key: "quantity",
      label: "Quantity",
      required: true,
      min: 0,
      data: 0,
      type: "number",
      error: null,
    },
  };

  const [inputs, setInputs] = useState(inputDataStructure);
  const [services, setServices] = useState([]);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    if (input.key === "service") {
      const selectedService = services.find(
        (service) => service.id === input.data
      );
      if (selectedService) {
        // Set thickness options based on selected service
        const thicknessOptions = selectedService.unitPrice.map((item) => ({
          id: item.thickness,
          title: item.thickness,
        }));
        input_list.unit.optList = thicknessOptions;

        // Reset price if service is changed
        input_list.price.data = 0;
      } else {
        input_list.unit.optList = [];
      }
    } else if (input.key === "unit") {
      const selectedService = services.find(
        (service) => service.id === inputs.service.data
      );
      if (selectedService) {
        const selectedUnit = selectedService.unitPrice.find(
          (price) => price.thickness === input.data
        );
        if (selectedUnit) {
          input_list.price.data = selectedUnit.price;
        } else {
          input_list.price.data = 0;
        }
      }
    }

    setInputs(input_list);
  };

  const buildForm = async () => {
    try {
      const response = await apiService.get("service");
      const filteredServices = response.filter(
        (service) => service.status === true
      );

      const optList = filteredServices.map((service) => ({
        id: service.id,
        title: service.title,
      }));

      setInputs((prevInputs) => ({
        ...prevInputs,
        service: { ...prevInputs.service, optList },
      }));
      setServices(filteredServices);
    } catch (error) {
      toast.error("Failed to load data.");
    }
  };

  useEffect(() => {
    buildForm();
  }, []);

  const handleSubmit = async () => {
    if (!inputs.service.data) {
      toast.error("Please select a service!");
      return;
    }

    if (inputs.price.data <= 0) {
      toast.error("Unit Price must be greater than 0!");
      return;
    }

    const selectedService = services.find(
      (service) => service.id === inputs.service.data
    );

    if (!selectedService) {
      toast.error("Selected service is not valid.");
      return;
    }

    handleAdd({
      id: selectedService.id,
      title: selectedService.title,
      price: inputs.price.data,
      type: "service",
      quantity: inputs.quantity.data,
      discount: selectedService.maxDiscount ? inputs.discount.data : 0,
    });
    handleClose(); // Close the modal after submission
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75">
        <div className="shadow-xl transition-all w-[25%] mx-auto mt-[100px] rounded-lg bg-white">
          <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
            <h3 className="py-1 mx-auto">ADD SERVICES</h3>
            <span
              className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
              onClick={handleClose}
            >
              X
            </span>
          </div>

          <div className="px-4 mt-2">
            <Select input={inputs.service} handleChange={handleChange} />
            <Select input={inputs.unit} handleChange={handleChange} />
            <Input input={inputs.price} handleChange={handleChange} />
            <Input input={inputs.quantity} handleChange={handleChange} />
            <Button handleClick={handleSubmit} className="mt-6" text="ADD" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceModal;
