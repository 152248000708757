import React from "react";

const InvoiceHead = () => {
  return (
    <thead>
      <tr className="px-3 mx-3 bg-slate-400">
        <th className="text-left">NO.</th>
        <th style={{ width: "50%" }} className="text-left">
          DESCRIPTION
        </th>
        <th className="text-right">QUANTITY</th>
        <th className="text-right">RATE</th>
        <th className="text-right">DISCOUNT</th>
        <th className="text-right">AMOUNT</th>
      </tr>
    </thead>
  );
};

export default InvoiceHead;
