import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const ListItem = ({ item }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const getClassList = (selected) => {
    let classList = "nav-item";
    classList += selected ? " bg-gray-400 text-gray-700" : " text-white";
    return classList;
  };

  const handleLogout = (e) => {
    if (item.url === "/logout") {
      if (!window.confirm("Do you want to log out?")) {
        e.preventDefault();
      }
    }
  };

  const toggleSubItems = (e) => {
    if (item.subItems) {
      e.preventDefault(); // Prevents default link behavior
      setIsExpanded(!isExpanded); // Toggles the submenu
    }
  };

  return (
    <li className="my-2">
      <NavLink
        to={item.subItems ? "#" : item.url}
        onClick={(e) => {
          handleLogout(e);
          toggleSubItems(e); // Toggle submenu visibility
        }}
      >
        <span className={getClassList(item.selected)}>
          {item.icon && !item.subItems && (
            <span className="mr-2">{item.icon}</span> // Render icon only for top-level items
          )}
          <span>{item.title}</span>
          {item.subItems && (
            <span className="ml-auto">{isExpanded ? "▲" : "▼"}</span>
          )}
        </span>
      </NavLink>
      {item.subItems && isExpanded && (
        <ul className="mt-2 ml-4 space-y-2">
          {item.subItems.map((subItem) => (
            <li key={subItem.id}>
              <NavLink to={subItem.url}>
                <span className={getClassList(subItem.selected)}>
                  {subItem.title1}
                </span>
              </NavLink>
              {/* Recursively render nested sub-items */}
              {subItem.subItems && <ListItem item={subItem} />}
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default ListItem;
