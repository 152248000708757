import React, { useEffect, useState } from "react";
import { Input, Button } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";

const MaterialModal = ({ handleClose, selectedProduct }) => {
    const inputDataStructure = {
        unitPrice: {
            key: "unitPrice",
            label: "Unit price (LKR)",
            data: 0,
            min: 1,
            required: true,
            type: "number",
            error: null,
        },
        maxDiscount: {
            key: "maxDiscount",
            label: "Max allowed discount (%)",
            data: 0,
            min: 1,
            required: true,
            type: "number",
            error: null,
        },
    };

    const [inputs, setInputs] = useState(inputDataStructure);

    const handleChange = (input) => {
        let input_list = { ...inputs };
        input_list[input.key] = input;

        setInputs(input_list);
    };

    const buildForm = async () => {
        let input_list = { ...inputs };

        input_list.unitPrice.data = selectedProduct.unitPrice;
        input_list.maxDiscount.data = selectedProduct.maxDiscount;

        setInputs(input_list);
    };

    useEffect(() => {
        buildForm();
    }, []);

    const handleSubmit = async () => {
        const event = window.event;
        event.preventDefault();

        const formData = {
            id: selectedProduct.id,
            unitPrice: inputs.unitPrice.data,
            maxDiscount: inputs.maxDiscount.data,
        };

        try {
            await apiService.patch("product/price", formData);
            toast.success("Prices updated successfully!");

            handleClose();
        } catch (e) {
            toast.error(e);
        }
    };

    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75 ">
                <div className=" shadow-xl transition-all w-[25%] mx-auto mt-[100px] rounded-lg bg-white">
                    <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
                        <h3 className="py-1 mx-auto">Set price</h3>
                        <span
                            className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
                            onClick={handleClose}
                        >
                            X
                        </span>
                    </div>

                    <div className="px-4 mt-2">
                        <Input
                            input={inputs.unitPrice}
                            handleChange={handleChange}
                        />
                        <Input
                            input={inputs.maxDiscount}
                            handleChange={handleChange}
                        />

                        <Button
                            handleClick={handleSubmit}
                            className="w-full mt-6"
                            text={"SUBMIT"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MaterialModal;
