import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { toast } from "react-toastify";
import { Button, Select } from "../../../../components/ui";
import apiService from "../../../../http";
import PrintReport from "./PrintReport"; // Adjust the import path as needed
import ReactDOMServer from "react-dom/server";

const Orders = () => {
  const [sales, setSales] = useState([]);
  const [filteredSales, setFilteredSales] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [searchCustomerName, setSearchCustomerName] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [minTotal, setMinTotal] = useState("");
  const [maxTotal, setMaxTotal] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const refreshSales = async () => {
    try {
      const sales = await apiService.get("sale");
      const parsedSales = sales.map((sale) => ({
        ...sale,
        createdAt: new Date(sale.createdAt),
      }));
      setSales(parsedSales);
      setFilteredSales(parsedSales);
    } catch (e) {
      toast.error(e.message);
    }
  };

  useEffect(() => {
    refreshSales();
  }, []);

  useEffect(() => {
    filterSales();
  }, [
    selectedPaymentMethod,
    searchCustomerName,
    fromDate,
    toDate,
    minTotal,
    maxTotal,
  ]);

  const filterSales = () => {
    let filtered = sales;

    if (selectedPaymentMethod) {
      filtered = filtered.filter(
        (sale) => sale.paymentMethod === selectedPaymentMethod
      );
    }

    if (searchCustomerName) {
      filtered = filtered.filter((sale) =>
        sale.customerName
          .toLowerCase()
          .includes(searchCustomerName.toLowerCase())
      );
    }

    if (fromDate) {
      const from = new Date(fromDate);
      from.setHours(0, 0, 0, 0);
      filtered = filtered.filter((sale) => {
        const saleDate = new Date(sale.createdAt);
        saleDate.setHours(0, 0, 0, 0);
        return saleDate >= from;
      });
    }

    if (toDate) {
      const to = new Date(toDate);
      to.setHours(23, 59, 59, 999);
      filtered = filtered.filter((sale) => {
        const saleDate = new Date(sale.createdAt);
        saleDate.setHours(0, 0, 0, 0);
        return saleDate <= to;
      });
    }
    if (minTotal) {
      filtered = filtered.filter((sale) => sale.total >= parseFloat(minTotal));
    }

    if (maxTotal) {
      filtered = filtered.filter((sale) => sale.total <= parseFloat(maxTotal));
    }

    setFilteredSales(filtered);
  };

  const uniquePaymentMethods = [
    ...new Set(sales.map((sale) => sale.paymentMethod)),
  ].map((paymentMethod) => ({
    id: paymentMethod,
    title: paymentMethod,
  }));

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => (
        <p className="text-center">{new Date(date).toLocaleDateString()}</p>
      ),
      align: "center",
      width: "10%",
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      render: (text) => <p className="text-left">{text}</p>,
      align: "left",
      width: "20%",
    },
    {
      title: "Invoice Number",
      dataIndex: "invID",
      key: "invID",
      render: (text) => <p className="text-left">{text}</p>,
      align: "left",
      width: "15%",
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      render: (text) => <p className="text-center">{text}</p>,
      align: "center",
      width: "15%",
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      render: (text) =>
        Number(text).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      align: "right",
      width: "10%",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text) =>
        Number(text).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      align: "right",
      width: "15%",
    },
  ];

  const handlePrint = () => {
    const printContent = ReactDOMServer.renderToString(
      <PrintReport sales={filteredSales} />
    );
    const printWindow = window.open("", "Print", "width=800,height=600");
    printWindow.document.write(
      `<html><head><title>Sales Report</title></head><body>${printContent}</body></html>`
    );
    printWindow.document.write(
      `<script>window.onload = function() { window.print(); window.onafterprint = function() { window.close(); }; };</script>`
    );
    printWindow.document.close();
    printWindow.document.addEventListener("afterprint", function () {
      printWindow.document.close();
    });
  };

  const totalSales = filteredSales.reduce(
    (acc, sale) => acc + Number(sale.total),
    0
  );

  const calculateTotalByPaymentMethod = (paymentMethod) => {
    return filteredSales
      .filter((sale) => sale.paymentMethod === paymentMethod)
      .reduce((acc, sale) => acc + Number(sale.total), 0)
      .toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
  };

  return (
    <section className="w-full">
      <div className="p-6 mx-3 my-1 border-2 rounded-md">
        <p className="text-lg font-bold">SALES REPORTS</p>
        <div className="flex flex-wrap mb-4 space-x-3">
          <div className="flex flex-wrap w-full">
            <Select
              input={{
                label: "Filter By Payment Method",
                data: selectedPaymentMethod,
                optList: uniquePaymentMethods,
                optTitle: "Payment Method",
              }}
              handleChange={(input) => setSelectedPaymentMethod(input.data)}
              inputClassName="w-[200px] mr-3"
              labelClassName=""
            />

            <div className="w-[200px] mr-3">
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Search by Name
              </label>
              <input
                type="text"
                value={searchCustomerName}
                placeholder="Enter Customer Name"
                onChange={(e) => setSearchCustomerName(e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              />
            </div>

            <div className="w-[200px] mr-3">
              <label className="block mb-2 text-sm font-medium text-gray-700">
                From Date
              </label>
              <input
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              />
            </div>

            <div className="w-[200px] mr-3">
              <label className="block mb-2 text-sm font-medium text-gray-700">
                To Date
              </label>
              <input
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              />
            </div>

            <div className="w-[200px] mr-3">
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Min Total
              </label>
              <input
                type="number"
                value={minTotal}
                placeholder="Min Total"
                onChange={(e) => setMinTotal(e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              />
            </div>

            <div className="w-[200px] mr-3">
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Max Total
              </label>
              <input
                type="number"
                value={maxTotal}
                placeholder="Max Total"
                onChange={(e) => setMaxTotal(e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              />
            </div>
          </div>
        </div>
        <Button
          text="CLEAR FILTERS"
          className="ml-3 hover:bg-primary hover:text-white px-[14px]"
          handleClick={() => {
            setSelectedPaymentMethod(null);
            setSearchCustomerName("");
            setFromDate("");
            setToDate("");
            setMinTotal("");
            setMaxTotal("");
            setFilteredSales(sales);
          }}
        />
        {isLoading ? (
          "Loading..."
        ) : (
          <Button
            text="PRINT"
            className="ml-3 hover:bg-primary hover:text-white px-[14px]"
            handleClick={handlePrint}
          />
        )}
      </div>
      <div id="printableArea" className="p-6 mx-3 my-1 border-2 rounded-md">
        <Table
          columns={columns}
          dataSource={filteredSales}
          rowKey="id"
          className="w-full"
          pagination={{ pageSize: 10 }}
        />
        <table className="w-full mt-4">
          <tbody>
            <tr>
              <td className="font-bold text-right">
                Total Cash Payment Amount:
              </td>
              <td className="text-right">
                {calculateTotalByPaymentMethod("cash")}
              </td>
            </tr>
            <tr>
              <td className="font-bold text-right">
                Total Card Payment Amount:
              </td>
              <td className="text-right">
                {calculateTotalByPaymentMethod("card")}
              </td>
            </tr>
            <tr>
              <td className="font-bold text-right">
                Total Cheque Payment Amount:
              </td>
              <td className="text-right">
                {calculateTotalByPaymentMethod("cheque")}
              </td>
            </tr>
            <tr>
              <td className="font-bold text-right">
                Total Credit Payment Amount:
              </td>
              <td className="text-right">
                {calculateTotalByPaymentMethod("credit")}
              </td>
            </tr>
            <tr>
              <td className="font-bold text-right">
                Total Bank Payment Amount:
              </td>
              <td className="text-right">
                {calculateTotalByPaymentMethod("bank")}
              </td>
            </tr>
            <tr>
              <td className="font-bold text-right">Total Sales:</td>
              <td className="text-right">
                {totalSales.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default Orders;
