import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { Table } from "antd";
import { toast } from "react-toastify";
import apiService from "../../../../http";
import { Select, Button } from "../../../../components/ui";

const CostingReport = () => {
  const [orders, setOrders] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [totalOrderCost, setTotalOrderCost] = useState(0);
  const [totalSelectedOrderCost, setTotalSelectedOrderCost] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const refreshOrders = async () => {
    try {
      const response = await apiService.get("order");
      setOrders(response);
    } catch (e) {
      toast.error(e.message);
    }
  };

  const refreshJobs = async (orderId) => {
    try {
      const selectedOrder = orders.find((order) => order.id === orderId);
      if (selectedOrder) {
        setSelectedOrder(selectedOrder);
        setTotalSelectedOrderCost(selectedOrder.total);
        setTotalRevenue(selectedOrder.totalRevenue || 0);
      } else {
        setSelectedOrder(null);
        setTotalSelectedOrderCost(0);
        setTotalRevenue(0);
      }

      const response = await apiService.get(`job/orderReport/${orderId}`);
      console.log("API Response:", response);

      if (Array.isArray(response)) {
        const parsedJobs = response.map((job) => {
          const labourCost = (job.labours || []).reduce(
            (total, labour) =>
              total +
              (labour.total ? Number(labour.total) * labour.hours : 0) +
              labour.labourHourlyRate * labour.hours,
            0
          );

          const materialCost = (job.materials || []).reduce(
            (total, material) => {
              if (material.transactionType === "return") {
                return total - material.amount * material.unitPrice;
              } else {
                return total + material.amount * material.unitPrice;
              }
            },
            0
          );

          const machineCost = (job.machines || []).reduce(
            (total, machine) => total + machine.total,
            0
          );

          const totalCost = labourCost + materialCost + machineCost;

          console.log("Job Costs:", {
            labourCost,
            materialCost,
            machineCost,
            totalCost,
          });

          return {
            ...job,
            createdAt: new Date(job.createdAt),
            labours: job.labours || [],
            materials: job.materials || [],
            machines: job.machines || [],
            labourCost: labourCost || 0,
            materialCost: materialCost || 0,
            machineCost: machineCost || 0,
            totalCost: totalCost || 0,
          };
        });

        console.log("Parsed Jobs:", parsedJobs);

        setJobs(parsedJobs);
        const orderTotalCost = parsedJobs.reduce(
          (total, job) => total + job.totalCost,
          0
        );
        setTotalOrderCost(orderTotalCost);

        console.log("Total Order Cost:", orderTotalCost);
      } else {
        console.error("API response is not an array");
        toast.error("Failed to fetch jobs: Invalid response format");
      }
    } catch (e) {
      console.error("Error fetching jobs:", e);
      toast.error(e.message);
    }
  };

  useEffect(() => {
    refreshOrders();
  }, []);

  useEffect(() => {
    if (selectedOrderId) {
      refreshJobs(selectedOrderId);
    } else {
      setJobs([]);
      setTotalOrderCost(0);
    }
  }, [selectedOrderId]);

  const jobColumns = [
    { title: "Job ID", dataIndex: "jobId", key: "jobId" },
    {
      title: "Product ID",
      dataIndex: ["product", "productId"],
      key: "productId",
    },
    {
      title: "Product Name",
      dataIndex: ["product", "title"],
      key: "productTitle",
    },
    { title: "Make Quantity", dataIndex: "makeQuantity", key: "makeQuantity" },
    {
      title: "Finished Quantity",
      dataIndex: "finishedQuantity",
      key: "finishedQuantity",
    },
    {
      title: "Material Cost",
      dataIndex: "materialCost",
      key: "materialCost",
      render: (text) => `${(text || 0).toFixed(2)}`,
    },
    {
      title: "Machine Cost",
      dataIndex: "machineCost",
      key: "machineCost",
      render: (text) => `${(text || 0).toFixed(2)}`,
    },
    {
      title: "Labour Cost",
      dataIndex: "labourCost",
      key: "labourCost",
      render: (text) => `${(text || 0).toFixed(2)}`,
    },
    {
      title: "Total Cost",
      dataIndex: "totalCost",
      key: "totalCost",
      render: (text) => `${(text || 0).toFixed(2)}`,
    },
  ];

  const handlePrint = () => {
    const printContent = ReactDOMServer.renderToString(
      <div>
        <div className="flex justify-between">
          <table className="no-border" style={{ width: "100%" }}>
            <tr>
              <td style={{ width: "60%" }}>
                <h3>Customer Details</h3>
                <table className="no-border">
                  <tr>
                    <td>Customer Name</td>
                    <td>: {selectedOrder?.customerName}</td>
                  </tr>
                  <tr>
                    <td>Customer Address</td>
                    <td>: {selectedOrder?.customerAddress}</td>
                  </tr>
                  <tr>
                    <td>Customer Phone Number</td>
                    <td>: {selectedOrder?.customerPhone}</td>
                  </tr>
                </table>
              </td>
              <td style={{ width: "40%" }}>
                <h3>Order Details</h3>
                <table className="no-border">
                  <tr>
                    <td>Order Id</td>
                    <td>: {selectedOrder?.orderId}</td>
                  </tr>
                  <tr>
                    <td>Advanced Payment:</td>
                    <td>: {selectedOrder?.advancePayment}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>: {selectedOrder?.status}</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
        <Table
          columns={jobColumns}
          dataSource={jobs}
          rowKey="jobId"
          pagination={false}
        />
        <div className="right">
          <table className="mt-5 no-border" style={{ width: "100%" }}>
            <tr>
              <td style={{ width: "60%" }}></td>
              <td style={{ width: "40%" }}>
                <table className="no-border">
                  <tr>
                    <h3>Total Costs</h3>
                  </tr>
                  <tr>
                    <td>Total Order Amount</td>
                    <td>
                      : {totalSelectedOrderCost.toFixed(2)}{" "}
                      {selectedOrder?.taxOption === "tax" && "(include Tax)"}
                    </td>
                  </tr>
                  <tr>
                    <td>Total Cost</td>
                    <td>: {totalOrderCost.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Business Cost</td>
                    <td>: {selectedOrder?.tip}</td>
                  </tr>
                  <tr>
                    <td>Profit</td>
                    <td>
                      : {(totalSelectedOrderCost - totalOrderCost).toFixed(2)}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
    const printWindow = window.open("", "Print", "width=800,height=600");
    printWindow.document.write(
      `<html><head><title>Costing Report</title>
      <style>
      table.no-border, table.no-border th, table.no-border td {
        border: none !important;
        padding: 8px;
    }
        table, th, td {
            border: 1px solid black;
            border-collapse: collapse;
            padding: 8px;
        }
      th {
          background-color: #f2f2f2;
      }
      img {
          width: 150px; /* Adjust the width as needed */
      }
      .print-content {
          text-align: center;
          margin-bottom: 20px;
      }
      .print-content p {
          font-family: Sinhala, Arial;
          font-size: 12px;
          margin: 20px 0 10px 0;
      }
      h1 {
          text-align: center;
      }
      p{
          font-family: Sinhala, Arial;
          font-size: 12px;
          margin: 10px 0 5px 0;
      }
      .classdiv{
          flex:2;
          text-align: right;
      }
      .fullCls{
          display:flex;
          justify-content:space-between;
          align-items:center;
          margin-bottom:20px;
      }.imgcls{
          flex:1;
          text-align:left;
          width:150px;
      }
  </style>
  </head><body>
  <div class="fullCls">
<div class="imgcls">
<img src="/logo.png" alt="Logo"/>
</div>
<div class="classdiv">
<p>
  තහඩු නැවීම, කැපිම, රොල් කිරීම, රූෆින් ශීට් සහ උපාංග, ස්ටීල්
  ෆැබ්‍රිකේශන් වැඩ
  <br />
  විලකොළදෙනිය, මලංගම, රත්නපුර
  <br />
  045 2121750, 074 0327850
</p>
</div>
</div>
  <h3 style="text-align:center;">Costing Report</h3>
            ${printContent}
            </body></html>`
    );
    printWindow.document.write(
      `<script>window.onload = function() { window.print(); window.onafterprint = function() { window.close(); }; };</script>`
    );
    printWindow.document.close();
  };

  return (
    <section className="w-full">
      <div className="p-6 mx-3 my-1 border-2 rounded-md">
        <p className="text-lg font-bold">COSTING REPORT</p>
        <div className="flex flex-wrap mb-4 space-x-3">
          <div className="w-[200px] mr-3">
            <Select
              input={{
                label: "Filter By Order ID",
                data: selectedOrderId,
                optList: orders.map((order) => ({
                  id: order.id,
                  title: order.orderId,
                })),
                optTitle: "Order ID",
              }}
              handleChange={(input) => setSelectedOrderId(input.data)}
              inputClassName="w-[200px] mr-3"
              labelClassName=""
            />
            <Button
              text="Print"
              className="hover:bg-primary hover:text-white px-[14px] mt-4"
              handleClick={() => handlePrint()}
            />
          </div>
        </div>
        <Table
          columns={jobColumns}
          dataSource={jobs}
          rowKey="jobId"
          pagination={false}
        />
        <div className="flex justify-between mt-4">
          <div className="left">
            <h3 className="font-bold">Customer Details</h3>
            <p>Customer Name: {selectedOrder?.customerName}</p>
            <p>Customer Address: {selectedOrder?.customerAddress}</p>
            <p>Customer Phone Number: {selectedOrder?.customerPhone}</p>
          </div>
          <div className="right">
            <h3 className="font-bold">Order Details</h3>
            <p>Order Id: {selectedOrder?.orderId}</p>
            <p>Advanced Payment: {selectedOrder?.advancePayment}</p>
            <p>Status: {selectedOrder?.status}</p>
          </div>
        </div>
        <div className="mt-4">
          <p className="font-bold">
            Total Order Amount: {totalSelectedOrderCost.toFixed(2)}
          </p>
          <p className="font-bold">Total Cost: {totalOrderCost.toFixed(2)}</p>
          <p className="font-bold">
            Profit: {(totalSelectedOrderCost - totalOrderCost).toFixed(2)}
          </p>
        </div>
      </div>
    </section>
  );
};

export default CostingReport;
