import React from "react";

const PrintReport = ({ sales }) => {
  if (!sales || sales.length === 0) {
    return <div>No sales data available to display.</div>;
  }
  const totalSales = sales.reduce((acc, sale) => acc + Number(sale.total), 0);
  const calculateTotalByPaymentMethod = (paymentMethod) => {
    return sales
      .filter((sale) => sale.paymentMethod === paymentMethod)
      .reduce((acc, sale) => acc + Number(sale.total), 0)
      .toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <div style={{ flex: "1", textAlign: "left" }}>
          <img src="/logo.png" alt="Logo" style={{ width: "200px" }} />
        </div>
        <div style={{ flex: "2", textAlign: "right" }}>
          <p
            style={{
              fontFamily: "Sinhala, Arial",
              fontSize: "15px",
              margin: "20px 0 10px 0",
            }}
          >
            තහඩු නැවීම, කැපිම, රොල් කිරීම, රූෆින් ශීට් සහ උපාංග, ස්ටීල්
            ෆැබ්‍රිකේශන් වැඩ
            <br />
            විලකොළදෙනිය, මලංගම, රත්නපුර
            <br />
            045 2121750, 074 0327850
          </p>
        </div>
      </div>
      <h3 style={{ textAlign: "center" }}>Sales Report</h3>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={{ padding: "8px", textAlign: "left" }}>Date</th>
            <th style={{ padding: "8px", textAlign: "left" }}>Customer Name</th>
            <th style={{ padding: "8px", textAlign: "left" }}>
              Invoice Number
            </th>
            <th style={{ padding: "8px", textAlign: "left" }}>
              Payment Method
            </th>
            <th style={{ padding: "8px", textAlign: "left" }}>Total</th>
            <th style={{ padding: "8px", textAlign: "left" }}>Sub Total</th>
          </tr>
        </thead>
        <tbody>
          {sales.map((sale, index) => (
            <tr key={index}>
              <td style={{ padding: "8px" }}>
                {new Date(sale.createdAt).toLocaleDateString()}
              </td>
              <td style={{ padding: "8px" }}>{sale.customerName}</td>
              <td style={{ padding: "8px" }}>{sale.invID}</td>
              <td style={{ padding: "8px" }}>{sale.paymentMethod}</td>
              <td style={{ padding: "8px" }}>
                {sale.total.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </td>
              <td style={{ padding: "8px" }}>
                {sale.discount.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <table className="w-full mt-4">
        <tbody>
          <tr>
            <td className="font-bold text-right">Total Cash Payment Amount:</td>
            <td className="text-right">
              {calculateTotalByPaymentMethod("cash")}
            </td>
          </tr>
          <tr>
            <td className="font-bold text-right">Total Card Payment Amount:</td>
            <td className="text-right">
              {calculateTotalByPaymentMethod("card")}
            </td>
          </tr>
          <tr>
            <td className="font-bold text-right">
              Total Cheque Payment Amount:
            </td>
            <td className="text-right">
              {calculateTotalByPaymentMethod("cheque")}
            </td>
          </tr>
          <tr>
            <td className="font-bold text-right">
              Total Credit Payment Amount:
            </td>
            <td className="text-right">
              {calculateTotalByPaymentMethod("credit")}
            </td>
          </tr>
          <tr>
            <td className="font-bold text-right">Total Bank Payment Amount:</td>
            <td className="text-right">
              {calculateTotalByPaymentMethod("bank")}
            </td>
          </tr>
          <tr>
            <td className="font-bold text-right">Total Sales:</td>
            <td className="text-right">
              {totalSales.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PrintReport;
