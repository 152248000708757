import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { Table } from "antd";
import { toast } from "react-toastify";
import apiService from "../../../../http";
import { Select, Button } from "../../../../components/ui";

const CustomerReport = () => {
  const [customers, setCustomers] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const refreshCustomers = async () => {
    try {
      const response = await apiService.get("customer");
      setCustomers(response);
    } catch (e) {
      toast.error(e.message);
    }
  };

  useEffect(() => {
    refreshCustomers();
  }, []);

  useEffect(() => {
    if (selectedCustomerId) {
      const customer = customers.find((cust) => cust.id === selectedCustomerId);
      setSelectedCustomer(customer);
    } else {
      setSelectedCustomer(null);
    }
  }, [selectedCustomerId]);

  const receiptColumns = [
    { title: "Payment ID", dataIndex: "invoiceId", key: "invoiceId" },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    { title: "Payment Method", dataIndex: "pMethod", key: "pMethod" },
    {
      title: "Outstanding Amount",
      dataIndex: "customerAmount",
      key: "customerAmount",
      render: (_, record) =>
        `${(record.currentoutstand + record.paidAmount).toFixed(2)}`,
    },
    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      key: "paidAmount",
      render: (text) => `${(text || 0).toFixed(2)}`,
    },
    {
      title: "Current Outstanding",
      dataIndex: "currentoutstand",
      key: "currentoutstand",
      render: (text) => `${(text || 0).toFixed(2)}`,
    },
  ];

  const handlePrint = () => {
    const printContent = ReactDOMServer.renderToString(
      <div>
        <div className="flex justify-between">
          <table className="no-border" style={{ width: "100%" }}>
            <tr>
              <td style={{ width: "60%" }}>
                <h3>Customer Details</h3>
                <table className="no-border">
                  <tr>
                    <td>Customer Name</td>
                    <td>: {selectedCustomer?.title}</td>
                  </tr>
                  <tr>
                    <td>NIC Number</td>
                    <td>: {selectedCustomer?.nicNumber}</td>
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td>: {selectedCustomer?.address}</td>
                  </tr>
                  <tr>
                    <td>Phone Number</td>
                    <td>: {selectedCustomer?.numberMob}</td>
                  </tr>
                </table>
              </td>
              <td style={{ width: "40%" }}>
                <h3>Outstanding Details</h3>
                <table className="no-border">
                  <tr>
                    <td>Outstanding Amount</td>
                    <td>: LKR.{selectedCustomer?.outstand.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>
                      : {selectedCustomer?.status ? "Active" : "Inactive"}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
        <Table
          columns={receiptColumns}
          dataSource={selectedCustomer?.outReciept}
          rowKey="invoiceId"
          pagination={false}
        />
      </div>
    );

    const printWindow = window.open("", "Print", "width=800,height=600");
    printWindow.document.write(
      `<html><head><title>Customer Report</title>
      <style>
      table {
          width: 100%;
          border-collapse: collapse;
      }
      th, td {
          text-align: left;
          padding: 8px;
      }
      th {
          background-color: #f2f2f2;
      }
      img {
          width: 150px; /* Adjust the width as needed */
      }
      .print-content {
          text-align: center;
          margin-bottom: 20px;
      }
      .print-content p {
          font-family: Sinhala, Arial;
          font-size: 12px;
          margin: 20px 0 10px 0;
      }
      h1 {
          text-align: center;
      }
      p{
          fontFamily: Sinhala, Arial;
          fontSize: 12px;
          margin: 10px 0 5px 0;
      }
      .classdiv{
          flex:2;
          text-align: right;
      }
      .fullCls{
          display:flex;
          justify-content:space-between;
          align-itmes:center;
          margin-bottom:20px;
      }.imgcls{
          flex:1;
          text-align:left;
          width:150px;
      }
  </style>
  </head><body>
  <div class="fullCls">
<div class="imgcls">
<img src="/logo.png" alt="Logo"/>
</div>
<div class="classdiv">
<p>
  තහඩු නැවීම, කැපිම, රොල් කිරීම, රූෆින් ශීට් සහ උපාංග, ස්ටීල්
  ෆැබ්‍රිකේශන් වැඩ
  <br />
  විලකොළදෙනිය, මලංගම, රත්නපුර
  <br />
  045 2121750, 074 0327850
</p>
</div>
</div>
            <h1>CUSTOMER OUTSTANDING REPORT</h1>
            ${printContent}
            </body></html>`
    );
    printWindow.document.write(
      `<script>window.onload = function() { window.print(); window.onafterprint = function() { window.close(); }; };</script>`
    );
    printWindow.document.close();
  };

  return (
    <section className="w-full">
      <div className="p-6 mx-3 my-1 border-2 rounded-md">
        <p className="text-lg font-bold">CUSTOMER OUTSTANDING REPORT</p>
        <div className="flex flex-wrap mb-4 space-x-3">
          <div className="w-[200px] mr-3">
            <Select
              input={{
                label: "Filter By Customer ID",
                data: selectedCustomerId,
                optList: customers.map((customer) => ({
                  id: customer.id,
                  title: customer.customerId + "-" + customer.title,
                })),
                optTitle: "Customer ID",
              }}
              handleChange={(input) => setSelectedCustomerId(input.data)}
              inputClassName="w-[200px] mr-3"
              labelClassName=""
            />
            <Button
              text="Print"
              className="hover:bg-primary hover:text-white px-[14px] mt-4"
              handleClick={() => handlePrint()}
            />
          </div>
        </div>
        {selectedCustomer && (
          <>
            <Table
              columns={receiptColumns}
              dataSource={selectedCustomer.outReciept}
              rowKey="invoiceId"
              pagination={false}
            />
            <div className="flex justify-between mt-4">
              <div className="left">
                <h3 className="font-bold">Customer Details</h3>
                <p>Customer Name: {selectedCustomer.title}</p>
                <p>NIC Number: {selectedCustomer.nicNumber}</p>
                <p>Address: {selectedCustomer.address}</p>
                <p>Phone Number: {selectedCustomer.numberMob}</p>
              </div>
              <div className="right">
                <h3 className="font-bold">Outstanding Details</h3>
                <p>
                  Outstanding Amount: Lkr.{selectedCustomer.outstand.toFixed(2)}
                </p>
                <p>Status: {selectedCustomer.status ? "Active" : "Inactive"}</p>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default CustomerReport;
