import React, { useEffect, useState } from "react";
import { Table,Input } from "antd";
import { toast } from "react-toastify";
import LabourModal from "./LabourModal";
import { Confirm, Button } from "../../../components/ui";
import apiService from "../../../http";

const Labours = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [Labours, setLabours] = useState([]);
    const [dataModalState, setDataModalState] = useState(false);
    const [selectedLabour, setSelectedLabour] = useState(null);
    const [confirmModalState, setConfirmModalState] = useState(false);

    const handleEdit = (labour) => {
        setSelectedLabour(labour);
        setDataModalState(true);
    };

    const handleDelete = async () => {
        try {
            await apiService.delete("labour/" + selectedLabour.id);
            setConfirmModalState(false);
            refreshLabours();
            setSelectedLabour(null);
            toast.success("Labour deleted successfully");
        } catch (e) {
            toast.error(e);
        }
    };

    const handleCloseModal = () => {
        refreshLabours();
        setDataModalState(false);
        setConfirmModalState(false);
        setSelectedLabour(null);
    };
    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    const filteredLabours = Labours.filter((labour) => {
        const columnsToSearch = ["title", "remarks", "labourId"]; 
        return columnsToSearch.some((column) =>
            String(labour[column]).toLowerCase().includes(searchTerm.toLowerCase())
        );
    });
    const refreshLabours = async () => {
        try {
            const labours = await apiService.get("labour");
            setLabours(labours);
        } catch (e) {
            toast.error(e);
        }
    };

    useEffect(() => {
        refreshLabours();
    }, []);

    const actionBtns = (labour) => {
        return (
            <div className="flex">
                {/* <Button
                    text="View"
                    className={
                        "ml-3 hover:bg-primary hover:text-white px-[14px]"
                    }
                    handleClick={() => handleEdit(labour)}
                /> */}
                <Button
                    text="DELETE"
                    className={
                        "ml-3 hover:bg-primary hover:text-white px-[14px]"
                    }
                    handleClick={() => {
                        setSelectedLabour(labour);
                        setConfirmModalState(true);
                    }}
                />

                <Button
                    text="EDIT"
                    className={
                        "ml-3 hover:bg-primary hover:text-white px-[14px]"
                    }
                    handleClick={() => {
                        setSelectedLabour(labour);
                        handleEdit(labour);
                    }}
                />
            </div>
        );
    };


    const columns = [
        {
            title: "#",
            width: "10%",
            render: (arg, record, index) => {
                const rowNumber = index + 1;
                return <p className="text-center">{rowNumber}</p>;
            },
        },
        {
            title: "LABOUR ID",
            dataIndex: "labourId",
            key: "labourId",
            align: "center",
            width: "10%",
            sorter: (a, b) => a.labourId.localeCompare(b.labourId),

        },
        {
            title: "TITLE",
            dataIndex: "title",
            key: "title",
            align: "center",
            width: "20%",
        },
        {
            title: "OT RATE",
            align: "center",
            width: "30%",
            render: (arg) => {
                const formattedPrice = arg.overTime.toLocaleString("en-US", {
                    style: "currency",
                    currency: "LKR",
                });
                return formattedPrice;
            },
        },
        {
            title: "HOURLY RATE",
            align: "center",
            width: "30%",
            render: (arg) => {
                const formattedPrice = arg.hourlyRate.toLocaleString("en-US", {
                    style: "currency",
                    currency: "LKR",
                });
                return formattedPrice;
            },

        },
        {
            title: "STATUS",
            dataIndex: "status",
            key: "status",
            align: "center",
            width: "30%",
            render: (status) => (
                <span
                className={`rounded px-2 py-1 ${
                    status ? "bg-green-500 text-white" : "bg-red-500 text-white"
                }`}
            >
                {status ? "ACTIVE" : "INACTIVE"}
            </span>
            ),
        },
        {
            title: "OPERATIONS",
            align: "center",
            width: "40%",
            render: (arg) => actionBtns(arg),
        },
    ];

    return (
        <section className="w-full mt-6">
            {dataModalState && (
                <LabourModal
                    selectedLabour={selectedLabour}
                    handleClose={handleCloseModal}
                />
            )}

            {confirmModalState && (
                <Confirm
                    cancelHandler={handleCloseModal}
                    confirmHandler={handleDelete}
                />
            )}

            <div className="grid grid-cols-3 gap-3 px-2 py-2 mb-3 bg-white rounded-md">
                <div>
                    <Button
                        text={"ADD NEW LABOUR"}
                        handleClick={() => setDataModalState(true)}
                    />
                </div>
                <div className="flex items-center justify-end col-span-2"  >
                
        <Input
            placeholder="   Type To Search " 
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
            style={{ width: "30%",border: "1px solid #878686", borderRadius: "4px",height: "40px" }}// Adjust width as needed

        />
       
    </div>
            </div>

            <Table
                columns={columns}
                dataSource={filteredLabours}
                style={{ width: "100%" }}
            />
        </section>
    );
};

export default Labours;
