/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { Table, Input } from "antd";
import Moment from "moment";
import apiService from "../../../http";
import { FaRegEye, FaPrint, FaRegTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";

const Sales = () => {
  const [Sales, setSales] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const refreshSales = async () => {
    try {
      const sales = await apiService.get("sale");
      setSales(sales);
    } catch (e) {
      console.log(e);
    }
  };
  const handleSearch = (value) => {
    setSearchTerm(value);
  };
  const handleDelete = async (arg) => {
    try {
      let id = arg.id;
      await apiService.delete(`sale/${id}`);
      toast.success("Successfully Deleted!");
      refreshSales();
    } catch (error) {
      toast.error("Error deleting sale: " + error.message);
    }
  };

  // const handleDownload = async (invoiceId) => {
  //     try {
  //       const response = await fetch(`${invoiceId}`, {
  //         method: 'GET',
  //         headers: {
  //           'Content-Type': 'application/pdf',
  //         },
  //       });

  //       if (!response.ok) {
  //         console.error(`Error downloading invoice: ${response.statusText}`);
  //         return;
  //       }

  //       const blob = await response.blob();

  //       const url = window.URL.createObjectURL(blob);

  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.download = `Invoice.pdf`;
  //       document.body.appendChild(link);
  //       link.click();
  //           document.body.removeChild(link);
  //       window.URL.revokeObjectURL(url);
  //     } catch (error) {
  //       console.error('Error downloading invoice:', error);
  //     }
  //   };

  // Assuming arg.invoice is the invoiceId

  const filteredServices = Sales.filter((sale) => {
    if (
      String(sale.customerName).toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return true;
    }
    if (String(sale.invID).toLowerCase().includes(searchTerm.toLowerCase())) {
      return true;
    }
    if (
      String(sale.chequeNum).toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return true;
    }
    const itemsTitles = sale.items.map((item) => item.title.toLowerCase());
    if (itemsTitles.join(" ").includes(searchTerm.toLowerCase())) {
      return true;
    }

    const columnsToSearch = ["invid", "title", "chequeNum"];
    return columnsToSearch.some((column) =>
      String(sale[column]).toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
  useEffect(() => {
    refreshSales();
  }, []);
  const handleReturn = async (arg) => {
    const formData = {
      customerid: arg.customerId,
      saleId: arg.id,
      value: arg.discount,
    };
    console.log(formData);
    try {
      await apiService.patch("sale/return", formData);
      toast.success("Cheque Return successfully!");
      refreshSales();
    } catch (e) {
      toast.error(e);
    }
  };
  const actionBtns = (arg) => {
    return (
      <div className="flex">
        <button
          onClick={() => viewInvoice(arg)}
          style={{
            backgroundColor: "#243c4f", // Dark blue background color
            color: "#fff", // White text color
            border: "none",
            padding: "8px 12px",
            borderRadius: "6px",
            cursor: "pointer",
          }}
          className="mx-2"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <FaRegEye size={16} /> {/* Adjust size as needed */}
          </div>
        </button>
        <button
          onClick={() => handleDelete(arg)}
          style={{
            backgroundColor: "red", // Dark blue background color
            color: "#fff", // White text color
            border: "none",
            padding: "8px 12px",
            borderRadius: "6px",
            cursor: "pointer",
          }}
          className="mx-2"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FaRegTrashAlt size={16} /> {/* Adjust size as needed */}
          </div>
        </button>
        <button
          onClick={() => handlePrint(arg)}
          style={{
            backgroundColor: "#243c4f", // Dark blue background color
            color: "#fff", // White text color
            border: "none",
            padding: "8px 12px",
            borderRadius: "6px",
            cursor: "pointer",
          }}
          className="mx-2"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <FaPrint size={16} /> {/* Adjust size as needed */}
          </div>
        </button>
        {arg.paymentMethod === "cheque" &&
          (arg.returned === "true" ? (
            <button
              disabled
              style={{
                backgroundColor: "#d3d3d3",
                color: "#000",
                border: "none",
                padding: "8px 12px",
                borderRadius: "6px",
                cursor: "not-allowed",
              }}
              className="mx-2"
            >
              {" "}
              Returned{" "}
            </button>
          ) : (
            <button
              onClick={() => handleReturn(arg)}
              style={{
                backgroundColor: "#243c4f",
                color: "#fff",
                border: "none",
                padding: "8px 12px",
                borderRadius: "6px",
                cursor: "pointer",
              }}
              className="mx-2"
            >
              {" "}
              Return{" "}
            </button>
          ))}
      </div>
    );
  };

  // View Invoice
  const viewInvoice = (invoice) => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US");

    const taxAmount = invoice.taxOption ? invoice.total * 0.18 : 0;
    const grandTotal = invoice.total + taxAmount;

    let printContent = "";
    if (invoice.outs === "true") {
      printContent += `
      <table>
          <thead>
              <tr>
                  <th>Description</th>
                  <th>Total</th>
              </tr>
          </thead>
          <tbody>
          <tr>
            <td>Outstanding Payment</td>
            <td>${invoice.total}</td>
          </tr>
          </tbody>
      </table>
      `;
    } else {
      printContent += `
      <table>
          <thead>
              <tr>
                  <th>#</th>
                  <th style="width: 50%;">Title</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Discount</th>
                  <th>Total</th>
              </tr>
          </thead>
          <tbody>
      `;
      let items = invoice.items;
      items.forEach((item, index) => {
        const title = item.title;
        let quantity = parseFloat(item.quantity);
        const price = parseFloat(item.price);
        const discount = parseFloat(item.discount);
        let totalPrice = 0;

        // Check if item type is "material"
        if (item.types === "material") {
          const fullQuantity = parseFloat(item.fullquantity);
          const offCutUsage = parseFloat(item.offCutUsage);
          const offCutPrice = parseFloat(item.offcutprice);

          quantity = `full: ${fullQuantity} | off: ${offCutUsage}`;

          totalPrice = fullQuantity * price + offCutUsage * offCutPrice;
        } else {
          totalPrice = quantity * price;
        }

        const discountedPrice = totalPrice - (totalPrice * discount) / 100;

        printContent += `
            <tr>
                <td>${index + 1}</td>
                <td>${title}</td>
                <td>${quantity}</td>
                <td>${price.toLocaleString("en-US", {
                  style: "currency",
                  currency: "LKR",
                })}</td>
                <td>${discount}%</td>
                <td>${discountedPrice.toLocaleString("en-US", {
                  style: "currency",
                  currency: "LKR",
                })}</td>
            </tr>
        `;
      });

      printContent += `
          </tbody>
      </table>
      `;
    }

    const printWindow = window.open("", "_blank"); // Open a new blank window
    printWindow.document.write(
      `<html><head><title>Invoice</title>
            <style>
                body {
                    font-family: Arial, sans-serif;
                    margin: 0;
                    padding: 0;
                    font-size: 12px;
                    color: #000;
                }
                .container {
                    width: 95%;
                    margin: 0 auto;
                    padding: 20px;
                    border: 1px solid #000;
                    box-sizing: border-box;
                }
                .header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 20px;
                }
                .header-left {
                    text-align: left;
                    width: 70%;
                }
                .header-left p {
                    font-family: Sinhala, Arial;
                    font-size: 15px;
                    margin: 10px 0;
                }
                .header-right {
                    text-align: right;
                    width: 30%;
                }
                .header-right img {
                    width: 150px;
                }
                .invoice-title {
                    text-align: center;
                    font-size: 24px;
                    font-weight: bold;
                    margin-bottom: 20px;
                }
                .invoice-details {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;
                }
                .invoice-details div {
                    width: 45%;
                }
                .invoice-details div p {
                    margin: 5px 0;
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                    margin-bottom: 20px;
                }
                table, th, td {
                    border: 1px solid black;
                    padding: 8px;
                }
                th {
                    background-color: #f2f2f2;
                    text-align: center;
                }
                .totals {
                    text-align: right;
                    margin-bottom: 20px;
                }
                .totals div {
                    margin: 5px 0;
                }
                .footer {
                    text-align: center;
                    font-size: 10px;
                    margin-top: 20px;
                }
                .signature {
                    margin-top: 40px;
                    text-align: right;
                }
                .signature p {
                    margin: 0;
                }
            </style>
            </head><body>
            <div class="container">
                <div class="header">
                    <div class="header-left">
                        <p>
                            තහඩු නැවීම, කැපිම, රොල් කිරීම, රූෆින් ශීට් සහ උපාංග, ස්ටීල් ෆැබ්‍රිකේශන් වැඩ
                        <br>
                            විලකොළදෙනිය, මලංගම, රත්නපුර
                        <br>
                            045 2121750, 074 0327850
                        </p>
                    </div>
                    <div class="header-right">
                        <img src="/logo.png" alt="Logo" />
                    </div>
                </div>
                <div class="invoice-title">Invoice</div>
                <div class="invoice-details">
                    <div class="left">
                        <p>Invoice Number: ${invoice.invID}</p>
                        <p>Invoice Date: ${formattedDate}</p>
                        <p>Customer Name: ${invoice.customerName}</p>
                        <p>Customer Telephone: ${invoice.customerPhone}</p>
                    </div>
                    <div class="right">
                        <p>Payment Method: ${invoice.paymentMethod}</p>
                    </div>
                </div>
                ${printContent}
                <div class="totals">
                    <div>Total Value: ${invoice.discount.toLocaleString(
                      "en-US",
                      {
                        style: "currency",
                        currency: "LKR",
                      }
                    )}</div>
                    ${
                      invoice.taxOption
                        ? `<div>Tax Amount: ${taxAmount.toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "LKR",
                            }
                          )}</div>`
                        : ""
                    }
                    ${
                      invoice.taxOption
                        ? `<div>Grand Total: ${grandTotal.toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "LKR",
                            }
                          )}</div>`
                        : ""
                    }
                </div>
                <div class="footer">
                    <p>Invoiced By Nsteel Engineering.</p>
                </div>
            </div>
            </body></html>`
    );

    printWindow.document.close();
  };

  const handlePrint = (invoice) => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US");
    const formattedTime = currentDate.toLocaleTimeString("en-US");

    const taxAmount = invoice.taxOption ? invoice.total * 0.18 : 0;
    const grandTotal = (invoice.total || 0) + taxAmount;

    let printContent = `
    <table style="width: 100%; border-collapse: collapse;">
      <thead>
        <tr>
          <th style="border: 1px solid black; padding: 5px;">Item Code & Description</th>
          <th style="border: 1px solid black; padding: 5px;">Qty</th>
          <th style="border: 1px solid black; padding: 5px;">U. Price</th>
          <th style="border: 1px solid black; padding: 5px;">DSCNT</th>
          <th style="border: 1px solid black; padding: 5px;">Amount</th>
        </tr>
      </thead>
      <tbody>
  `;

    let items = invoice.items || [];
    items.forEach((item) => {
      const title = item.title || "";
      const quantity = parseFloat(item.quantity) || 0;
      const price = parseFloat(item.price) || 0;
      const discount = parseFloat(item.discount) || 0;
      const totalPrice = quantity * price;
      const discountedPrice = totalPrice - (totalPrice * discount) / 100;

      printContent += `
      <tr>
        <td style="border: 1px solid black; padding: 5px;">${title}</td>
        <td style="border: 1px solid black; padding: 5px; text-align: right;">${quantity}</td>
        <td style="border: 1px solid black; padding: 5px; text-align: right;">${price.toFixed(
          2
        )}</td>
        <td style="border: 1px solid black; padding: 5px; text-align: right;">${discount.toFixed(
          2
        )}</td>
        <td style="border: 1px solid black; padding: 5px; text-align: right;">${discountedPrice.toFixed(
          2
        )}</td>
      </tr>
    `;
    });

    printContent += `
      </tbody>
    </table>
    
  `;

    const printWindow = window.open("", "_blank"); // Open a new blank window
    printWindow.document.write(
      `<html><head><title>Invoice</title>
          <style>
              body {
                  font-family: Arial, sans-serif;
                  margin: 0;
                  padding: 0;
                  font-size: 12px;
                  color: #000;
              }
              .container {
                  width: 95%;
                  margin: 0 auto;
                  padding: 20px;
                  border: 1px solid #000;
                  box-sizing: border-box; /* Ensures the padding and border are included in the width */

              }
              .header {
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 20px;
              }
              .header-left {
                  width: 70%;
                  font-weight: bold;
                  line-height: 1.5;
              }
              .header-right {
                  width: 25%;
                  text-align: right;
              }
              .header-right img {
                  max-width: 100px;
              }
              .invoice-title {
                  text-align: center;
                  font-size: 24px;
                  font-weight: bold;
                  margin-bottom: 20px;
              }
              .invoice-details {
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 20px;
              }
              .invoice-details div {
                  width: 30%;
              }
              .invoice-details div p {
                  margin: 5px 0;
              }
              table {
                  width: 100%;
                  border-collapse: collapse;
                  margin-bottom: 20px;
              }
              table, th, td {
                  border: 1px solid black;
                  padding: 8px;
              }
              th {
                  background-color: #f2f2f2;
                  text-align: center;
              }
              .totals {
                  text-align: right;
                  margin-bottom: 20px;
              }
              .totals div {
                  margin: 5px 0;
              }
              .footer {
                  text-align: center;
                  font-size: 10px;
                  margin-top: 20px;
              }
              .signature {
                  margin-top: 40px;
                  text-align: right;
              }
              .signature p {
                  margin: 0;
              }
          </style>
          </head><body>
          
          <div class="container">
              <div class="header">
                  <div class="header-left">
                      Nsteel Engineering<br>
                      විලකොළදෙනිය, මලංගම, රත්නපුර<br>
                      Tel: 045-2121750, 074-0327850
                  </div>
                  <div class="header-right">
                      <img src="/logo.png" alt="Logo">
                  </div>
              </div>
              
              <div class="invoice-title">INVOICE</div>
              
              <div class="invoice-details">
                  <div>
                      <p><strong>Invoice Number:</strong> ${
                        invoice.invID || "N/A"
                      }</p>
                      <p><strong>Invoice Date:</strong> ${formattedDate}</p>
                      <p><strong>Invoice Time:</strong> ${formattedTime}</p>
                  </div>
                  <div>
                      <p><strong>Invoiced By:</strong> Nsteel Engineering</p>
                      <p><strong>Customer Name:</strong> ${
                        invoice.customerName || "N/A"
                      }</p>
                      <p><strong>Customer Phone:</strong> ${
                        invoice.customerPhone || "N/A"
                      }</p>
                  </div>
              </div>
              

                      ${printContent}

              
              <div class="totals">
              <p>Total Amount: ${(invoice.total || 0).toFixed(2)}</p>
              <p>Total Disc Amount: ${(invoice.discountTotal || 0).toFixed(
                2
              )}</p>
              ${
                invoice.taxOption
                  ? `<div>Tax Amount: ${taxAmount.toLocaleString("en-US", {
                      style: "currency",
                      currency: "LKR",
                    })}</div>`
                  : ""
              }
              ${
                invoice.taxOption
                  ? `<div>Grand Total: ${grandTotal.toLocaleString("en-US", {
                      style: "currency",
                      currency: "LKR",
                    })}</div>`
                  : ""
              }
             
              </div>
              
              <div class="footer">
                  * 30 days credit only *<br>
                  Cheques to be written in favor of Nsteel Engineering & CROSSED AS A/C PAYEE ONLY<br>
                  ${(invoice.netTotal || 0).toFixed(2)} ONLY
              </div>
              
              <div class="signature">
                  <p>Authorized Signature: _______________________</p>
              </div>
          </div>
          
          <script>
              window.onload = function() { 
                  window.print(); 
                  window.onafterprint = function() { window.close(); }; 
              };
          </script>
          </body></html>`
    );
    printWindow.document.close();
  };

  const columns = [
    {
      title: "#",
      width: "7%",
      render: (arg, record, index) => {
        const rowNumber = index + 1;
        return <p className="text-center">{rowNumber}</p>;
      },
    },
    {
      title: "INVOICE ID",
      width: "7%",
      key: "invid",
      render: (arg) => {
        return (
          <div className="flex flex-col items-start">
            <p>{arg.invID}</p>
            {/* <p>{arg.customerPhone}</p>
                        <p>{arg.customerAddress}</p> */}
          </div>
        );
      },
      sorter: (a, b) => {
        const invIdA = a.invID || "";
        const invIdB = b.invID || "";
        return invIdA.localeCompare(invIdB);
      },
    },
    {
      title: "CHEQUE NO.",
      width: "7%",
      key: "chequeNum",
      render: (arg) => {
        return (
          <div className="flex flex-col items-start">
            <p>{arg.chequeNum ? arg.chequeNum : "-"}</p>
          </div>
        );
      },
    },

    // {
    //     title: "REMARKS",
    //     align: "center",
    //     width: "10%",
    //     render: (arg) => {
    //         return arg.remarks ? arg.remarks : "N/A";
    //     },
    // },
    {
      title: "CUSTOMER DETAILS",
      align: "left",
      width: "24%",
      render: (arg) => {
        return (
          <div className="flex flex-col items-start">
            <p>
              {arg.customerName}{" "}
              {arg.outs === "true" && <span>(Outstand Payment)</span>}
            </p>

            {/* <p>{arg.customerPhone}</p>
                        <p>{arg.customerAddress}</p> */}
          </div>
        );
      },
    },

    {
      title: "TOTAL",
      align: "center",
      width: "17%",
      render: (arg) => {
        const formattedPrice = arg.total.toLocaleString("en-US", {
          style: "currency",
          currency: "LKR",
        });
        return formattedPrice;
      },
    },
    {
      title: "SUB TOTAL",
      align: "center",
      width: "22%",
      render: (arg) => {
        const subTotal = arg.discount;
        const formattedPrice = subTotal.toLocaleString("en-US", {
          style: "currency",
          currency: "LKR",
        });
        return formattedPrice;
      },
    },
    {
      title: "SALE DATE",
      align: "center",
      width: "20%",
      render: (arg) => {
        const date = Moment(arg.createdAt);

        const formattedDatetime = date.format("YYYY-MM-DD "); //ADD this one to show time with date HH:mm:ss

        return formattedDatetime;
      },
    },
    {
      title: "OPERATIONS",
      align: "center",
      width: "24%",
      render: (arg) => actionBtns(arg),
    },
  ];

  return (
    <section className="w-full mt-6 ">
      <div className="flex items-end justify-end my-2" style={{ width: "98%" }}>
        <Input
          placeholder="   Type To Search"
          value={searchTerm}
          onChange={(e) => handleSearch(e.target.value)}
          style={{
            width: "20%",
            border: "1px solid #878686",
            borderRadius: "4px",
            marginLeft: "auto",
            height: "40px",
          }} // Adjust width as needed
        />
      </div>
      <Table
        columns={columns}
        dataSource={filteredServices}
        style={{ width: "100%" }}
      />
    </section>
  );
};

export default Sales;
