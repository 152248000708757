import React, { useEffect, useState } from "react";
import { Table, Input } from "antd";
import { toast } from "react-toastify";
import JobModal from "./JobModal";
import ProductMaterialModal from "./ProductMaterialModal";
import ProductLabourModal from "./ProductLabourModal";
import JobExportModal from "./JobExportModal";
import MachineModal from "./ProductMachineModal";
import StockEditModal from "./StockEditModal";
import ServiceModal from "./ProductServiceModal";
import { FaArrowUp, FaMarker } from "react-icons/fa";
import { Button, Confirm } from "../../../components/ui";
import apiService from "../../../http";

const Products = () => {
  const [jobs, setJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [jobModalState, setJobModalState] = useState(false);
  const [materialModalState, setMaterialModalState] = useState(false);
  const [labourModalState, setLabourModalState] = useState(false);
  const [exportModalState, setExportModalState] = useState(false);
  const [machinetModalState, setMachineModalState] = useState(false);
  const [stockEditModalState, setStockEditModalState] = useState(false); // State for stock edit modal
  const [serviceModal, setServiceModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [confirmModalState, setConfirmModalState] = useState(false);

  const handleCloseModal = () => {
    setJobModalState(false);
    setConfirmModalState(false);
    setMaterialModalState(false);
    setLabourModalState(false);
    setExportModalState(false);
    setMachineModalState(false);
    setStockEditModalState(false); // Close stock edit modal
    setServiceModal(false);
    refreshProducts();
  };

  const handleEdit = (product) => {
    setSelectedJob(product);
    setStockEditModalState(true); // Open stock edit modal
  };
  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const filteredServices = jobs.filter((job) => {
    const columnsToSearch = ["jobId", "orderId", "finishedQuantity"];
    if (
      String(job.product.productId)
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    ) {
      return true;
    } else if (
      String(job.product.title).toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return true;
    }
    return columnsToSearch.some((column) =>
      String(job[column]).toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
  // const handleDelete = async () => {
  //     try {
  //         await apiService.delete("product/" + selectedJob.id);
  //         setConfirmModalState(false);
  //         refreshProducts();
  //         setSelectedJob(null);
  //         toast.success("Product deleted successfully");
  //     } catch (e) {}
  // };
  //   const saveStock = async (stock) => {
  //     try {
  //       await apiService.patch(`product/${selectedJob.product.id}`, { stock });
  //       toast.success("Stock quantity updated successfully!");
  //       refreshProducts();
  //     } catch (e) {
  //       console.log(e);
  //       toast.error("Failed to update stock quantity.");
  //     }
  //   };
  const refreshProducts = async () => {
    try {
      const jobs = await apiService.get("job");

      setJobs(jobs);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    refreshProducts();
  }, []);

  // const costCalculation = (product) => {
  //     // const formattedPrice = product.unitPrice.toLocaleString("en-US", {
  //     //     style: "currency",
  //     //     currency: "LKR",
  //     // });
  //     // return formattedPrice;
  //     // return (
  //     //     <div>
  //     //         <pre>{"Material cost  : " + formattedPrice}</pre>
  //     //         <pre>{"Labour cost    : " + formattedPrice}</pre>
  //     //         <pre>{"Total cost     : " + formattedPrice}</pre>
  //     //     </div>
  //     // );
  // };
  // const timeCalculation = (product) => {
  //     const startDate = Moment(product.createdAt);

  //     let endDate = null;
  //     let days = null;

  //     if (product.finishedAt) {
  //         endDate = Moment(product.finishedAt);
  //         days = endDate.diff(startDate, "days");
  //     }

  //     return (
  //         <div>
  //             <pre>{"Start date  : " + startDate.format("YYYY-MM-DD")}</pre>
  //             {endDate && (
  //                 <pre>{"End date    : " + endDate.format("YYYY-MM-DD")}</pre>
  //             )}
  //             {days != null && <pre>{"Total days  : " + `${days} Days`}</pre>}
  //         </div>
  //     );
  // };

  // const productStatus = (product) => {
  //     let classes =
  //         "cursor-pointer inline-flex items-center justify-center px-2  py-1 mr-2 text-xs font-bold leading-none text-red-100 rounded-full";

  //     switch (product.status) {
  //         case "on-going":
  //             classes += " bg-[#6c5ce7]";
  //             break;
  //         case "finished":
  //             classes += " bg-[#1abc9c]";
  //             break;

  //         default:
  //     }

  //     return (
  //         <span
  //             onClick={async () => {
  //                 if (product.status === "finished") return;
  //                 setSelectedJob(product);
  //                 setConfirmModalState(true);
  //             }}
  //             className={classes}
  //         >
  //             {product.status === "on-going" ? "On Going" : "Finished"}
  //         </span>
  //     );
  // };

  const actionBtns = (product) => {
    const { orderId } = product;
    return (
      <div className="flex flex-wrap items-center">
        <Button
          disabled={product.status === "finished"}
          text="MATERIAL"
          className={"ml-1 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedJob(product);
            setMaterialModalState(true);
          }}
        />
        <Button
          disabled={product.status === "finished"}
          text="LABOUR"
          className={"ml-1 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedJob(product);
            setLabourModalState(true);
          }}
        />
        <Button
          disabled={product.status === "finished"}
          text="SERVICE"
          className={"ml-1 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedJob(product);
            setServiceModal(true);
          }}
        />

        <Button
          disabled={product.status === "finished"}
          text="MACHINE"
          className={"ml-1 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedJob(product);
            setMachineModalState(true);
          }}
        />
        {orderId ? null : (
          <Button
            // Disable the button if orderId has a value
            disabled={!!orderId}
            text="EXPORT"
            className={"ml-1 hover:bg-primary hover:text-white px-[14px]"}
            handleClick={() => {
              setSelectedJob(product);
              setExportModalState(true);
            }}
          >
            <FaArrowUp />
          </Button>
        )}
        <Button
          text="EDIT STOCK"
          className={" hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedJob(product);
            handleEdit(product);
          }}
        >
          <FaMarker />
        </Button>
      </div>
    );
  };

  const columns = [
    // {
    //   title: "#",
    //   width: "3%",
    //   render: (arg, record, index) => {
    //     const rowNumber = index + 1;
    //     return <p className="text-center">{rowNumber}</p>;
    //   },
    // },
    {
      title: "JOB ID",
      dataIndex: "jobId",
      key: "jobId",
      align: "center",
      width: "3%",
      sorter: (a, b) => a.jobId.localeCompare(b.jobId),
    },
    {
      title: "PRO ID",
      align: "center",
      width: "3%",
      render: (arg) => {
        return arg.product.productId;
      },
    },
    {
      title: "ORD ID",
      align: "center",
      width: "2%",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "PRODUCT",
      align: "center",
      width: "3%",
      render: (arg) => {
        return arg.product.title;
      },
    },
    {
      title: "MAKE QTY.",
      align: "center",
      width: "2%",
      dataIndex: "makeQuantity",
      key: "makeQuantity",
    },
    {
      title: "FINISHED QTY.",
      align: "center",
      width: "2%",
      dataIndex: "finishedQuantity",
      key: "finishedQuantity",
    },
    {
      title: "OPERATIONS",
      align: "center",
      width: "30%",
      render: (arg) => actionBtns(arg),
    },
  ];

  return (
    <section className="w-full mt-6">
      {jobModalState && (
        <JobModal selectedJob={selectedJob} handleClose={handleCloseModal} />
      )}
      {materialModalState && (
        <ProductMaterialModal
          selectedJob={selectedJob}
          handleClose={handleCloseModal}
        />
      )}
      {labourModalState && (
        <ProductLabourModal
          selectedJob={selectedJob}
          handleClose={handleCloseModal}
        />
      )}
      {exportModalState && (
        <JobExportModal
          selectedJob={selectedJob}
          handleClose={handleCloseModal}
        />
      )}
      {machinetModalState && (
        <MachineModal
          selectedJob={selectedJob}
          handleClose={handleCloseModal}
        />
      )}
      {stockEditModalState && (
        <StockEditModal
          selectedJob={selectedJob}
          handleClose={handleCloseModal}
        />
      )}
      {serviceModal && (
        <ServiceModal
          selectedJob={selectedJob}
          handleClose={handleCloseModal}
        />
      )}
      {confirmModalState && (
        <Confirm
          title="Finish job"
          confirmMsg="Do you want to finish the product job?"
          actionLabel="OK"
          cancelHandler={handleCloseModal}
          confirmHandler={async () => {
            await apiService.patch("product/status/" + selectedJob.id);
            toast.success("Product status changed successfully!");
            handleCloseModal();
            setSelectedJob(null);
          }}
        />
      )}
      <div className="flex w-full">
        <div
          className="grid grid-cols-3 gap-3 px-2 py-2 mb-3 bg-white rounded-md"
          style={{ width: "50%" }}
        >
          <div>
            <Button
              text={"NEW JOB"}
              handleClick={() => setJobModalState(true)}
            />
          </div>
        </div>
        <div
          className="flex items-center justify-end mx-5"
          style={{ width: "50%" }}
        >
          <Input
            placeholder="   Type To Search"
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
            style={{
              width: "45%",
              border: "1px solid #878686",
              borderRadius: "4px",
              height: "40px",
            }} // Adjust width as needed
          />
        </div>
      </div>{" "}
      <Table
        columns={columns}
        dataSource={filteredServices}
        style={{ width: "103%" }}
      />
    </section>
  );
};

export default Products;
